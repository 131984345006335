// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as adminProductActions } from '../../state/actions/adminProducts';

export function useAdminProductDetails(productId) {
  const dispatch = useDispatch();

  const productStore = useSelector(state => state.editProductDetails);
  const [product, setAdminProducts] = useState(productStore);

  useEffect(() => {
      dispatch(adminProductActions.loadAdminProductDetails(productId));
  }, [dispatch, productId]);
  
  useEffect(() => {
    if(productStore)
    {
      setAdminProducts(productStore);
    }
  },[productStore.isLoading]);

  useEffect(() => {   
      setAdminProducts(product);
  },[productStore.isUploading]);

  const updateProduct = (keyValue, newValue, updateProduct) => {

    const lensSet = (key, newValue, updateProduct) => {

        const updateProductDetails = set(lensPath(key.split('.')), newValue, updateProduct);
        setAdminProducts({
          ...product,
            productDetails: updateProductDetails
          });
    };
    lensSet(keyValue, newValue, updateProduct ?? product.productDetails);
  };

  const onSubmit = form => {
    const updatedProduct = {...product, 
        productDetails: { 
          ...product.productDetails, description: form.description}};

      dispatch(adminProductActions.saveProductDetails(updatedProduct.productDetails));   
  };

  const uploadImage = image => {
    dispatch(adminProductActions.imageUpload(image));
    setTimeout(1000, dispatch(adminProductActions.imageUpload(image)));
  };

  return {
    error: productStore.error,
    product: product.productDetails,
    categories: product.categories,
    deliveries: product.deliveries,
    isLoading: productStore.isLoading,
    isUploading: productStore.isUploading,
    isSaving: productStore.isSaving,
    saveMessage: productStore.saveMessage,
    updateProduct,
    onSubmit,
    uploadImage
  };
}
