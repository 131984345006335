// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as styledPageActions } from '../../state/actions/styledPages';

export function useEditStyledPages(pageId) {
  const dispatch = useDispatch();

  const styledPageStore = useSelector(state => state.styledPages);

    const defaultProps = {
      styledPage: {
        styledPage: styledPageStore?.styledPage == null ? [] : styledPageStore?.styledPage
      }
  };

  const [styledPage, setStyledPage] = useState(defaultProps);
  const [lastUploadedImages, setLastUploadedImagesPage] = useState(null);

  useEffect(() => {
    setLastUploadedImagesPage(null);
    dispatch(styledPageActions.loadStyledPage( pageId === undefined ? null : pageId));
  }, []);

  useEffect(() => {
    setStyledPage(styledPageStore);
  },[styledPageStore.isLoading]);

  useEffect(() => {
    setStyledPage(styledPageStore);
  },[styledPageStore.isSaveComplete]);

  useEffect(() => {
    if(styledPageStore.styledPage && styledPageStore.styledPage.styledPage && styledPageStore.styledPage.styledPage.itemBlocks 
      && styledPage.styledPage && styledPage.styledPage.styledPage)
    {
      if(styledPage.styledPage.styledPage.itemBlocks === null || styledPage.styledPage.styledPage.itemBlocks === undefined)
      {
        setStyledPage(styledPageStore);
      }
      else if(styledPage.styledPage.styledPage.itemBlocks !== null && 
        styledPageStore.styledPage.styledPage.itemBlocks.length > styledPage.styledPage.styledPage.itemBlocks.length)
      {
        setStyledPage(styledPageStore);
      }
    }

  },[styledPage.styledPage]);

  const moveDragableItem = (sourceIndex, destinationIndex) => {

    const allItems = styledPage && styledPage.styledPage.styledPage.itemBlocks;

    const newOrder = Array.from(allItems);
    const [removed] = newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, removed);

    for(let i = 0; i < newOrder.length; i++)
    {
      newOrder[i].orderPostion = i;
    }
 
    const updatedStyledPage = styledPage.styledPage.styledPage;
    updatedStyledPage.itemBlocks = newOrder;
    updatedStyledPage.published = false;

    setStyledPage({...styledPage, styledPage: { styledPage : updatedStyledPage}});

    dispatch(styledPageActions.postSaveItemBlockOrder(updatedStyledPage));
    setLastUploadedImagesPage(null);
  };

  const setStyledPageToEdit = styledPage => {
    dispatch(styledPageActions.loadStyledPage( styledPage.id === undefined ? null : styledPage.id));
    setLastUploadedImagesPage(null);
  };

  const addStyledPage = (form) => {
    dispatch(styledPageActions.postAddStyledPage(form));
    setLastUploadedImagesPage(null);
    setStyledPage({title: form.title});
  };

  const addItemBlock = (form) => {
    updateStyledPage("styledPage.styledPage.published", false, styledPage);
    dispatch(styledPageActions.postAddStyledPageItemBlock(form));
    setLastUploadedImagesPage(null);
  };

  const removeItemBlockSubmit = (pageId, id, blockType) => {
    const newOrder = styledPage && styledPage.styledPage.styledPage.itemBlocks.filter(x => x.id !== id);

    for(let i = 0; i < newOrder.length; i++)
    {
      newOrder[i].orderPostion = i;
    }

    const updatedStyledPage = styledPage.styledPage.styledPage;
    updatedStyledPage.itemBlocks = newOrder;
    updatedStyledPage.published = false;

    setStyledPage({...styledPage, styledPage: { styledPage : updatedStyledPage}});
    
    dispatch(styledPageActions.deleteStyledPageItemBlock({pageId, id, blockType}));
  };

  const updateStyledPage = (keyValue, newValue, updateStyledPage) => {
    const lensSet = (key, newValue, updateStyledPage) => {

        const updatedStyledPage = set(lensPath(key.split('.')), newValue, updateStyledPage);
        setStyledPage(updatedStyledPage);
    };
    lensSet(keyValue, newValue, updateStyledPage ?? styledPage.styledPage);
  };

  function sortByDate(a, b) {
    const dateA = a.dateCreated;
    const dateB = b.dateCreated;
  
    let comparison = 0;
    if (dateA < dateB) {
      comparison = 1;
    } else if (dateA > dateB) {
      comparison = -1;
    }
    return comparison;
  }

  const publishStyledPage = () => {
    dispatch(styledPageActions.postPublishStyledPage(styledPage.styledPage.styledPage));
    updateStyledPage("styledPage.styledPage.published", true, styledPage);
  };

  const resetItemBlockForm = () =>
  {
    setLastUploadedImagesPage(null);
  }

  const uploadImages = (form, itemForm, images, files) => {
    setLastUploadedImagesPage({
      id: itemForm.id, 
      title: itemForm !== null && itemForm !== undefined ? itemForm.title : '',
      text: itemForm !== null && itemForm !== undefined ? itemForm.text : '',
      orderPostion: itemForm.orderPostion,
      imageOnLeft: itemForm.imageOnLeft,
      isCarosel: itemForm.isCarosel,
      blockType: itemForm.blockType,
      images: images,
    });

    dispatch(styledPageActions.postImageUpload({ images: form, pageId: itemForm.id }));
  };

  const removeStyledPage = styledPage => {
    let updatedStyledPage = styledPage.styledPage.filter(x => x.id !== styledPage.id);
    updatedStyledPage = updatedStyledPage.sort(sortByDate);

    setStyledPage({
      ...styledPage,
        styledPage: updatedStyledPage
    });

    dispatch(styledPageActions.removeStyledPage(styledPage)); 
  }

  function updateImages(images) {
    setLastUploadedImagesPage({...lastUploadedImages, 
      images: images
    });
}

  return {
    error: styledPageStore.error,
    styledPage: styledPage && styledPage.styledPage ? styledPage.styledPage : null,
    styledPages: styledPage && styledPage.styledPages ? styledPage.styledPages : [],
    isLoading: styledPageStore.isLoading,
    isSaving: styledPageStore.isSaving,
    isUploading: styledPageStore.isUploading,
    saveMessage: styledPageStore.saveCompleteMessage,
    updateStyledPage,
    publishStyledPage,
    uploadImages,
    updateImages,
    setStyledPageToEdit,
    lastUploadedImages,
    moveDragableItem,
    addItemBlock,
    addStyledPage,
    removeStyledPage,
    resetItemBlockForm,
    removeItemBlockSubmit,
  };
}
