// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ROUTES from '../../constants/routes';

import { faUserEdit, faPencilAlt, faPlus, faDollyFlatbed, faBookOpen, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    width: 70%;
    margin:auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
`;

const ActionLink = styled(Link)`
    text-decoration: none;
    color: #3d3d3d;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;

    padding: 10px 10px 10px 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const ColumnCentreAlign = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items:center;
    text-align:center;
    justify-content: space-between;

    &:hover {
        color: #d9d9d9;;
    }
`;

const OptionContainer = styled.div`
    width: 100px;
    height : 100px;
    padding: 10px;
    margin: 5px;
    color: #ffffff;
    border: solid 1px #000000;
    background-color: rgba(168, 39, 0, 0.9);
`;

export default function() {
return(
    <Container>
        <Header></Header>

        <Row>
        </Row>
        <Content>
            <Column>
                <Row>
                    <ActionLink to={ROUTES.EDIT_PRODUCT} ><OptionContainer><ColumnCentreAlign><div>Add new product</div><FontAwesomeIcon icon={faPlus} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_PRODUCT_SEARCH} ><OptionContainer><ColumnCentreAlign><div>Edit product</div><FontAwesomeIcon icon={faPencilAlt} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_VIEW_ORDERS} ><OptionContainer><ColumnCentreAlign><div>View Orders</div><FontAwesomeIcon icon={faDollyFlatbed} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADD_ACCOUNT} ><OptionContainer><ColumnCentreAlign><div>Add Account</div><FontAwesomeIcon icon={faUserEdit} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_BLOGS} ><OptionContainer><ColumnCentreAlign><div>Manage Blogs</div><FontAwesomeIcon icon={faPlaneDeparture} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_STYLED_PAGES} ><OptionContainer><ColumnCentreAlign><div>Manage Styled Pages</div><FontAwesomeIcon icon={faBookOpen} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_EDIT_CATEGORIES} ><OptionContainer><ColumnCentreAlign><div>Manage Brands and Categories</div><FontAwesomeIcon icon={faBookOpen} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>

                    <ActionLink to={ROUTES.ADMIN_EDIT_OPENINGTIMES} ><OptionContainer><ColumnCentreAlign><div>Manage Opening Times</div><FontAwesomeIcon icon={faBookOpen} size="2x" /></ColumnCentreAlign></OptionContainer></ActionLink>
                </Row>
            </Column>
        </Content>

        <Row>
        </Row>
    </Container>
);
}