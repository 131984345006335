// @flow
import { useEffect, useState } from 'react';
import { lensPath, set } from 'ramda';

export function useBasket() {

    let defaultBasketStorage = {
        items: [],
        basketErrorMessage: null,
        justAdded: null
    };

    let basketStorage = {
        items: [],
        basketErrorMessage: null,
        justAdded: null
    };

  const defaultPaymentDetails = {
    billingDeliverySet: false,
    billingDeliveryConfirmed: false,
    userDetailsSet: false,
    userDetails: null,
    sameBillingAddress: true,
    deliveryMethod: null,
    deliveryAddress: null,
    billingAddress: null,
    paymentMethod: null
  };

  const localStorageData = localStorage.getItem('basket');

  if(localStorageData !== null)
  {
    basketStorage = JSON.parse(localStorageData);
  }
  
  const [paymentDetails, setPaymentDetails] = useState(defaultPaymentDetails);
  const [basket, setBasket] = useState(basketStorage);

  const closeAddedToCartPopup = basket => {
      const updatedBasket = {...basket, justAdded: null};
      setBasket(updatedBasket);
      localStorage.setItem('basket', JSON.stringify(updatedBasket));
  }

  const incramentQuantity = itemId => {

    let basketErrorMessage = '';
    let updatedProduct = null;

    const updatedItems = basket.items.map(item => { 
        if(item.id === itemId)
        {
            if(item.quantity +1 <= item.maxQuantity) 
            {
                item.quantity += 1;
                updatedProduct = item;
            }
            else
            {
                basketErrorMessage = `Sorry you can only add ${item.maxQuantity} to your basket.`;
            }
        }

        return item;
    });
    if(updatedProduct)
    {
        const updatedBasket = {...basket, items: updatedItems, basketErrorMessage, justAdded: { product: updatedProduct, totalQuantity: updatedProduct.quantity}}
        setBasket(updatedBasket);
        localStorage.setItem('basket', JSON.stringify(updatedBasket));
    }
  }

  const decreaseQuantity = itemId => {

    let basketErrorMessage = '';
    let updatedProduct = null;

    const updatedItems = basket.items.map(item => { 
        if(item.id === itemId)
        {
            if(item.quantity -1 > 0) 
            {
                item.quantity -= 1;
                updatedProduct = item;
            }
            else
            {
                removeFromBasket(itemId);
            }
        }

        return item;
    });

    if(updatedProduct)
    {
        const updatedBasket = {...basket, items: updatedItems, basketErrorMessage, justAdded: { product: updatedProduct, totalQuantity: updatedProduct.quantity}}
        setBasket(updatedBasket);
        localStorage.setItem('basket', JSON.stringify(updatedBasket));
    }
  }

  const removeFromBasket = intemId => {
      let basketErrorMessage = '';
      const basketItems = basket.items.filter(x => x.id !== intemId);
      const updatedBasket = {...basket, items: basketItems, basketErrorMessage, justAdded: null }
      setBasket(updatedBasket);
      localStorage.setItem('basket', JSON.stringify(updatedBasket));
  }

  const updateBasket = product => {

    let itemToAdd = basket.items.filter(x => x.id === product.id);
    if(itemToAdd.length > 0)
    {
        let basketErrorMessage = '';

        const updatedItems = basket.items.map(item => { 
                if(item.id === product.id)
                {
                    if(item.quantity +1 <= item.maxQuantity) 
                    {
                        item.quantity += 1;
                    }
                    else
                    {
                        basketErrorMessage = `Sorry you can only add ${item.maxQuantity} to your basket.`;
                    }
                }

                return item;
            });

        const updatedBasket = {...basket, items: updatedItems, basketErrorMessage, justAdded: { product: product, totalQuantity: itemToAdd[0].quantity}};
        setBasket(updatedBasket);
        localStorage.setItem('basket', JSON.stringify(updatedBasket));
    }
    else
    {
        if(product.quantity <= 0)
        {
            const updatedBasket = {...basket,  basketErrorMessage: `Sorry this is out of stock right now.`, justAdded: { product: product, totalQuantity: 0}};
            setBasket(updatedBasket);
            localStorage.setItem('basket', JSON.stringify(updatedBasket));
        }
        else
        {
            const newItem = {
                id: product.id,
                name: product.name,
                price: product.price,
                images: product.images,
                quantity: 1,
                deliveryOptions: product.deliveryOptions,
                maxQuantity: product.quantity
            };

            basket.items.push(newItem);

            const updatedBasket = {...basket, items: basket.items, basketErrorMessage: null, justAdded: { product: product, totalQuantity: newItem.quantity}};
            setBasket(updatedBasket);
            localStorage.setItem('basket', JSON.stringify(updatedBasket));
        }
    }
  }

  const updatePaymentDetailsFromForm = (keyValue, newValue) => {

    const lensSet = (key, newValue, product) => {
        const newPaymentDetails = set(lensPath(key.split('.')), newValue, paymentDetails);
        
        if(newPaymentDetails.sameBillingAddress === true) {
            newPaymentDetails.billingAddress = newPaymentDetails.deliveryAddress;
        }

        setPaymentDetails(newPaymentDetails);
    };


    lensSet(keyValue, newValue, paymentDetails);
  };

  const updatePaymentDetails = (paymentDetails) => {
    setPaymentDetails(paymentDetails);
  };

  useEffect(() => {
      setBasket({...basket,  justAdded: null});
  }, []);

  const resetBasket = () => {
    setBasket(defaultBasketStorage);
    localStorage.setItem('basket', JSON.stringify(defaultBasketStorage));
    setPaymentDetails(defaultPaymentDetails);
  };

  return {
    paymentDetails,
    updatePaymentDetailsFromForm,
    updatePaymentDetails,
    basket,
    updateBasket,
    closeAddedToCartPopup,
    incramentQuantity,
    decreaseQuantity,
    removeFromBasket,
    resetBasket,
  };
}
