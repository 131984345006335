import { useEffect, useState, useMemo } from 'react';

export function useHeader(ref) {

    const [logoVisible, setLogoVisible] = useState(true);

        const observer = useMemo(() => new IntersectionObserver(
            ([entry]) => setLogoVisible(entry.isIntersecting)
        ), [ref])

      useEffect(() => {
          if(ref && ref.current) {
                observer.observe(ref?.current)
                
            }
            // Remove the observer as soon as the component is unmounted
            return () => { observer.disconnect() }
      }, [])
    

    return {
        logoVisible
    };
}
