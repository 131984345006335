export const constants = {
    loadProductSearch: 'LOAD_PRODUCT_SEARCH',
    loadProductSearchSuccess: 'LOAD_PRODUCT_SEARCH_SUCCESS',
    loadProductSearchFailure: 'LOAD_PRODUCT_SEARCH_FAILURE',

    loadProductDetails: 'LOAD_PRODUCT_DETAILS',
    loadProductDetailsSuccess: 'LOAD_PRODUCT_DETAILS_SUCCESS',
    loadProductDetailsFailure: 'LOAD_PRODUCT_DETAILS_FAILURE',
};

export const actions = {
    loadProductSearch: products => ({
        type: constants.loadProductSearch,
        payload: products
    }),
    loadProductSearchSuccess: payload => ({
        type: constants.loadProductSearchSuccess,
        payload,
    }),
    loadProductSearchFailure: error => ({
        type: constants.loadProductSearchFailure,
        error,
    }),

    loadProductDetails: productDetails => ({
        type: constants.loadProductDetails,
        payload: productDetails
    }),
    loadProductDetailsSuccess: payload => ({
        type: constants.loadProductDetailsSuccess,
        payload,
    }),
    loadProductDetailsFailure: error => ({
        type: constants.loadProductDetailsFailure,
        error,
    })
};