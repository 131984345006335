import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/blogs';
import { get, post, httpDelete, postFiles } from '../../helpers/http';
import { blogsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadBlogs() {
  try {
    
    const url = `${blogsUrl}`;

    const response = yield call(get, { url });

    yield put(actions.loadBlogsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadBlogsFailure(GetErrorResponseMessage(error, "loadBlogs", REQUEST_METHODS.GET)));
  }
}

function* loadBlog({payload}) {
  try {
    
    const url = `${blogsUrl}/${payload}`;

    const response = yield call(get, { url });

    yield put(actions.loadBlogSuccess(response.data));
  } catch (error) {
    yield put(actions.loadBlogFailure(GetErrorResponseMessage(error, "loadBlog", REQUEST_METHODS.GET)));
  }
}

function* loadBlogDesigns() {
  try {
    
    const url = `${blogsUrl}/designs`;

    const response = yield call(get, { url });

    yield put(actions.loadBlogDesignsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadBlogDesignsFailure(GetErrorResponseMessage(error, "loadBlogDesigns", REQUEST_METHODS.GET)));
  }
}

function* loadBlogDesign({ payload }) {
  try {
    
    const url = `${blogsUrl}/designs/${payload}`;

    const response = yield call(get, { url });

    yield put(actions.loadBlogDesignSuccess(response.data));
  } catch (error) {
    yield put(actions.loadBlogDesignFailure(GetErrorResponseMessage(error, "loadBlogDesign", REQUEST_METHODS.GET)));
  }
}

function* newBlogDesign({ payload }) {
  try {
    
    const url = `${blogsUrl}`;

    const response = yield call(post, { url,  data: payload });

    yield put(actions.newBlogDesignSuccess(response.data));
  } catch (error) {
    yield put(actions.newBlogDesignFailure(GetErrorResponseMessage(error, "newBlogDesign", REQUEST_METHODS.POST)));
  }
}

function* saveBlogDesign({ payload }) {
  try {
    
    const url = `${blogsUrl}`;

    const response = yield call(post, { url,  data: payload });

    yield put(actions.saveBlogDesignSuccess(response.data));
  } catch (error) {
    yield put(actions.saveBlogDesignFailure(GetErrorResponseMessage(error, "saveBlogDesign", REQUEST_METHODS.POST)));
  }
}

function* publishBlogDesign({ payload }) {
  try {
    
    const url = `${blogsUrl}/publish/${payload.id}`;

    const response = yield call(post, { url,  data: payload });

    yield put(actions.publishBlogDesignSuccess(response.data));
  } catch (error) {
    yield put(actions.publishBlogDesignFailure(GetErrorResponseMessage(error, "publishBlogDesign", REQUEST_METHODS.POST)));
  }
}

function* removeBlog({ payload }) {
  try {
    
    const url = `${blogsUrl}/${payload.id}`;

    const response = yield call(httpDelete, { url });

    yield put(actions.removeBlogSuccess(response.data));
  } catch (error) {
    yield put(actions.removeBlogFailure(GetErrorResponseMessage(error, "removeBlog", REQUEST_METHODS.DELETE)));
  }
}

function* imageUpload({ payload }) {
  try {
    const url = `${blogsUrl}/images`;

    const response = yield call(postFiles, { url,  data: payload });

    yield put(actions.imageUploadSuccess(response.data));

  } catch (error) {
    yield put(actions.imageUploadFailure(GetErrorResponseMessage(error, "imageUpload-images", REQUEST_METHODS.UPLOAD)));
  }
}

export default function* productSaga() {
  yield takeLatest(constants.loadBlogs, loadBlogs);
  yield takeLatest(constants.loadBlog, loadBlog);
  yield takeLatest(constants.loadBlogDesign, loadBlogDesign);
  yield takeLatest(constants.loadBlogDesigns, loadBlogDesigns);
  yield takeLatest(constants.saveBlogDesign, saveBlogDesign);
  yield takeLatest(constants.newBlogDesign, newBlogDesign);
  yield takeLatest(constants.publishBlogDesign, publishBlogDesign);
  yield takeLatest(constants.removeBlog, removeBlog);
  yield takeLatest(constants.imageUpload, imageUpload);
}
