// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as customerDetailsActions } from '../../state/actions/auth';

export function useCreateUserAccount(props) {
  const dispatch = useDispatch();

  const authDetails = useSelector(state => state.authDetails);

  useEffect(() => {
  },[authDetails]);

  const saveAccountSubmit = form => {
    dispatch(customerDetailsActions.postCreateCustomerAccount(form));
  };

  return {
    error: authDetails.error,
    isLoading: authDetails.isLoading,
    isLoggedIn: authDetails.isLoggedIn,
    saveCompleteMessage: authDetails.saveCompleteMessage,
    saveAccountSubmit,
  };
}
