// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import Carousel from '../../components/common/ImageGallery';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import { imagesLocation } from '../../settings';
import { BasketAccessRestrictions } from '../../helpers/SiteRestrictionAccess';
import { ScrollToTop } from '../../helpers/scrollToTop';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import '../../styles/accordion.css';
import { default as NumberFormat } from 'react-number-format';
import { useProductDetails } from '../../components/product/useProductDetails';
import { useBasket } from '../../components/basket/useBasket';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
    }
`;

const ProductImagesContainer = styled.div`
    width: 100%;
    @media (max-width: 799px) {
        padding:0px;
    }
    padding:10px;
    margin-bottom:40px;
`;

const ProductDescriptionContainer = styled.div`
    padding:10px;
    width: 90%;

    @media (max-width: 799px) {
        width: auto;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 1.4em;
    padding-bottom:10px;
`;

const Description = styled.div`
    padding-top:20px;
`;

const Price = styled.div`
    display:flex;
    font-weight: bold;
    font-size: 1.2em;
    padding-top:10px;
    justify-content: space-between;
`;

const HorizontalRule = styled.div`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    background-color: #696969;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
`;

const DeliveryPriceContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ProductDeliveryContainer = styled.div`
    padding-top: 20px;
`;

const DeliveryOptionDescription = styled.div`
    font-size: 0.8em;
    padding-bottom: 10px;
`;

const AddedToCartImage = styled.img`
    src: url(${props => props.src});
    width: 100px;
    height: 100px;
`;

const AddedToCartName = styled.div`
    display: flex;
    text-align: center;
    width: 100%;
    margin-left: 5px;
`;

const AddedToCartColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const AddedToCartClose = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px 0 0;
    cursor: pointer;
`;

const AddedToCartIntro = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    color: green;
    font-weight: bold;
    font-size: 1.1em;
`;

const AddedToCartQuantity = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 0.9em;
    font-style: italic;
`;

const AddedToCart = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    max-width: 400px;
    background-color: #ffffff;
    border: 2px solid #a82700;
    z-index: 1000;
    padding: 10px;
    margin: 10px;
`;

const AddedToCartErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    color: #a82700;
    font-weight: bold;
    font-size: 1.1em;
`;

const AddedToCartRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const RightArrow = styled.div`
    height: 6px;
    width: 6px;
    top: 6px;
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-top: 10px;
`;

const LeftArrow = styled.div`
    height: 6px;
    width: 6px;
    top: 6px;
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    transform: rotate(130deg);
    -webkit-transform: rotate(130deg);
    margin-top: 10px;
`;

const SoldOut = styled.div`
    color: red;
`;

const ViewBasketLink = styled(Link)`
    display: flex;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;

    &:active {
        border: none;
        text-decoration: none;
    }
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

const FreeIfOver = styled.div`
    font-style: italic;
    font-size: 0.9em;
`;

function getDeliveryOption(deliveryOption) {

    return(<div key={deliveryOption.delivery.name}>
        <DeliveryPriceContainer>
            <div>{deliveryOption.delivery.description}</div>
            <div>{deliveryOption.delivery.price === 0 ? <>free</> : <NumberFormat value={deliveryOption.delivery.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />}</div>
        </DeliveryPriceContainer>
        <DeliveryOptionDescription>
            <FreeIfOver>Free when you spend over £{deliveryOption.delivery.freeIfOverAmount}</FreeIfOver>
        </DeliveryOptionDescription>
    </div>);
}

function getDetails(productDetails, basket, updateBasket, isBasketEnabled)
{
    ScrollToTop();
    
    return(
        <>
        <Row>
            <ProductImagesContainer>
                <Carousel 
                    images={productDetails.images}
                    baseImagesLocation={`${imagesLocation}products/`} />
            </ProductImagesContainer>
            <ProductDescriptionContainer>
                <Name>{productDetails.name}</Name>
                <HorizontalRule />
                <Price>
                    { productDetails.quantity > 0 ? 
                        <NumberFormat value={productDetails.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /> : 
                        <SoldOut>Sold out</SoldOut>
                    }
                    { isBasketEnabled === true ?  
                        productDetails.quantity > 0 ? <Button onClick={() => updateBasket(productDetails)} >Add to basket</Button> : null  : 
                        null 
                    }
                </Price>
                <Description dangerouslySetInnerHTML={{__html: productDetails.description}} />

                <ProductDeliveryContainer>
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Delivery
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {productDetails.deliveryOptions.map((deliveryOption, idx) => getDeliveryOption(deliveryOption, idx))}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                </ProductDeliveryContainer>
            </ProductDescriptionContainer>
        </Row>
        <Row>
            <HorizontalRule />
        </Row>
        <Row></Row>
    </>
    );
}

export default function(props) {

const { isEnabled } = BasketAccessRestrictions();

 const {
    error,
    productDetails,
    isLoading,
 } = useProductDetails(props.match.params.name);

 const {
    basket,
    updateBasket,
    closeAddedToCartPopup
    } = useBasket();
    
const imagePath = basket && basket.justAdded && basket.justAdded.product && basket.justAdded.product.images.length > 0 ? 
    `${imagesLocation}products/${basket.justAdded.product.images.filter(x => x.order === 0)[0].path}` : 
    "";

return(
    <Container>
        {
            basket.justAdded ? 
            <AddedToCart>
                <AddedToCartIntro>Added to your basket</AddedToCartIntro>
                <AddedToCartRow>
                    {imagePath === "" ? null : <AddedToCartImage src={ `${imagesLocation}products/${basket.justAdded.product.images.filter(x => x.order === 0)[0].path}` } />}
                    <AddedToCartColumn>
                        <AddedToCartName>{basket.justAdded.product.name}</AddedToCartName>
                        <AddedToCartQuantity>({basket.justAdded.totalQuantity} in your basket)</AddedToCartQuantity>
                        <ViewBasketLink to={ROUTES.BASKET}>View basket</ViewBasketLink>
                    </AddedToCartColumn>
                </AddedToCartRow>
                <AddedToCartErrorMessage>{basket.basketErrorMessage}</AddedToCartErrorMessage>
                <AddedToCartClose onClick={() => closeAddedToCartPopup(basket)}><RightArrow/><LeftArrow/></AddedToCartClose>
            </AddedToCart> :
            <></>
        }
        <Header></Header>
        <Content>
            { 
                isLoading === true ? <Loading><LoadingAnimation /></Loading>  : 
                    !error.message && productDetails != null ? getDetails(productDetails, basket, updateBasket, isEnabled) : 
                        <Error>{error.message}</Error> 
            }
        </Content>

    </Container>
);
}