// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { imagesLocation } from '../../settings';
import LoadingAnimation from '../common/LoadingAnimation';

import { useBlogs } from './useBlogs';

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    position:relative;
    width: 100%;
`;

const Blogs = styled.div`
    display: flex;
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
    position:relative;
    width: 100%;
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const TextContainer = styled.div`
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

const BlogContainer = styled.div`
    display: flex;
    margin: 5px;
    position: relative;
    background-color: #fafafa;
    text-decoration: none;
    flex-direction: column;
    border: 1px solid #adadad;
    &:hover {
        border: 1px solid #000000;
    };
    a:visited  {
        color: #000000;
    };
    cursor: pointer;
    color: #000000;
    height: min-content;

    width: 200px;


    @media (min-width: 200px) {
        width: 48%;
    }

    @media (min-width: 550px) {
        width: 48%;
    }

    @media (min-width: 780px) {
        width: 30%;
    }    
    
    @media (min-width: 800px) {
        width: 30%;
    }

    @media (min-width: 1024px) {
        width: 32%;
    }
`;

const StyledLink = styled(Link)`
    color: #000000;
    padding: 10px;
    text-decoration: none;
`;

const BlogImageContainer = styled.div`
`;

const BlogBody = styled.div`
    padding: 10px;
`;


function getBlog(blog: object, setBlogToView: Function) {

    let img = '';
    try {
        img = blog.images && `${imagesLocation}blogs/published/${blog.images.filter(x => x.order === 0)[0].path}`;
    } catch (error) {
        img = ``;
    }

return (
    
        <BlogContainer onClick={() => { setBlogToView(blog.name) }}>
            <StyledLink to={`${ROUTES.BLOG}/${blog.name}`}>
                <BlogImageContainer>
                    {blog.images && blog.images[0] ?
                        <img key={img} 
                        src={img} 
                        title={blog.name} 
                        alt={''} 
                        width='100%' /> :
                        <></>
                    }
                </BlogImageContainer>
                <TextContainer>
                    <Title>{blog.name}</Title>
                </TextContainer>          
            </StyledLink>
        </BlogContainer>);
}

export default function(props) {

    const showAllBlogs = props.props.location.pathname === ROUTES.BLOG;
    const blogName = props.props.match && props.props.match.params && props.props.match.params.blogName ? props.props.match.params.blogName : null;
    const {
        blogs,
        isLoading,
        selectedBlog,
        error,
        setBlogToView,
    } = useBlogs(showAllBlogs, blogName);

    return isLoading === true || blogs === undefined ? 
        (<Container><Loading><LoadingAnimation /></Loading></Container>) : error && error.message ?
        (<Container><Error>{error.message}</Error></Container>) : blogs.length === 0 ? (<Container><Loading><p><br />Dosn't look like we have any blog articles yet.<br />Come back later and we might have something new to share.<br /></p></Loading></Container>) :
        ( <Container>
                {selectedBlog.selectedBlog === null ? 
                <Blogs>
                    {blogs && blogs.map((blog) => getBlog(blog, setBlogToView))}
                </Blogs> :
                <>
                <BlogBody dangerouslySetInnerHTML={{__html: selectedBlog.selectedBlog.body}} />
                </>}
         </Container>)
}