// @flow
import { combineReducers } from 'redux';

import routerReducer, { type State as RouterState } from './reducers/router';
import authReducer, { type State as AuthState } from './reducers/auth';

import menuReducer, {
  type State as MenuDetailsState,
} from './reducers/menu';

import productsReducer, {
  type State as ProductsState,
} from './reducers/products';

import editProductDetailsReducer, {
  type State as EditProductDetailsState,
} from './reducers/editProductDetails';

import changePasswordReducer, {
  type State as ResetPasswordState,
} from './reducers/resetPassword';

import contactUsReducer, {
  type State as ContactUsState,
} from './reducers/contactUs';

import deliveryDetailsReducer, {
  type State as deliveryDetailsState,
} from './reducers/basket';

import newOrderReducer, {
  type State as newOrderState,
} from './reducers/order';

import customerOrdersReducer, {
  type State as customerOrdersState,
} from './reducers/customerOrders';

import customerDetailsReducer, {
  type State as customerDetailsState,
} from './reducers/customerDetails';



import adminProductsReducer, {
  type State as AdminProductsState,
} from './reducers/adminProducts';

import adminOrdersReducer, {
  type State as adminOrdersState,
} from './reducers/adminOrders';

import adminAccountsReducer, {
  type State as adminAccountsState,
} from './reducers/adminAccounts';

import adminCategoriesReducer, {
  type State as adminCategoriesState,
} from './reducers/adminCategories';

import adminOpeningTimesReducer, {
  type State as adminOpeningTimesState,
} from './reducers/adminOpeningTimes';



import blogsReducer, {
  type State as blogsState,
} from './reducers/blogs';

import styledPagesReducer, {
  type State as styledPagesState,
} from './reducers/styledPages';

import homePageReducer, {
  type State as homePageState,
} from './reducers/home';


export type State = {
  authDetails: AuthState,
  router: RouterState,
  menuDetails: MenuDetailsState,
  products: ProductsState,
  adminProducts: AdminProductsState,
  editProductDetails: EditProductDetailsState,
  changePassword: ResetPasswordState,
  contactUs: ContactUsState,
  deliveryDetails: deliveryDetailsState,
  newOrder: newOrderState,
  adminOrders: adminOrdersState,
  customerOrders: customerOrdersState,
  customerDetails: customerDetailsState,
  adminAccounts: adminAccountsState,
  blogs: blogsState,
  styledPagesReducer: styledPagesState,
  homePageReducer: homePageState,
  adminCategoriesReducer: adminCategoriesState,
  adminOpeningTimesReducer: adminOpeningTimesState,
};

export default combineReducers({
  authDetails: authReducer,
  router: routerReducer,
  menuDetails: menuReducer,
  products: productsReducer,
  adminProducts: adminProductsReducer,
  editProductDetails: editProductDetailsReducer,
  changePassword: changePasswordReducer,
  contactUs: contactUsReducer,
  deliveryDetails: deliveryDetailsReducer,
  newOrder: newOrderReducer,
  adminOrders: adminOrdersReducer,
  customerDetails: customerDetailsReducer,
  adminAccounts: adminAccountsReducer,
  customerOrders: customerOrdersReducer,
  blogs: blogsReducer,
  styledPages: styledPagesReducer,
  publishedStyledPages: homePageReducer,
  adminCategories: adminCategoriesReducer,
  adminOpeningTimes: adminOpeningTimesReducer
});