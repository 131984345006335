// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';

import { useEditProductForm } from './useEditProductForm';

//import ImageUploader from 'react-images-upload';
import ImageUploader from '../common/ImageUploader';

import TextEditor from '../common/TextEditor';
import InputNumber from '../common/Input';
import InputText from '../common/Input';
import DropdownList from '../common/DropdownList';
import Checkbox from '../common/Checkbox';
import MultiSelect from '../common/MultiSelect';
import { imagesLocation } from '../../settings';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 799px) {
        flex-direction: column;
    }
`;

const ColumnNoMargin = styled.div`
    padding:10px;
    width: 50%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    padding:10px;
    margin-bottom:40px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    background-color: #696969;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
`;

const ProductDeliveryContainer = styled.div`
    padding-top: 20px;
`;

const Error = styled.div`
    color: #a82700;
`;

const Complete = styled.div`
    color: green;
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;


export default function(props) {

const isSaving = props.editProductDetails.isSaving;
const product = props.editProductDetails.product;
const updateProduct = props.editProductDetails.updateProduct;
const onSubmit = props.editProductDetails.onSubmit;
const categories = props.editProductDetails.categories;
const deliveries = props.editProductDetails.deliveries;
const isUploading = props.editProductDetails.isUploading;

const { handleSubmit, register, errors, setValue, setError, triggerValidation } = useForm();

useEditProductForm(product, isSaving, setValue, setError, register);

const onDeliverySelect = (keyValue, { options }) => {
    let deliveryOptions = [];

    for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
        deliveryOptions.push({delivery: {name: options[i].value}});
        }
    }

    const updated = {
        ...product,
        deliveryOptions,
    };

    updateProduct(keyValue, deliveryOptions, updated);
  };

const submitFrom = (form) => {
    setValue('description', product.description);
    onSubmit(form);
};

function submitImageUpload(form, images) {
    updateProduct('images', images, {
        ...product,
        images: images,
    });

    props.editProductDetails.uploadImage(form); 

    setValue('images', images);
    setValue('description', product.description);
}

 function updateImages(images) {
    updateProduct('images', images, product);

    setValue('images', images);
    setValue('description', product.description);
}

return(
    <Container> 
        <ColumnNoMargin>
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </ColumnNoMargin>
        <Row>
            <Column>
                <ImageUploader 
                    name='images'
                    keyValue='images'
                    value={product && product.images && product.images.map((image) => image.path)} 
                    onSubmit={submitImageUpload}
                    updateImages={updateImages}
                    label="Upload Images" 
                    baseImagesLocation={`${imagesLocation}products/`}
                    isUploading={isUploading}
                />
            </Column>
        </Row>
        <form onSubmit={handleSubmit(submitFrom)}>
            <Row>
                <Column>
                    <Button disables={isSaving}>{isSaving ? 'Saving' : 'Save'}</Button>
                    <Error>{props.editProductDetails.error && props.editProductDetails.error.message}</Error>
                    <Complete>{props.editProductDetails.saveMessage}</Complete>
                    
                    <InputText 
                        name='name'
                        keyValue='name'
                        value={product.name} 
                        onChange={(keyValue, value) => {
                        updateProduct(keyValue, value, {
                            ...product,
                            name: value,
                        });
                        setValue('name', value);}} 
                        label="Name" 
                        ref={register({
                            required: 'Enter a name.',
                        })}
                        error={errors.name} 
                        required />

                    <InputText 
                        name='stockCode' 
                        keyValue='stockCode'
                        value={product.stockCode} 
                        onChange={(keyValue, value) => {
                        updateProduct(keyValue, value, {
                            ...product,
                            stockCode: value,
                        });
                        setValue('stockCode', value);}} 
                        label="Stock Code" 
                        ref={register({
                            required: 'Enter a stock code.',
                        })}
                        error={errors.stockCode} 
                        required />

                    <InputNumber 
                        name='price'
                        keyValue='price' 
                        value={product.price} 
                        onChange={(keyValue, value) => {
                        updateProduct(keyValue, value, {
                            ...product,
                            price: value,
                        });
                        setValue('price', value.value);}} 
                        label="Price" 
                        error={errors.price} 
                        ref={register({
                            required: 'Enter a price.',
                            minLength: { value: '0', message: 'Enter a price.' },
                        })}
                        required />

                    <ProductDeliveryContainer>
                        <DropdownList 
                            name="brand" 
                            keyValue='brand.name' 
                            label="Brand" 
                            value={product.brand && product.brand.name ? product.brand.name : 'Select'}
                            options={categories.brands}
                            onChange={(keyValue, value) => {
                                if(value.value !== 'Select')
                                {
                                    updateProduct(keyValue, value.value, {
                                        ...product,
                                        brand: {
                                            name: value.value
                                        },
                                    });
                                    setValue('brand', value.value);
                                }

                                triggerValidation('brand');
                            }}
                            error={errors.brand} 
                            showDefaultValue={product.brand && product.brand.name ? false : true}
                            required />
                            
                    </ProductDeliveryContainer>

                    <ProductDeliveryContainer>
                    
                        <DropdownList 
                            keyValue='category.name' 
                            name="category" 
                            label="Category" 
                            value={product.category && product.category.name ? product.category.name : 'Select'}
                            options={categories.categories}
                            onChange={(keyValue, value) => {
                                if(value.value !== 'Select')
                                {
                                    updateProduct(keyValue, value.value, {
                                        ...product,
                                        category: {
                                            name: value.value
                                        },
                                    });
                                    setValue('category', value.value);
                                }
                                triggerValidation('category');
                            }}
                            error={errors.category} 
                            showDefaultValue={product.category && product.category.name ? false : true}
                            required />
                            
                    </ProductDeliveryContainer>

                    <ProductDeliveryContainer>
                        <DropdownList 
                            keyValue='subCategory.name' 
                            name="subCategory" 
                            label="Sub Category" 
                            value={product.subCategory && product.subCategory.name ? product.subCategory.name : 'Select'}
                            options={categories.subCategories}
                            onChange={(keyValue, value) => {
                                if(value.value !== 'Select')
                                {
                                    updateProduct(keyValue, value.value, {
                                        ...product,
                                        subCategory: {
                                            name: value.value
                                        },
                                    });
                                    setValue('subCategory', value.value);
                                }
                                triggerValidation('subCategory');
                            }}
                            showDefaultValue={product.subCategory && product.subCategory.name ? false : true}
                            error={errors.subCategory}
                            required />
                            
                    </ProductDeliveryContainer>

                    <ProductDeliveryContainer>
                        <DropdownList 
                            keyValue='subSubCategory.name' 
                            name="subSubCategory" 
                            label="Sub Sub Category" 
                            value={product.subSubCategory && product.subSubCategory.name? product.subSubCategory.name : 'Select'}
                            options={categories.subSubCategories}
                            onChange={(keyValue, value) => {
                                if(value.value !== 'Select')
                                {
                                    updateProduct(keyValue, value.value, {
                                        ...product,
                                        subSubCategory: {
                                            name: value.value
                                        },
                                    });
                                    setValue('subSubCategory', value.value);
                                }
                                else {
                                    updateProduct(keyValue, null, {
                                        ...product,
                                        subSubCategory: {
                                            name: null
                                        },
                                    });
                                    setValue('subSubCategory', null);
                                }
                            }}
                            showDefaultValue={true}
                            error={errors.subSubCategory} />

                    </ProductDeliveryContainer>

                    <ProductDeliveryContainer>

                        <MultiSelect 
                            name="devlivery" 
                            label="Delivery" 
                            keyValue="deliveryOptions"
                            value={product.deliveryOptions === null ? [] : product.deliveryOptions.map(option => option.delivery.name)}
                            options={deliveries.sort().map(deliveryOption => ({ 
                                                                        value: deliveryOption,
                                                                        label: deliveryOption
                                                                    }))}
                            onChange={onDeliverySelect}
                            error={errors.devlivery}
                            multiple
                            ref={register({
                                required: 'Select a delivery.',
                            })}
                            required />

                    </ProductDeliveryContainer>
                </Column>
                <Column>
                    <Checkbox 
                        keyValue='visible' 
                        name='visible' 
                        value={product.visible} 
                        onChange={(keyValue, value) => {
                        updateProduct(keyValue, value, {
                            ...product,
                            visible: value,
                        });
                        setValue('visible', value);}} 
                        label="Visible on Site" 
                        error={errors.visible} 
                        />
                    
                    <InputNumber 
                        keyValue='quantity'
                        name='quantity' 
                        value={product.quantity} 
                        onChange={(keyValue, value) => {
                        updateProduct(keyValue, value, {
                            ...product,
                            quantity: value,
                        });
                        setValue('quantity', value);}} 
                        label="Quantity" 
                        error={errors.quantity} 
                        ref={register({
                            required: 'Enter a quantity.',
                            minLength: { value: '0', message: 'Enter a quantity.' },
                        })}
                        required />
                
                    <TextEditor 
                        keyValue='description'
                        name='description'
                        placeholder="Description..."
                        value={product && product.description}
                        onChange={(keyValue, value) => {
                                setValue('description', value);
                                triggerValidation('description');
                            }}
                        label="Description"
                        error={errors.description}
                        required
                    />

                </Column>
            </Row>
        </form>
    </Container>
);
}