// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import DropdownList from '../common/DropdownObjectList';
import InputText from '../common/Input';
import Checkbox from '../common/Checkbox';
import { useCheckoutDelivery } from './useCheckoutDelivery';
import COUNTRY_CODES from '../../helpers/countryCodes';

const RowColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 1200px) {
        flex-direction: column;
        width: auto;
    }
`;

const BillingAddress = styled.div `
    ${props => props.isVisible ? "display: flex;" : "display: none;"}
    ${props => props.isVisible ? "width: 100%;" : null}

    @media (max-width: 1200px) {
        width: auto;
        ${props => props.isVisible ? "display: block;" : "display: none;"}
    }
`;

const Pointer = styled.div`
    cursor: pointer;
    height:100%;
    width: 100%;
`;

const Button = styled.button`
    cursor: pointer;
    font-weight: bold;
    font-size:1.2em;
    padding:5px;
    min-width: 100px;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    width:100%;

    @media (max-width: 1200px) {
        width: auto;
        padding: 0;
        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 10px;
    }
`;

const InternationalDelivery = styled.div`
    color: red;
`;

const CheckoutStageTitle = styled.div`
`;

const EmptyBlock = styled.div `
    height: 63px;
    @media (max-width: 1200px) {
        height: 0px;
    }
`;

export default function CheckoutDeliverySelection(props) {

    const {basket, paymentDetails, updatePaymentDetailsFromForm, updatePaymentDetails, RecreateOrderIntent} = props.props;

    const { handleSubmit, register, errors } = useForm();    
    
    const { getDeliveryOptions } = useCheckoutDelivery(paymentDetails, basket, updatePaymentDetailsFromForm);

    const submitFrom = form => {

        if(paymentDetails.sameBillingAddress === true)
        {
            updatePaymentDetails({
                ...paymentDetails,
                    billingDeliveryConfirmed: true,
                    billingDeliverySet: true,
                    billingAddress: { 
                        ...paymentDetails.billingAddress, 
                        address: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address,
                        address1: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address1,
                        address2: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address2,
                        city: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.city,
                        county: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.county,
                        postcode: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.postcode,
                        country: paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.country,
                    }
                });
        }
        else{
            updatePaymentDetails({
                ...paymentDetails,
                    billingDeliveryConfirmed: true,
                    billingDeliverySet: true
                });
        }

        RecreateOrderIntent();
    };

    const deliveryOptions = getDeliveryOptions();

    if(paymentDetails && paymentDetails.deliveryAddress && 
      (paymentDetails.deliveryAddress.country == "UK" || paymentDetails.deliveryAddress.country == "" || paymentDetails.deliveryAddress.country == null) &&
       paymentDetails?.deliveryMethod === "International Delivery" && paymentDetails?.deliveryMethod !== null) {
            updatePaymentDetailsFromForm('deliveryMethod', '');        
    }

    return paymentDetails.billingDeliveryConfirmed === false && paymentDetails.userDetailsSet === true ? (
        <form style={{width:'100%'}} onSubmit={handleSubmit(submitFrom)}>
            
            <RowColumn>
                <Column>
                    <CheckoutStageTitle>
                        <strong>Delivery</strong>
                    </CheckoutStageTitle>
                    { 
                        paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.country !== "UK" && paymentDetails.deliveryAddress.country !== ''
                            ? <InternationalDelivery><strong>Deliveries outside of the UK</strong><br />Place your order and we will contact you with a quote for delivery, if you are not happy with the quote we will refund your order in full.</InternationalDelivery>
                            : <DropdownList
                                name="deliveryMethod" 
                                keyValue='deliveryMethod'
                                label="Delivery Options" 
                                value={ paymentDetails && paymentDetails.deliveryMethod ? paymentDetails.deliveryMethod : '' }
                                options={deliveryOptions}
                                onChange={(keyValue, value) => {  
                                    updatePaymentDetailsFromForm(keyValue, value.value);
                                }} 
                                error={errors.deliveryMethod} 
                                ref={register({
                                    required: 'Select a delivery option.',
                                })}
                                required
                                showDefaultValue={true}
                            />
                        }
                    </Column>
            </RowColumn>

            <RowColumn>
                <Column>
                    <Checkbox 
                        keyValue='sameBillingAddress' 
                        name='sameBillingAddress' 
                        value={paymentDetails.sameBillingAddress} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value);
                        }} 
                        label="Use same delivery and billing address" 
                        error={errors.sameBillingAddress} 
                        />

                    <InputText 
                        name='address'
                        keyValue='deliveryAddress.address'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value);
                        }} 
                        label="Delivery Address" 
                        ref={register({
                            required: 'Enter an address.',
                            minLength: { value: 4, message: "Must be longer than 3 characters" },
                        })}
                        error={errors.address} 
                        placeholder={'Address'}
                        required />

                    <InputText 
                        name='address1'
                        keyValue='deliveryAddress.address1'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address1} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value);
                        }} 
                        placeholder={'Address line 1'}
                        />
                    <InputText 
                        name='address2'
                        keyValue='deliveryAddress.address2'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address2} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value); 
                        }} 
                        placeholder={'Address line 2'}
                        />
                    
                    <InputText 
                        name='postcode'
                        keyValue='deliveryAddress.postcode'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.postcode} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value); 
                        }} 
                        ref={register({
                            required: 'Enter a postcode.',
                            minLength: { value: 4, message: "Must be longer than 3 characters" },
                        })}
                        label="Postcode" 
                        placeholder={'PostCode'}
                        error={errors.postcode} 
                        required
                        />

                    <InputText 
                        name='county'
                        keyValue='deliveryAddress.county'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.county} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value);
                        }} 
                        label="County" 
                        ref={register({
                            required: 'Enter a county.',
                            minLength: { value: 4, message: "Must be longer than 3 characters" },
                        })}
                        error={errors.county} 
                        placeholder={'County'}
                        required />

                    <InputText 
                        name='city'
                        keyValue='deliveryAddress.city'
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.city} 
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value);  
                        }} 
                        ref={register({
                            required: 'Enter a city.',
                            minLength: { value: 4, message: "Must be longer than 3 characters" },
                        })}
                        label="City" 
                        placeholder={'City'}
                        error={errors.city} 
                        required
                        />

                    <DropdownList 
                        name="country" 
                        keyValue='deliveryAddress.country' 
                        label="Country" 
                        value={paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.country ? paymentDetails.deliveryAddress.country : ''}
                        options={COUNTRY_CODES}
                        onChange={(keyValue, value) => {
                            updatePaymentDetailsFromForm(keyValue, value.value);
                        }}
                        error={errors.country} 
                        ref={register({
                            required: 'Select a country.',
                        })}
                        showDefaultValue={true}
                        required />
                </Column>

                 <BillingAddress isVisible={!paymentDetails.sameBillingAddress}>
                    <Column>
                        <EmptyBlock />
                        <InputText 
                            name='billingAddress'
                            keyValue='billingAddress.address'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.address} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            label="Billing Address" 
                            ref={!paymentDetails.sameBillingAddress ? register({
                                required: 'Enter an address.',
                                minLength: { value: 4, message: "Must be longer than 3 characters" },
                            }) : null}
                            error={errors.billingAddress} 
                            placeholder={'Address'}
                            required={!paymentDetails.sameBillingAddress}
                            disabled={paymentDetails.sameBillingAddress} />

                        <InputText 
                            name='billingAddress1'
                            keyValue='billingAddress.address1'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.address1} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            placeholder={'Billing Address line 1'}
                            disabled={paymentDetails.sameBillingAddress}
                            />
                        <InputText 
                            name='billingAddress2'
                            keyValue='billingAddress.address2'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.address2} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            placeholder={'Billing Address line 2'}
                            disabled={paymentDetails.sameBillingAddress}
                            />
                        
                        <InputText 
                            name='billingPostcode'
                            keyValue='billingAddress.postcode'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.postcode} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            ref={!paymentDetails.sameBillingAddress ? register({
                                required: 'Enter a postcode.',
                                minLength: { value: 4, message: "Must be longer than 3 characters" },
                            }) : null}
                            label="Postcode" 
                            placeholder={'PostCode'}
                            error={errors.billingPostcode} 
                            required={!paymentDetails.sameBillingAddress}
                            disabled={paymentDetails.sameBillingAddress}
                            />

                        <InputText 
                            name='billingCounty'
                            keyValue='billingAddress.county'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.county} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            label="County" 
                            ref={!paymentDetails.sameBillingAddress ? register({
                                required: 'Enter a county.',
                                minLength: { value: 4, message: "Must be longer than 3 characters" },
                            }) : null}
                            error={errors.billingCounty} 
                            placeholder={'County'}
                            required={!paymentDetails.sameBillingAddress}
                            disabled={paymentDetails.sameBillingAddress} />

                        <InputText 
                            name='billingCity'
                            keyValue='billingAddress.city'
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.city} 
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value);
                            }} 
                            ref={!paymentDetails.sameBillingAddress ? register({
                                required: 'Enter a city.',
                                minLength: { value: 4, message: "Must be longer than 3 characters" },
                            }) : null}
                            label="City" 
                            placeholder={'City'}
                            error={errors.billingCity} 
                            required={!paymentDetails.sameBillingAddress}
                            disabled={paymentDetails.sameBillingAddress}
                            />

                        <DropdownList 
                            name="billingCountry" 
                            keyValue='billingAddress.country' 
                            label="Country" 
                            value={paymentDetails.billingAddress && paymentDetails.billingAddress.country}
                            options={COUNTRY_CODES}
                            onChange={(keyValue, value) => {
                                updatePaymentDetailsFromForm(keyValue, value.value);
                            }}
                            error={errors.billingCountry} 
                            ref={!paymentDetails.sameBillingAddress ? register({
                                required: 'Enter a country.',
                            }) : null}
                            showDefaultValue={true}
                            required={!paymentDetails.sameBillingAddress}
                            disabled={paymentDetails.sameBillingAddress} 
                            />
                   
                    </Column>
                </BillingAddress>
            </RowColumn>
            <RowColumn>
                <Pointer><Button>Next</Button></Pointer>
            </RowColumn>
        </form>
    ) : <></>;
}