import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import ImageList from './Images';
import Checkbox from './Checkbox';
import InputText from './Input';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';

import Compress from 'compress.js';

const ImageUploadContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ImageUploadLabelContainer = styled.div`
    display:flex;
    justify-content: center;
    margin: 10px 0 10px 0;
`;

const ImageUploadLabel = styled.label`
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
`;

const ImageUpload = styled.input`
    display:none;
    width: 100%;
`;

const Images = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
`;

const UploaderOptions = styled.div`
  ${props => props.visible ? 'display: flex;' : 'display:none;'}
  flex-direction: row;
  justify-content: center;
`;

const UploaderOptionsLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

const UploaderOptionsInput = styled.div`
  padding: 5px;
`;

type Props = {
  label: string,
  required: boolean,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  onSubmit: (e: object, images:object) => void,
  updateImages: (images:object) => void,
  error?: string,
  disabled: boolean,
  name: string,
};

const ImageUploader = React.forwardRef(({
  label,
  required,
  keyValue,
  value,
  onChange,
  onSubmit,
  updateImages,
  error,
  disabled,
  baseImagesLocation,
  isUploading,
}: Props, ref) => {
  const defaultValue = value !== undefined && value !== null ? value.map((value, idx)=> { return ({ order: idx, path: value } )}) : [];
  const defaultVisibleValue = value !== undefined && value !== null ? value.map((value, idx)=> { return ({ order: idx, path: value } )}) : [];
  const defaultUploadOptions = { visible: true, maxHeight: 998, maxWidth: 998, resize: true };
  if(value === undefined || value === null)
  {
    value = [];
  }

  const [stateImages, setImages] = useState(defaultValue);
  const [stateVisibleImages, setVisibleImages] = useState(defaultVisibleValue);
  const [uploadOptions, setUploadOptions] = useState(defaultUploadOptions);

  useEffect(() => {
    setImages(defaultValue);
    setVisibleImages(defaultVisibleValue);
  },[]);

  useEffect(() => {
    if(defaultValue.length !== stateVisibleImages.length)
    {
      setImages(defaultValue);
      setVisibleImages(defaultVisibleValue);
    }
  }, [defaultValue]);

  const fileInput = useRef(null);

  function RemoveImage(e)
  {
    const newStateImages = stateImages.filter( x => `${x.path}-${x.order}` !==e.target.id) ?? [];

    const images = newStateImages.filter( x => x.path.includes('data:image') === false).map((image, idx) => { 
      return { path: image.path, order: idx}
    });

    setImages(newStateImages);

    const newStateVisibleImages = stateVisibleImages.filter( x => `${x.path}-${x.order}` !==e.target.id) ?? [];

    setVisibleImages(newStateVisibleImages);

    updateImages(images);
  }

  function OnSubmit(e)
  { 
    e.preventDefault();

    let count = fileInput.current.files.length;

    const form = new FormData();
    const newStateImages = stateImages == null ? [] : stateImages;
    const newStateVisibleImages = stateVisibleImages == null ? [] : stateVisibleImages;

    for(let i=0; i < fileInput.current.files.length; i++)
    {
      if(fileInput.current.files[i].type.includes('image'))
      {
        checkFile(fileInput.current.files[i], i);
      }
    }

    function checkFile(file, counter) {
      var reader = new FileReader();

      reader.onloadend  = async(event) => {     

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        newStateImages.push({ path: file.name, order: newStateImages.length });

        newStateVisibleImages.push({ path: reader.result, order: newStateVisibleImages.length });

        setImages(newStateImages);
        setVisibleImages(newStateVisibleImages);

        
        let fileOfBlob = file;
        let resizedImage = file;

        if(file.type.includes('svg') || file.type.includes('sml')) {
        }
        else {
          const compress = new Compress();
          const resizedImage = await compress.compress([file], {
            size: 2, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: uploadOptions.maxWidth, // the max width of the output image, defaults to 1920px
            maxHeight: uploadOptions.maxHeight, // the max height of the output image, defaults to 1920px
            resize: uploadOptions.resize // defaults to true, set false if you do not want to resize the image width and height
          })

          const filename = file.name;
          const img = resizedImage[0];
          const base64str = img.data;
          const imgExt = img.ext;
          const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
          
          const blob = new Blob([resizedFiile], {type : file.type});      
          fileOfBlob = new File([blob], filename);
        }

        const form = new FormData();
        form.append('images', fileOfBlob);

        onSubmit(form, newStateImages, resizedImage); 

        return false;
      };

      reader.readAsDataURL(file);

      return false;
    }
  }

  const moveImage = (dragIndex, hoverIndex) => {

    const draggedImage = stateImages[dragIndex];

    const newOrder = update(stateImages, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]
    });

    setImages(newOrder);

    const newVisibleOrder = update(stateVisibleImages, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]
    });

    setVisibleImages(newVisibleOrder);

    const images = newOrder.map((image, idx) => { 
      return { path: image.path, order: idx}
    });
    updateImages(images);
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); return false; }}>
      <ImageUploadContainer>
          <ImageUploadLabelContainer>
            <ImageUploadLabel>
                {label}
                <ImageUpload 
                    id={'imageUpload'} 
                    type='file' 
                    name={'imageUpload'}
                    ref={fileInput} 
                    accept='image/*' 
                    onChange={OnSubmit} 
                    pattern="image/*"
                    multiple />
            </ImageUploadLabel>
          </ImageUploadLabelContainer>

          <UploaderOptionsLabel visible={true} onClick={() => setUploadOptions({...uploadOptions, visible: !uploadOptions.visible})}>
            {  uploadOptions.visible ? <ArrowUp />: <ArrowDown />}
            Options
          </UploaderOptionsLabel>

          <UploaderOptions visible={uploadOptions.visible}>
            <UploaderOptionsInput>
              <Checkbox 
                  keyValue='resize' 
                  name='resize' 
                  value={uploadOptions.resize} 
                  onChange={(keyValue, value) => {
                    setUploadOptions({...uploadOptions, resize: value})
                  }} 
                  label="Resize on upload" 
                  error={null} 
              />
            </UploaderOptionsInput>
            <UploaderOptionsInput>
              <InputText 
                  name='maxHeight'
                  keyValue='maxHeight'
                  value={uploadOptions.maxHeight} 
                  onChange={(keyValue, value) => {
                    setUploadOptions({...uploadOptions, maxHeight: value})
                  }} 
                  label="Max Height" 
                  ref={null}
                  error={null} 
              />
            </UploaderOptionsInput>
            <UploaderOptionsInput>
              <InputText 
                  name='maxWidth'
                  keyValue='maxWidth'
                  value={uploadOptions.maxWidth} 
                  onChange={(keyValue, value) => {
                    setUploadOptions({...uploadOptions, maxWidth: value})
                  }} 
                  label="Max Width" 
                  ref={null}
                  error={null} 
              />
            </UploaderOptionsInput>
          </UploaderOptions>

          <Images>
            <DndProvider backend={HTML5Backend}>
              <ImageList images={stateVisibleImages} 
                         moveImage={moveImage} 
                         baseImagesLocation={baseImagesLocation} 
                         removeImage={RemoveImage}
                         isUploading={isUploading} />
            </DndProvider>
          </Images>
        </ImageUploadContainer>
    </form>
  )
});

export default ImageUploader