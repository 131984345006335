// @flow
import React from 'react';
import styled from 'styled-components';
import { imagesLocation } from '../../settings';
import BannerCarousel from '../../components/common/ImageGallery';

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color:#5e5e5e;
    width: 100%;
`;

const BlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    color:#5e5e5e;
    align-items: center ;
    position:relative;
    width:100%;
`;

const Img = styled.img`
    src: url(${props => props.src});
    overflow: hidden;
    width: 200px;

    @media (max-width: 799px) {
        width:100px;
    }
`;

const BannerCarouselContainer = styled.div`
    z-index: 0;
    width: 100%;
`;

const MultiImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-bottom:10px;
`;

const PictureBlockImage = styled.img`
    src: url(${props => props.src});
    overflow: hidden;
    width: 100%;
`;

const ImageContainer = styled.div`
  z-index:1;
`;

const IntroContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

const StyledElementContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function(props) { 

    const styledPage = props && props.styledPage;

    const textBlockItem = (itemContent, idx) => {
      if(idx === 0) { return null; }

      const imagePath = itemContent && itemContent.image && itemContent.image.path ? `${imagesLocation}styled-pages/published/${itemContent.image.path}` : null;
      {
        return itemContent && itemContent.image && itemContent.image.path ? 
            itemContent.imagePositionLeft ?
            <BlockContainer>
              <ImageContainer>
                <Img src={imagePath} alt={itemContent.title}/>
              </ImageContainer>
              <TextContainer>
                <strong>{itemContent.title}</strong>
                <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
              </TextContainer>
              
            </BlockContainer> : 
            <BlockContainer>
              <TextContainer>
                <strong>{itemContent.title}</strong>
                <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
              </TextContainer>
              <ImageContainer>
                <Img src={imagePath} alt={itemContent.title}/>
              </ImageContainer>
            </BlockContainer> 
          :
          <BlockContainer>
            <TextContainer>
              <strong>{itemContent.title}</strong>
              <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
            </TextContainer>
            
          </BlockContainer>
        }
      };
      
      const pictureBlockItem = (itemContent, idx) => {
      {
        if(idx === 0) { return null; }

        const images = itemContent.images.map((image) => { return image && image.path ? {path: `${image.path}`} : null });
        return itemContent.isCarosel === true ?
          <BlockContainer>
            <BannerCarouselContainer>
              <BannerCarousel 
                images={images} 
                showThumbnails={false} 
                showNav={false} 
                showBullets={true} 
                autoPlay={true} 
                baseImagesLocation={`${imagesLocation}styled-pages/published/`}
              />
            </BannerCarouselContainer>
            
          </BlockContainer> :
          <BlockContainer>
            <MultiImageContainer>
              {
                itemContent.images !== null && itemContent.images.length > 0 && itemContent.images.map((image, idx) => 
                {
                  const imagePath = image && image.path ? `${imagesLocation}styled-pages/published/${image.path}` : null;
                  return <PictureBlockImage src={imagePath} alt={'image'}/>;
                })
              }
            </MultiImageContainer>
            
          </BlockContainer>;
        }
      };

  return (
    <>
      <IntroContent>
        { 
          styledPage && styledPage.itemBlocks !== null && styledPage.itemBlocks.length > 0 ? 
            styledPage.itemBlocks[0].blockType === 0 ? 
              textBlockItem(styledPage.itemBlocks[0], null) : 
              pictureBlockItem(styledPage.itemBlocks[0], null) : 
            <Loading>Whoops! These been an error.</Loading>
        }
      </IntroContent>
      <Content>
        {styledPage && styledPage.itemBlocks && styledPage.itemBlocks.map((itemContent, idx) => {
            return itemContent && itemContent.orderPostion >= 0 ? 
            itemContent.blockType === 0 ? textBlockItem(itemContent, idx) : pictureBlockItem(itemContent, idx) : 
            null;
        })}
      </Content>
    </>);
}