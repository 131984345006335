// @flow
import axios from 'axios';

const defaultConfig = () => {
  const store = JSON.parse(localStorage.getItem('persist:root'));
  let token = '';

  if(store && store.authDetails)
  {
    const user = JSON.parse(store.authDetails);
    token = user.loginDetails && user.loginDetails.token;
  }

  return ({
    responseType: 'json',
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });
}

const multipartConfig = () => {
  const store = JSON.parse(localStorage.getItem('persist:root'));
  let token = '';

  if(store && store.authDetails)
  {
    const user = JSON.parse(store.authDetails);
    token = user.loginDetails && user.loginDetails.token;
  }

  return ({
    responseType: 'json',
    headers: token 
      ? { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data',  'Access-Control-Allow-Origin': '*', } 
      : { 'content-type': 'multipart/form-data',  'Access-Control-Allow-Origin': '*', },
  });
}

type Options = {
  url: string,
  config: Object,
  data?: Object,
  token: string,
};

export function get(options: Options) {
  const { url, config = {}, token } = options;
  return axios.get(url, { ...defaultConfig({ token }), ...config });
}

export function post(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.post(url, data, { ...defaultConfig({ token }), ...config });
}

export function httpDelete(options: Options) {
  const { url, config = {}, token } = options;
  return axios.delete(url, { ...defaultConfig({ token }), ...config });
}

export function postFiles(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.post(url, data, { ...multipartConfig({ token }), ...config });
}

export function httpPut(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.put(url, data, { ...defaultConfig({ token }), ...config });
}
