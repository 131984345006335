// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as customerDetailsActions } from '../../state/actions/customerDetails';

export function useViewCustomerDetails(props) {
  const dispatch = useDispatch();

  const customerDetailsStore = useSelector(state => state.customerDetails);
  const [customerDetails, setCustomerDetails ] = useState(customerDetailsStore);

  useEffect(() => {
    dispatch(customerDetailsActions.loadCustomerDetails());
  },[]);

  useEffect(() => {
      setCustomerDetails(customerDetailsStore); 
  },[customerDetailsStore.isLoading]);

  useEffect(() => {

  },[customerDetails, dispatch]);

  const UpdateCustomerDetails = (keyValue, newValue) => {
    const lensSet = (key, newValue) => {
      const newCustomerDetails = set(lensPath(key.split('.')), newValue, customerDetails.customerDetails);
      setCustomerDetails({...customerDetails, customerDetails: newCustomerDetails});
    };
    lensSet(keyValue, newValue);
  };

  const saveAccountSubmit = () => {
    dispatch(customerDetailsActions.postUpdateCustomerDetails(customerDetails.customerDetails));
  };

  return {
    error: customerDetailsStore.error,
    isLoading: customerDetailsStore.isLoading,
    saveCompleteMessage: customerDetailsStore.saveCompleteMessage,
    saveAccountSubmit,
    UpdateCustomerDetails,
    customerDetails: customerDetails.customerDetails,
  };
}
