export const constants = {
    loadContactUsEmail: 'LOAD_CONTACT_US_EMAIL',
    loadContactUsEmailSuccess: 'LOAD_CONTACT_US_EMAIL_SUCCESS',
    loadContactUsEmailFailure: 'LOAD_CONTACT_US_EMAIL_FAILURE',
};

export const actions = {
    loadContactUsEmail: emailForm => ({
        type: constants.loadContactUsEmail,
        message: emailForm
    }),
    loadContactUsEmailSuccess: payload => ({
        type: constants.loadContactUsEmailSuccess,
        payload,
    }),
    loadContactUsEmailFailure: error => ({
        type: constants.loadContactUsEmailFailure,
        error,
    })
};