export const constants = {
    loadBlogs: 'LOAD_BLOGS',
    loadBlogsSuccess: 'LOAD_BLOGS_SEARCH_SUCCESS',
    loadBlogsFailure: 'LOAD_BLOGS_SEARCH_FAILURE',

    loadBlog: 'LOAD_BLOG',
    loadBlogSuccess: 'LOAD_BLOG_SUCCESS',
    loadBlogFailure: 'LOAD_BLOG_FAILURE',

    loadBlogDesigns: 'LOAD_BLOG_DESIGNS',
    loadBlogDesignsSuccess: 'LOAD_BLOG_DESIGNS_SEARCH_SUCCESS',
    loadBlogDesignsFailure: 'LOAD_BLOG_DESIGNS_SEARCH_FAILURE',

    loadBlogDesign: 'LOAD_BLOG_DESIGN',
    loadBlogDesignSuccess: 'LOAD_BLOG_DESIGN_SEARCH_SUCCESS',
    loadBlogDesignFailure: 'LOAD_BLOG_DESIGN_SEARCH_FAILURE',

    saveBlogDesign: 'SAVE_BLOG_DESIGN',
    saveBlogDesignSuccess: 'SAVE_BLOG_DESIGN_SUCCESS',
    saveBlogDesignFailure: 'SAVE_BLOG_DESIGN_FAILURE',

    newBlogDesign: 'NEW_BLOG_DESIGN',
    newBlogDesignSuccess: 'NEW_BLOG_DESIGN_SUCCESS',
    newBlogDesignFailure: 'NEW_BLOG_DESIGN_FAILURE',

    publishBlogDesign: 'PUBLISH_BLOG_DESIGN',
    publishBlogDesignSuccess: 'PUBLISH_BLOG_DESIGN_SUCCESS',
    publishBlogDesignFailure: 'PUBLISH_BLOG_DESIGN_FAILURE',

    removeBlog: 'REMOVE_BLOG',
    removeBlogSuccess: 'REMOVE_BLOG_SUCCESS',
    removeBlogFailure: 'REMOVE_BLOG_FAILURE',

    imageUpload: 'IMAGE_UPLOAD',
    imageUploadSuccess: 'IMAGE_UPLOAD_SUCCESS',
    imageUploadFailure: 'IMAGE_UPLOAD_FAILURE',
};

export const actions = {
    loadBlogs: () => ({
        type: constants.loadBlogs
    }),
    loadBlogsSuccess: payload => ({
        type: constants.loadBlogsSuccess,
        payload,
    }),
    loadBlogsFailure: error => ({
        type: constants.loadBlogsFailure,
        error,
    }),

    loadBlog: payload => ({
        type: constants.loadBlog,
        payload
    }),
    loadBlogSuccess: payload => ({
        type: constants.loadBlogSuccess,
        payload,
    }),
    loadBlogFailure: error => ({
        type: constants.loadBlogFailure,
        error,
    }),

    loadBlogDesigns: () => ({
        type: constants.loadBlogDesigns
    }),
    loadBlogDesignsSuccess: payload => ({
        type: constants.loadBlogDesignsSuccess,
        payload,
    }),
    loadBlogDesignsFailure: error => ({
        type: constants.loadBlogDesignsFailure,
        error,
    }),

    loadBlogDesign: payload => ({
        type: constants.loadBlogDesign,
        payload
    }),
    loadBlogDesignSuccess: payload => ({
        type: constants.loadBlogDesignSuccess,
        payload,
    }),
    loadBlogDesignFailure: error => ({
        type: constants.loadBlogDesignFailure,
        error,
    }),

    newBlogDesign: blog => ({
        type: constants.newBlogDesign,
        payload: blog
    }),
    newBlogDesignSuccess: payload => ({
        type: constants.newBlogDesignSuccess,
        payload,
    }),
    newBlogDesignFailure: error => ({
        type: constants.newBlogDesignFailure,
        error,
    }),

    saveBlogDesign: blog => ({
        type: constants.saveBlogDesign,
        payload: blog
    }),
    saveBlogDesignSuccess: payload => ({
        type: constants.saveBlogDesignSuccess,
        payload,
    }),
    saveBlogDesignFailure: error => ({
        type: constants.saveBlogDesignFailure,
        error,
    }),

    publishBlogDesign: blog => ({
        type: constants.publishBlogDesign,
        payload: blog
    }),
    publishBlogDesignSuccess: payload => ({
        type: constants.publishBlogDesignSuccess,
        payload,
    }),
    publishBlogDesignFailure: error => ({
        type: constants.publishBlogDesignFailure,
        error,
    }),

    removeBlog: blog => ({
        type: constants.removeBlog,
        payload: blog
    }),
    removeBlogSuccess: payload => ({
        type: constants.removeBlogSuccess,
        payload,
    }),
    removeBlogFailure: error => ({
        type: constants.removeBlogFailure,
        error,
    }),

    imageUpload: imageUpload => ({
        type: constants.imageUpload,
        payload: imageUpload,
    }),
    imageUploadSuccess: payload => ({
        type: constants.imageUploadSuccess,
        payload,
    }),
    imageUploadFailure: error => ({
        type: constants.imageUploadFailure,
        error,
    })
};