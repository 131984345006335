/* eslint-disable import/no-webpack-loader-syntax */
// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NavigationPannel from '../components/menu/NavigationPannel';

type SidebarProps = {
    router: Object,
};

const mapStateToProps = (props: SidebarProps) => ({
    pathname: props.router.location.pathname,
});

export default withRouter(connect(mapStateToProps)(NavigationPannel));