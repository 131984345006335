// @flow
import routerSaga from './router';
import menuDetailsSaga from './menu';
import productSaga from './products';
import adminProductSaga from './adminProducts';
import authSaga from './auth';
import accountSaga from './resetPassword';
import contactUs from './contactUs';
import deliveryDetails from './basket';
import adminOrders from './adminOrders';
import adminAccounts from './adminAccounts';
import customerOrders from './customerOrders';
import customerDetails from './customerDetails';
import blogs from './blogs';
import styledPages from './styledPages';
import home from './home';
import adminCategories from './adminCategories';
import adminOpeningTimes from './adminOpeningTimes';

export default [...routerSaga, menuDetailsSaga, productSaga, adminProductSaga, authSaga, 
    accountSaga, contactUs, deliveryDetails, adminOrders, adminAccounts, customerOrders,
    customerDetails, blogs, styledPages, home, adminCategories, adminOpeningTimes];