// @flow
import { constants } from '../actions/adminOrders';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  orders: object,
  products: object,
};

const initialState: State = { 
  orders: null,
  products: null,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadAdminOrdersSearch:
      return { ...state, orders: null, isLoading: true };

    case constants.loadAdminOrdersSearchSuccess: {
      return {
        ...state,
          orders: action.payload.orders,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadAdminOrdersSearchFailure:
      return { ...state, error: action.error, orders: null, isLoading: false };

      case constants.postAdminUpdateOrder:
        return { ...state, isLoading: true };
  
      case constants.postAdminUpdateOrderSuccess: {
        return {
          ...state,
            error: null,
            isLoading: false,
        };
      }
      case constants.postAdminUpdateOrderFailure:
        return { ...state, error: action.error, isLoading: false };
    default:
      return state;
    }
}
