import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/basket';
import { get, post } from '../../helpers/http';
import { accountsUrl, ordersUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadDeliveryAndBillingDetails() {

  try {
    
    const url = `${accountsUrl}/delivery-details`;

    const response = yield call(get, { url });

    yield put(actions.loadDeliveryAndBillingDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadDeliveryAndBillingDetailsFailure(GetErrorResponseMessage(error, "deliveryAndBillingDetails", REQUEST_METHODS.GET)));
  }
}

function* loadOrderClientSecret({ payload }) {

  try {
    
    const url = `${ordersUrl}/order-secret`;

    const response = yield call(post, { url, data: payload });

    yield put(actions.loadOrderClientSecretSuccess(response.data));
  } catch (error) {
    yield put(actions.loadOrderClientSecretFailure(GetErrorResponseMessage(error, "deliveryAndBillingDetails", REQUEST_METHODS.POST)));
  }
}

function* postOrderNewDetails({ payload }) {

  try {
    
    const url = `${ordersUrl}`;

    const response = yield call(post, { url, data: payload });

    yield put(actions.postOrderNewDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.postOrderNewDetailsFailure(GetErrorResponseMessage(error, "deliveryAndBillingDetails-Order", REQUEST_METHODS.POST)));
  }
}

function* resetOrderNewDetails() {
  try {
    yield put(actions.resetOrderNewDetailsSuccess());
  } catch (error) {
    yield put(actions.postOrderNewDetailsFailure(GetErrorResponseMessage(error, "deliveryAndBillingDetails-OrderReset", REQUEST_METHODS.POST)));
  }
}

export default function* basketSaga() {
  yield takeLatest(constants.loadDeliveryAndBillingDetails, loadDeliveryAndBillingDetails);
  yield takeLatest(constants.loadOrderClientSecret, loadOrderClientSecret);
  yield takeLatest(constants.postOrderNewDetails, postOrderNewDetails);
  yield takeLatest(constants.resetOrderNewDetails, resetOrderNewDetails);
}
