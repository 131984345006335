const GET = 'get';
const POST = 'post';
const DELETE = 'delete';
const UPLOAD = 'upload';

const REQUEST_METHODS = {
    GET,
    POST,
    DELETE,
    UPLOAD,
};

export default REQUEST_METHODS;