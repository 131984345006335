// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { useResetPassword } from './useResetPassword';
import Input from '../common/Input';

const Button = styled.button`
    padding:5px;
    min-width: 100px;
    margin-top: 10px;
    margin-right: 10px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 10px;
    color:#5e5e5e;
    background-color: #fafafa;
    width: 100%;
    margin: 5px;

    @media (max-width: 799px) {
        flex-direction: column; 
    }
`;

const Error = styled.div`
    color: #a82700;
    font-weight: bold;
    font-size: 0.9em;
`;

const LoginForm = styled.div`
    min-width:250px;
    width: 50%;
    padding: 5px 10px 5px 10px;

    @media (max-width: 799px) {
        width: 98%;
    }
`;

export default function(props) {   

    const { token } = props;   
    const {
        error,
        message,
        changePasswordSubmit,
    } = useResetPassword();

    const { handleSubmit, register, errors } = useForm();

    return (
        <Column>
            <LoginForm>
            <form onSubmit={handleSubmit(changePasswordSubmit)}>

                <Input type='hidden' name='token' onChange={(value) => {}} ref={register()} value={token} />

                <Input
                    label='New Password' 
                    type='password' 
                    placeholder='' 
                    name="newPassword"
                    error={errors.newPassword}
                    ref={register({
                    required: 'Please enter your password.',
                    })}
                    onChange={(value) => {}}
                    required
                    />

                <Input
                    label='Confirm New Password' 
                    type='password' 
                    placeholder='' 
                    name="confirmNewPassword"
                    error={errors.confirmNewPassword}
                    ref={register({
                    required: 'Please confirm your password.',
                    })}
                    onChange={(value) => {}}
                    required
                    />

                <Button type='submit' >Save</Button> 

                <p>{message}</p>
                
                <Error>{error ? <div dangerouslySetInnerHTML={{__html:error}}></div> : ""}</Error>
            </form>
            </LoginForm>
        </Column>
    );
}