// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as productActions } from '../../state/actions/products';

export function useProductDetails(productName) {
  const dispatch = useDispatch();

  const productStore = useSelector(state => state.products);

  useEffect(() => {
    dispatch(productActions.loadProductDetails(productName));
  },[]);

  return {
    error: productStore.error,
    productDetails: productStore.productDetails,
    isLoading: productStore.isLoading,
  };
}
