// @flow
import React from 'react';
import styled from 'styled-components';
import HomePage from '../../components/styledPages/home'
import {useHomePage} from '../../components/styledPages/useStyledPages'
import LoadingAnimation from '../../components/common/LoadingAnimation';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
    padding: 20px 0px 30px 0px;
`;

export default function(props) {
    const styledPage = useHomePage('home');

    return (
        <Container>
            <Header></Header>
            {
                styledPage && styledPage.isLoading 
                ? <Loading><LoadingAnimation /></Loading> 
                : styledPage && styledPage.page && styledPage.page.itemBlocks.length > 0 
                    ? <HomePage styledPage={styledPage.page} />  
                    : styledPage && styledPage.error && styledPage.error.message 
                        ? <Loading>{styledPage.error.message}</Loading> 
                        : <Loading>We are making some changes right now, this section will be back up and running soon.</Loading>  
            }
        </Container>
    );
}