// @flow
import React from 'react';
import styled from 'styled-components';
import TestStyledPage from '../../components/admin/TestStyledPage'
import {useTestStyledPage} from '../../components/admin/useTestStyledPage'
import LoadingAnimation from '../../components/common/LoadingAnimation';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

export default function(props) {
    const pageId = props.match.params.pageId;

    const { styledPage, isLoading, error } = useTestStyledPage(pageId);

    let testPage = null;
    if(styledPage && styledPage.styledPage && styledPage.styledPage && styledPage.styledPage.title)
    {
        testPage = styledPage.styledPage;
    }

    return (
        <Container>
            <Header></Header>
            {
                isLoading === true ? <Loading><LoadingAnimation /></Loading> :
                    testPage !== null ? <TestStyledPage styledPage={testPage} /> :  
                        <Error>{error && error.message}</Error>
            }
        </Container>
    );
}