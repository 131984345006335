// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as adminBlogsActions } from '../../state/actions/blogs';

export function useAdminBlogs() {
  const dispatch = useDispatch();

  const blogStore = useSelector(state => state.blogs);

    const defaultProps = {
      designBlogs: {
        designBlogs: blogStore?.designBlogs == null ? [] : blogStore?.designBlogs
      }
  };

  const [designBlogs, setBlogs] = useState(defaultProps);
  const [editBlog, setEditBlog] = useState(null);

  useEffect(() => {
      setEditBlog(null);
      dispatch(adminBlogsActions.loadBlogDesigns());
  }, []);

  useEffect(() => {
    setBlogs(blogStore);
  },[dispatch, blogStore.isLoading]);


  useEffect(() => {
    setBlogToEdit(blogStore.selectedBlog);
  },[dispatch, blogStore.selectedBlog]);

  const updateBlog = (keyValue, newValue) => {

    const lensSet = (key, newValue, updateBlog) => {

        let updatedBlog = set(lensPath(key.split('.')), newValue, updateBlog);
        updatedBlog.published = false;
        setEditBlog(updatedBlog);
    };

    lensSet(keyValue, newValue, editBlog ?? editBlog);
  };

  const setBlogToEdit = blog => {
    setEditBlog(blog); 
  };

  function sortByDate(a, b) {
    const dateA = a.dateCreated;
    const dateB = b.dateCreated;
  
    let comparison = 0;
    if (dateA < dateB) {
      comparison = 1;
    } else if (dateA > dateB) {
      comparison = -1;
    }
    return comparison;
  }

  const onSubmit = form => {
    const updateBlog = {
      id: form.id, 
      name:form.name, 
      body:form.body, 
      images:form.images, 
      dateCreated: form.dateCreated, 
      published: false
    };

    let updatedBlogs = designBlogs.designBlogs.filter(x => x.id !== form.id);
    updatedBlogs.push(updateBlog);

    updatedBlogs = updatedBlogs.sort(sortByDate);

    setBlogs({
      ...designBlogs,
        designBlogs: updatedBlogs
    });

    setEditBlog(updateBlog);
  
    dispatch(adminBlogsActions.saveBlogDesign(form));   
  };

  const newBlogSubmit = form => {
    dispatch(adminBlogsActions.newBlogDesign(form));   
  };

  const uploadImages = images => {
    dispatch(adminBlogsActions.imageUpload(images));
    setTimeout(1000, dispatch(adminBlogsActions.imageUpload(images)));
  };

  const removeBlog = blog => {
    let updatedBlog = designBlogs.designBlogs.filter(x => x.id !== blog.id);
    updatedBlog = updatedBlog.sort(sortByDate);

    setBlogs({
      ...designBlogs,
        designBlogs: updatedBlog
    });

    dispatch(adminBlogsActions.removeBlog(blog));   
  }

  const publishBlog = blog => {
    setEditBlog({
      ...editBlog,
        published: true
    });

    dispatch(adminBlogsActions.publishBlogDesign(blog));   
  }

  return {
    error: blogStore.error,
    designBlogs: designBlogs && designBlogs.designBlogs ? designBlogs.designBlogs : [],
    isLoading: blogStore.isLoading,
    isSaving: blogStore.isSaving,
    saveMessage: blogStore.saveMessage,
    updateBlog,
    onSubmit,
    newBlogSubmit,
    uploadImages,
    setBlogToEdit,
    editBlog,
    removeBlog,
    publishBlog,
  };
}
