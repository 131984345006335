export const constants = {
    loadAdminOrdersSearch: 'LOAD_ADMIN_ORDERS_SEARCH',
    loadAdminOrdersSearchSuccess: 'LOAD_ADMIN_ORDERS_SEARCH_SUCCESS',
    loadAdminOrdersSearchFailure: 'LOAD_ADMIN_ORDERS_SEARCH_FAILURE',

    postAdminUpdateOrder: 'POST_ADMIN_UPDATE_ORDER',
    postAdminUpdateOrderSuccess: 'POST_ADMIN_UPDATE_ORDER_SUCCESS',
    postAdminUpdateOrderFailure: 'POST_ADMIN_UPDATE_ORDER_FAILURE',
};

export const actions = {
    loadAdminOrdersSearch: () => ({
        type: constants.loadAdminOrdersSearch,
    }),
    loadAdminOrdersSearchSuccess: payload => ({
        type: constants.loadAdminOrdersSearchSuccess,
        payload,
    }),
    loadAdminOrdersSearchFailure: error => ({
        type: constants.loadAdminOrdersSearchFailure,
        error,
    }), 
    
    postAdminUpdateOrder: payload => ({
        type: constants.postAdminUpdateOrder,
        payload,
    }),
    postAdminUpdateOrderSuccess: payload => ({
        type: constants.postAdminUpdateOrderSuccess,
        payload,
    }),
    postAdminUpdateOrderFailure: error => ({
        type: constants.postAdminUpdateOrderFailure,
        error,
    }),
};