// @flow
import React from 'react';
import styled from 'styled-components';
import LoadingSvg from '../../assets/icons/loading.svg';

const Loading = styled.img`
    src: url(${prop => prop.src});
    width: 200px;
`;

const LoadingAnimation = () => {
    return <Loading src={LoadingSvg} />
};

export default LoadingAnimation