// @flow
import React from 'react';
import styled from 'styled-components';
import WorldMap from '../../components/WorldMap';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    @media (max-width: 1000px) {
        width: 100%;
    }
`;

const MapUsageDetails = styled.div`
    font-style: italic;
    font-size: 0.8em;
    color: #5e5e5e;
`;

export default function() {
    return(
        <Container>
            <Header></Header>
            <Content>
                <MapUsageDetails>Click on the red marker to expand the location, click anywhere else on the map to close the location again.<br />(Works best when your screen is landscape)</MapUsageDetails>
                <WorldMap/>
            </Content>
        </Container>
    );
}