// @flow
import React, { useRef } from 'react';
import styled from 'styled-components';
import Input from '../common/Input';
import DropdownList from '../common/DropdownObjectList';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import { useViewCustomerDetails } from './useViewCustomerDetails';
import COUNTRY_CODES from '../../helpers/countryCodes';
import LoadingAnimation from '../common/LoadingAnimation';
import {useEditAccountDetailsForm} from './useEditAccountDetailsForm';

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Button = styled.button`
    padding: 5px;
    margin-top: 10px;
    min-width: 100px;
    max-width: 150px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color:#5e5e5e;
`;

const RowColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
    padding: 10px;
    width:100%;

    @media (max-width: 799px) {
        width: auto;
        padding: 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const CustomerDetailsForm = styled.div`
    min-width:250px;
    width: 100%;
`;

const SaveComplete = styled.div`
    color: green;
`;

const Error = styled.div`
    color: red;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

export default function CheckoutUserSelection() {
    
    const {
        error,
        isLoading,
        UpdateCustomerDetails,
        saveAccountSubmit,
        customerDetails,
        saveCompleteMessage
        } = useViewCustomerDetails();
     
    const { handleSubmit, register, errors, watch, setValue } = useForm();  

    const password = useRef({});
    password.current = watch("password", "");

    useEditAccountDetailsForm(customerDetails, setValue, register);


    return isLoading === true || customerDetails === null ? <Content><Loading><LoadingAnimation /></Loading></Content> : (
    <Content>
        <Row>
            <Column> 
                <AccountActionLink to={ROUTES.MYACCOUNT} ><ArrowLeft /> Back To Account</AccountActionLink>
                <strong>My Account</strong>
            </Column> 
        </Row>
        <Row>
            <CustomerDetailsForm>
                <form style={{width:'100%'}} onSubmit={handleSubmit(saveAccountSubmit)}>
                    <RowColumn>
                        <Column>
                            <Input
                                keyValue='firstName'
                                label='First Name' 
                                type='text' 
                                value={customerDetails && customerDetails.firstName}
                                placeholder='' 
                                name="firstName"
                                error={errors.firstName}
                                onChange={(keyValue, value) => {
                                    UpdateCustomerDetails(keyValue, value, {
                                        ...customerDetails,
                                        deliveryAddress: { 
                                            ...customerDetails.firstName, 
                                            address: value 
                                        },
                                    });
    
                                    setValue('firstName', value);}} 
                                ref={register({
                                required: 'Please enter your first name.',
                                minLength: { value: 3, message: "First name must be longer than 3 characters" },
                                })}
                                required />

                            <Input
                                keyValue='lastName'
                                label='Last Name' 
                                type='text' 
                                value={customerDetails && customerDetails.lastName}
                                placeholder='' 
                                name="lastName"
                                error={errors.lastName}
                                onChange={(keyValue, value) => {
                                    UpdateCustomerDetails(keyValue, value, {
                                        ...customerDetails,
                                        deliveryAddress: { 
                                            ...customerDetails.lastName, 
                                            address: value 
                                        },
                                    });
    
                                    setValue('lastName', value);}} 
                                ref={register({
                                required: 'Please enter your last name.',
                                minLength: { value: 3, message: "Last name must be longer than 3 characters" },
                                })}
                                required />
                        </Column>
                        <Column>
                            <Input
                                keyValue='email'
                                label='Email Address/Username' 
                                type='text' 
                                value={customerDetails && customerDetails.email}
                                placeholder='' 
                                name="email"
                                error={errors.email}
                                onChange={(keyValue, value) => {
                                    UpdateCustomerDetails(keyValue, value, {
                                        ...customerDetails,
                                        deliveryAddress: { 
                                            ...customerDetails.email, 
                                            address: value 
                                        },
                                    });
    
                                    setValue('email', value);}} 
                                ref={register({
                                required: 'Please enter your email address.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address"
                                }})}
                                required
                                />

                            <Input
                                keyValue='phoneNumber'
                                label='Contact number' 
                                type='text' 
                                value={customerDetails && customerDetails.phoneNumber}
                                placeholder='' 
                                name="phoneNumber"
                                error={errors.phoneNumber}
                                onChange={(keyValue, value) => {
                                    UpdateCustomerDetails(keyValue, value, {
                                        ...customerDetails,
                                        deliveryAddress: { 
                                            ...customerDetails.phoneNumber, 
                                            address: value 
                                        },
                                    });
    
                                    setValue('phoneNumber', value);}} 
                                ref={register({
                                    required: 'Please enter your contact number.',
                                    minLength: { value: 11, message: "Contact number must be longer than 11 characters" },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Use numbers with no spaces."
                                    }
                                })}
                                required
                                />
                        </Column>
                    </RowColumn>
                        
                    <RowColumn>
                        <Column>
                            <Input 
                                name='address'
                                keyValue='deliveryAddress.address'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.address} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        address: value 
                                    },
                                });

                                setValue('address', value);}} 
                                label="Delivery Address" 
                                ref={register({
                                    required: 'Enter an address.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                error={errors.address} 
                                placeholder={'Address'}
                                required />

                            <Input 
                                name='address1'
                                keyValue='deliveryAddress.address1'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.address1} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        address1: value 
                                    },
                                });
                                
                                setValue('address1', value);}} 
                                placeholder={'Address line 1'}
                                />
                            <Input 
                                name='address2'
                                keyValue='deliveryAddress.address2'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.address2} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        address2: value 
                                    },
                                });
                                
                                setValue('address2', value);}} 
                                placeholder={'Address line 2'}
                                />
                            
                            <Input 
                                name='postCode'
                                keyValue='deliveryAddress.postCode'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.postCode} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        postCode: value 
                                    },
                                });
                                
                                setValue('postCode', value);}} 
                                ref={register({
                                    required: 'Enter a postcode.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                label="Postcode" 
                                placeholder={'PostCode'}
                                error={errors.postCode} 
                                required
                                />

                            <Input 
                                name='county'
                                keyValue='deliveryAddress.county'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.county} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        county: value 
                                    },
                                });
                                setValue('county', value);}} 
                                label="County" 
                                ref={register({
                                    required: 'Enter a county.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                error={errors.county} 
                                placeholder={'County'}
                                required />

                            <Input 
                                name='city'
                                keyValue='deliveryAddress.city'
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.city} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    deliveryAddress: { 
                                        ...customerDetails.deliveryAddress, 
                                        city: value 
                                    },
                                });
                                
                                setValue('city', value);}} 
                                ref={register({
                                    required: 'Enter a city.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                label="City" 
                                placeholder={'City'}
                                error={errors.city} 
                                required
                                />

                            <DropdownList 
                                name="country" 
                                keyValue='deliveryAddress.country' 
                                label="Country" 
                                value={customerDetails.deliveryAddress && customerDetails.deliveryAddress.country !== undefined ? customerDetails.deliveryAddress.country : null}
                                options={COUNTRY_CODES}
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value.value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.country, 
                                        country: value.value  
                                    },
                                });
                                setValue('country', value);}} 
                                error={errors.country} 
                                // ref={register({
                                //     required: 'Select a country.',
                                // })}
                                showDefaultValue={customerDetails && customerDetails.deliveryAddress && customerDetails.deliveryAddress.country === undefined}
                                required />
                        </Column>

                        <Column>

                            <Input 
                                name='billingAddress'
                                keyValue='billingAddress.address'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.address} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        address: value 
                                    },
                                });
                                setValue('billingAddress', value);}} 
                                label="Billing Address" 
                                ref={register({
                                    required: 'Enter an address.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                error={errors.billingAddress} 
                                placeholder={'Address'}
                                required={true}
                                />

                            <Input 
                                name='billingAddress1'
                                keyValue='billingAddress.address1'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.address1} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        address1: value 
                                    },
                                });
                                
                                setValue('billingAddress1', value);}} 
                                placeholder={'Billing Address line 1'}
                                
                                />
                            <Input 
                                name='billingAddress2'
                                keyValue='billingAddress.address2'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.address2} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        address2: value 
                                    },
                                });
                                
                                setValue('billingAddress2', value);}} 
                                placeholder={'Billing Address line 2'}
                                
                                />
                            
                            <Input 
                                name='billingPostcode'
                                keyValue='billingAddress.postCode'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.postCode} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        postCode: value 
                                    },
                                });
                                
                                setValue('billingPostcode', value);}} 
                                ref={register({
                                    required: 'Enter a postcode.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                label="Postcode" 
                                placeholder={'Postcode'}
                                error={errors.billingPostcode} 
                                required={true}
                                
                                />

                            <Input 
                                name='billingCounty'
                                keyValue='billingAddress.county'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.county} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        county: value 
                                    },
                                });
                                setValue('billingCounty', value);}} 
                                label="County" 
                                ref={register({
                                    required: 'Enter a county.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                error={errors.billingCounty} 
                                placeholder={'County'}
                                required={true}
                                />

                            <Input 
                                name='billingCity'
                                keyValue='billingAddress.city'
                                value={customerDetails.billingAddress && customerDetails.billingAddress.city} 
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        city: value 
                                    },
                                });
                                
                                setValue('billingCity', value);}} 
                                ref={register({
                                    required: 'Enter a city.',
                                    minLength: { value: 4, message: "Must be longer than 3 characters" },
                                })}
                                label="City" 
                                placeholder={'City'}
                                error={errors.billingCity} 
                                required={true}
                                
                                />

                            <DropdownList 
                                name="billingCountry" 
                                keyValue='billingAddress.country' 
                                label="Country" 
                                value={customerDetails.billingAddress !== null && customerDetails.billingAddress.country !== null && customerDetails.billingAddress.country !== undefined ? customerDetails.billingAddress.country : null}
                                options={COUNTRY_CODES}
                                onChange={(keyValue, value) => {
                                UpdateCustomerDetails(keyValue, value.value, {
                                    ...customerDetails,
                                    billingAddress: { 
                                        ...customerDetails.billingAddress, 
                                        country: value.value 
                                    },
                                });
                                
                                setValue('billingCountry', value.value);}}
                                error={errors.billingCountry} 
                                // ref={register({
                                //     required: 'Enter a country.',
                                // })}
                                showDefaultValue={customerDetails.billingAddress === null || customerDetails.billingAddress.country === null || customerDetails.billingAddress.country === undefined}
                                required
                                />

                        </Column>
                    </RowColumn>

                    <RowColumn>
                        <Column> 
                            <Button type='submit' >Save</Button> 
                            <SaveComplete>{saveCompleteMessage}</SaveComplete>
                            {error && error.message ? <Error>{error.message}</Error> : null}
                        </Column> 
                    </RowColumn>
                </form>
            </CustomerDetailsForm>
        </Row>
    </Content>
    );
}