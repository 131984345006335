import { accountsUrl } from '../../settings';
import { put, call, takeLatest } from 'redux-saga/effects';
import { post } from '../../helpers/http';
import { actions, constants } from '../actions/auth';

function* Login({username, password, redirectTo}) {
    const url = `${accountsUrl}/authenticate`;

    const response = yield call(post, {url, data: {username: username,
        password: password,}} );

    const data = response && response.data;
                
    if (!data.loginResult.succeeded) {
        let error = 'You entered a wrong username or password';
        if (data.loginResult.isLockedOut === true) {
            // auto logout if 401 response returned from api
            error = 'Your account has been locked beacuse there has been too mant failed log in attempts. You can try again in 30 minutes.';
        }

        if (data.loginResult.isNotAllowed === true) {
            // auto logout if 401 response returned from api
            error = 'Please verify your email address and try again. We will send your verification email again.';
        }
        
        yield put(actions.loginFailure(error)); 
    }

    if (data.loginResult.succeeded) {
        yield put(actions.loginSuccess(data, redirectTo));
    }
}

function* Logout() {
    const url = `${accountsUrl}/logout`;

    yield call(post, { url } );
}

function* postCreateCustomerAccount(payload) {

    const {firstName, lastName, contactNumber, username, password, confirmPassword} = payload;

    try {
        const url = `${accountsUrl}/register`;

        const response = yield call(post, {url, data: {
            firstName: firstName, 
            lastName: lastName, 
            phoneNumber:contactNumber, 
            email:username, 
            password: password, 
            confirmPassword: confirmPassword,
        }} );
    
        const data = response && response.data;
                    
        if (!data.loginResult.succeeded) {
            
            let error = 'You entered a wrong username or password';

            if (data.loginResult.isLockedOut === true) {
                // auto logout if 401 response returned from api
                error = 'Your account has been locked beacuse there has been too mant failed log in attempts. You can try again in 30 minutes.';
            }
    
            if (data.loginResult.isNotAllowed === true) {
                // auto logout if 401 response returned from api
                error = 'Please verify your email address and try again. We will send your verification email again.';
            }
            
            yield put(actions.loginFailure(error)); 
        }
    
        if (data.loginResult.succeeded) {
            yield put(actions.loginSuccess(data));
        }

    } catch (error) {
        yield put(actions.loginFailure(error));
    }
}

export default function* authSaga() {
  yield takeLatest(constants.Login, Login);
  yield takeLatest(constants.Logout, Logout);

  yield takeLatest(constants.postCreateCustomerAccount, postCreateCustomerAccount);
}