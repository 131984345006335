// @flow
import { constants } from '../actions/adminCategories';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  isUploading: boolean,
  error: ?Error,
  categories: object,
};

const initialState: State = { 
  categories: null,
  isLoading: true,
  isUploading: false,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadAdminCategories:
      return { ...state, categories: null, isLoading: true };

    case constants.loadAdminCategoriesSuccess: {
      return {
        ...state,
          categories: action.payload,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadAdminCategoriesFailure:
      return { ...state, error: action.error, categories: null, isLoading: false };

    case constants.postAdminUpdateCategories:
        return { ...state, isSaving: true };
  
    case constants.postAdminUpdateCategoriesSuccess: {
        return {
          ...state,
            error: null,
            categories: action.payload,
            saveCompleteMessage: action.payload.saveMessage,
            isSaving: false,
        };
      }
    case constants.postAdminUpdateCategoriesFailure:
        return { ...state, error: action.error, isSaving: false };
        
    case constants.postCategoryImageUpload:
      return { ...state, isUploading: true };
    case constants.postCategoryImageUploadSuccess: {
      return {
        ...state,
        error: null,
        isUploading: false,
        saveCompleteMessage: action.payload.saveMessage
      };
    }
    case constants.postCategoryImageUploadFailure:
      return { ...state, error: action.error, isUploading: false };

    default:
      return state;
    }
}
