// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as adminProductActions } from '../../state/actions/adminProducts';
import { pageSize } from '../../settings';

export function useAdminProductSearch(props) {

  const dispatch = useDispatch();

  const productStore = useSelector(state => state.adminProducts);

  const {
        searchText, 
        brand, 
        category, 
        subCategory, 
        subSubCategory,
        location
      } = props;


  const defaultProps = {
    searchText: productStore?.searchResults?.filters?.searchText == null ? searchText : productStore?.searchResults?.filters?.searchText , 
    brand: productStore?.searchResults?.filters?.brand == null ? brand : productStore?.searchResults?.filters?.brand, 
    category: productStore?.searchResults?.filters?.category == null ? category : productStore?.searchResults?.filters?.category, 
    subCategory: productStore?.searchResults?.filters?.subCategory == null ? subCategory : productStore?.searchResults?.filters?.subCategory, 
    subSubCategory: productStore?.searchResults?.filters?.subSubCategory == null ? subSubCategory : productStore?.searchResults?.filters?.subSubCategory,
    maxPrice: productStore?.searchResults?.filters?.maxPrice == null ? null : productStore?.searchResults?.filters?.maxPrice,
    minPrice: productStore?.searchResults?.filters?.minPrice == null ? null : productStore?.searchResults?.filters?.minPrice,
    page: productStore?.searchResults?.filters?.page == null ? 0 : productStore?.searchResults?.filters?.page,
    pageSize: productStore?.searchResults?.filters?.pageSize == null ? pageSize : productStore?.searchResults?.filters?.pageSize, 
    location: productStore?.searchResults?.filters?.location == null ? location : productStore?.searchResults?.filters?.location,
    sortBy: productStore?.searchResults?.filters?.sortBy == null ? '' : productStore?.searchResults?.filters?.sortBy,
    sortByOptions: productStore?.searchResults?.filters?.sortByOptions == null ? [] : productStore?.searchResults?.filters?.sortByOptions,
    filterableProductCategories: productStore?.searchResults?.filters?.filterableProductCategories == null ? [] : productStore?.searchResults?.filters?.filterableProductCategories,
    filterableSearchString: productStore?.searchResults?.filters?.filterableSearchString == null ? '' : productStore?.searchResults?.filters?.filterableSearchString,
  };

  const [filters, setFilters] = useState(defaultProps);

  const updateFilters = filters => {
    setFilters(filters);
  }

  if(location !== filters.location ||
  category !== filters.category || 
  subCategory !== filters.subCategory || 
  subSubCategory !== filters.subSubCategory || 
  brand !== filters.brand) {

    const defaultProps = {
      searchText: searchText, 
      brand: brand, 
      category: category, 
      subCategory: subCategory, 
      subSubCategory: subSubCategory,
      maxPrice: null,
      minPrice: null,
      page: 0,
      pageSize: productStore?.searchResults?.filters?.pageSize == null ? pageSize : productStore?.searchResults?.filters?.pageSize,
      location: location,
      sortBy: productStore?.searchResults?.filters?.sortBy == null ? '' : productStore?.searchResults?.filters?.sortBy,
      sortByOptions: productStore?.searchResults?.filters?.sortByOptions == null ? [] : productStore?.searchResults?.filters?.sortByOptions,
      filterableProductCategories: productStore?.searchResults?.filters?.filterableProductCategories == null ? [] : productStore?.searchResults?.filters?.filterableProductCategories,
      filterableSearchString: productStore?.searchResults?.filters?.filterableSearchString == null ? '' : productStore?.searchResults?.filters?.filterableSearchString,
    };
    setFilters(defaultProps);
    //dispatch(adminProductActions.loadAdminProductSearch(defaultProps));
  }

  useEffect(() => {
    dispatch(adminProductActions.loadAdminProductSearch(filters));
  }, [filters]);


  return {
    error: productStore.error,
    products: productStore && productStore.searchResults && productStore.searchResults.products,
    productFilters: productStore && productStore.searchResults && productStore.searchResults.filters,
    filters,
    updateFilters,
    totalPages: productStore && productStore.searchResults && productStore.searchResults.totalPages,
    isLoading: productStore.isLoading,
  };
}
