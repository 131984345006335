// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as menuActions } from '../../state/actions/menu';

export function useMenuDetails(routeId) {
  const dispatch = useDispatch();

  const [
    menuToggleExpanded,
    setToggleMenuExpanded,
  ] = useState(false);

  const [
    menuItem,
    setMenuItem,
  ] = useState(null);

  const menuStore = useSelector(state => state.menuDetails);

  useEffect(() => {
    setToggleMenuExpanded(menuToggleExpanded);
  }, [menuToggleExpanded]);

  useEffect(() => {
    dispatch(menuActions.loadMenuDetails());
  }, []);

  const toggleMenuExpandedState = () => {
    setToggleMenuExpanded(!menuToggleExpanded);
  };

  const menuItemClick = (newMenuItem, hasSubItems) => {
    if(menuItem !== newMenuItem) {
      setMenuItem(newMenuItem);
    }

    if(!hasSubItems) {
      setToggleMenuExpanded(!menuToggleExpanded);
    }
  };

  return {
    error: menuStore.error,
    categories: menuStore.categories,
    opeingHours: menuStore.openingHours,
    isLoading: menuStore.isLoading,
    toggleMenuExpandedState,
    menuToggleExpanded,
    menuItem,
    menuItemClick,
  };
}
