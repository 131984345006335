// @flow
import React from 'react';
import styled from 'styled-components';
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const FormInput = styled.input`
  padding:5px;
  font-size: 1.0em;
  ${props => props.error ? `border-color ${ERROR_COLOUR};` : `border-color ${INPUT_BORDER_COLOR};`}
  ${props => props.disabled ? `background-color: ${INPUT_DISABLED_COLOUR};` : `background-color: #fff;`}
  ${props => props.disabled ? `cursor: not-allowed;` : `cursor: inherit;`}
  color: ${INPUT_TEXT_COLOUR};

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
`;

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: baseline;
    ${props => props.inline === true ? 'flex-direction: row;' : 'flex-direction: column;'}
    ${props => props.inline === true ? null : 'margin-bottom: 10px;'};
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  readonly: Boolean,
};

const Checkbox = React.forwardRef(({
  label,
  required,
  keyValue,
  value,
  onChange,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  reference,
  inline = false,
  name,
}: Props, ref) => {
  return inline === false ? (
    <InputContainer inline={inline}>
        <LabelContainer><Label>{label}{required ? <Required error={error}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
        <FormInput 
          type='checkbox'
          placeholder={placeholder} 
          name={name}
          error={error}
          ref={ref}
          checked={value}
          onChange={onChange != null ? e => { onChange(keyValue, !value)} : ''}
        />
    </InputContainer>
  ) :
  (
    <InputContainer inline={inline}>
        <FormInput 
          type='checkbox'
          placeholder={placeholder} 
          name={name}
          error={error}
          ref={ref}
          checked={value}
          onChange={onChange != null ? e => { onChange(keyValue, !value)} : ''}
        />
        <LabelContainer><Label>{label}{required ? <Required error={error}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
    </InputContainer>
  )
});

export default Checkbox