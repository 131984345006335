// @flow
import { constants } from '../actions/resetPassword';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  message: string,
};

const initialState: State = { 
  message: null,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadResetPasswordRequest:
      return { ...state, isLoading: true, message: null, error: [] };

    case constants.loadResetPasswordRequestSuccess: {
      return {
        ...state,
        message: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadResetPasswordRequestFailure:
      return { ...state, message: null, error: action.error, isLoading: false};

    case constants.loadResetPassword:
      return { ...state, message: null , isLoading: true, error: [] };
    case constants.loadResetPasswordSuccess: {
      return {
        ...state,
        message: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadResetPasswordFailure:
      return { ...state, error: action.error, message: null, isLoading: false };
    default:
      return state;
    }
}
