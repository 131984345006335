// @flow
import { useAuth } from '../components/auth/useAuth';
import ROLES from '../constants/roles';
import { 
        checkoutEnabled,
        loginEnabled,
        createAccountEnabled, 
    } from '../settings';

export function BasketAccessRestrictions()
{
    const {
        loginDetails,
        isLoggedIn,
    } = useAuth();

    if(checkoutEnabled === false)
    {

        if (loginDetails && loginDetails.roles && loginDetails.roles.indexOf(ROLES.ADMINISTRATOR) !== -1 && isLoggedIn === true) {
            return {
                message: 'The basked is currently disabled for customers.<br />You can access this because you are logged in as an administrator.',
                isEnabled: true
            };
        }

        return {
            message: 'Sorry we have disbaled the checkout for the moment.<br/>Please come back later, we probably added something new and shiny.',
            isEnabled: false
        };
    }

    return {
        message: null,
        isEnabled: true
    };
}

export function LoginAccessRestrictions()
{
    const {
        loginDetails,
        isLoggedIn,
    } = useAuth();

    if(loginEnabled === false)
    {

        if (loginDetails && loginDetails.roles && loginDetails.roles.indexOf(ROLES.ADMINISTRATOR) !== -1 && isLoggedIn === true) {
            return {
                message: 'The login is currently disabled for customers.<br />You can access this because you are logged in as an administrator.',
                isEnabled: true
            };
        }

        return {
            message: 'Sorry we have disbaled login for the moment.<br/>Please come back later, we probably added something new and shiny.',
            isEnabled: false
        };
    }

    return {
        message: null,
        isEnabled: true
    };
}

export function CreateAccountAccessRestrictions()
{
    const {
        loginDetails,
        isLoggedIn,
    } = useAuth();

    if(createAccountEnabled === false)
    {

        if (loginDetails && loginDetails.roles && loginDetails.roles.indexOf(ROLES.ADMINISTRATOR) !== -1 && isLoggedIn === true) {
            return {
                message: 'The create account area is currently disabled for customers.<br />You can access this because you are logged in as an administrator.',
                isEnabled: true
            };
        }

        return {
            message: 'Sorry you cannot create an account for the moment.<br/>Please come back later, we probably added something new and shiny.',
            isEnabled: false
        };
    }

    return {
        message: null,
        isEnabled: true
    };
}