// @flow
import { useEffect } from 'react';

export function useCheckoutDelivery(paymentDetails, basket, updatePaymentDetailsFromForm) {

  const TotalPrice = () => 
  {     
      let total = 0;
      basket.items.forEach( x => { total += x.quantity * x.price });

      return total;
  };

  const getDeliveryOptions = () => {

      const totalPrice = TotalPrice();
  
      const deliveryOptions = [];

      const financial = (x) => Number.parseFloat(x).toFixed(2);

      if(basket.items.length === 1)
      {
          return basket.items[0].deliveryOptions.map((option, idx) => {
          
          if(totalPrice >= option.delivery.freeIfOverAmount || option.delivery.price === 0)
          {
              return { label: `FREE - ${option.delivery.description}`, value: option.delivery.name, cost: 0 };
          }
          
          return { 
              label: `£${financial(option.delivery.price)} - ${option.delivery.description}`, 
              value: option.delivery.name,
              cost: financial(option.delivery.price)
          };
      });
      }

      for(let a = 0; a < basket.items.length; a++)
      {
          for(let b = 0; b < basket.items.length; b++)
          {
              for(let i = 0; i < basket.items[a].deliveryOptions.length; i++)
              {
                  for(let k = 0; k < basket.items[b].deliveryOptions.length; k++)
                  {
                      if(basket.items[a].id !== basket.items[b].id && basket.items[a].deliveryOptions[i].delivery.name === basket.items[b].deliveryOptions[k].delivery.name)
                      {
                          if(deliveryOptions && deliveryOptions.filter(x => basket.items[a].deliveryOptions[i].delivery.name === x.name).length === 0)
                          {
                              deliveryOptions.push(basket.items[a].deliveryOptions[i].delivery);
                          }
                      }
                  }
              }
          }
      }

      return deliveryOptions.map((option, idx) => {
          
          if(totalPrice >= option.freeIfOverAmount || option.price === 0)
          {
              return { label: `FREE - ${option.description}`, value: option.name, cost: 0 };
          }
          
          return { 
              label: `£${ option.price } - ${option.description}`, 
              value: option.name,
              cost: option.price
          };
      });
  };

  useEffect(() => {

    if(paymentDetails && paymentDetails.deliveryAddress && paymentDetails.deliveryAddress?.country !== "" && paymentDetails.deliveryAddress?.country !== null)
    {
        if(paymentDetails?.deliveryAddress?.country !== undefined) 
        {
            if(paymentDetails && paymentDetails.deliveryAddress && 
                paymentDetails.deliveryAddress?.country !== "UK" &&
                paymentDetails.deliveryMethod !== 'International Delivery')
            {
                updatePaymentDetailsFromForm('deliveryMethod', 'International Delivery');
            }
        }
    }
  }, [paymentDetails]);

  return { 
    getDeliveryOptions 
  };
}
