// @flow
import React from 'react';
import styled from 'styled-components';
import ViewOrders from '../../components/accounts/ViewOrders';
import { useViewOrders } from '../../components/accounts/useViewOrders';
import LoadingAnimation from '../../components/common/LoadingAnimation';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;

    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

export default function(props) {

    const {
        error,
        orders,
        isLoading,
        GetOrderToView,
        viewOrderPaneVisible,
        UpdateViewOrderPaneVisible,
        order
        } = useViewOrders();

    const orderId = props.match && props.match.params && props.match.params.orderId;

    return(
        
            isLoading === true ? <Container><Loading><LoadingAnimation /></Loading></Container> : 
            orders != null ? 
            <Container>
                <Header></Header>
                <Content>
                    <ViewOrders props={{orders,
                    orderId, 
                    GetOrderToView,
                    viewOrderPaneVisible,
                    UpdateViewOrderPaneVisible,
                    order}}/>
                </Content>
            </Container> :  <Container><Error>{error && error.message}</Error></Container>
        );
}