// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import { imagesLocation } from '../../settings';
import { ScrollToTop } from '../../helpers/scrollToTop';
import { useEditBlogForm } from './useEditBlogForm';
import TextEditor from '../common/TextEditor';
import InputText from '../common/Input';
import ImageUploader from '../common/ImageUploader';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ColumnNoMargin = styled.div`
    padding:10px;
    width: 50%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    padding:10px;
    margin-bottom:40px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 5px;
    ${props => props.disabled ? 'background-color:#9e9e9e;' : `background-color:${props.color};`}
    ${props => props.disabled ? 'disabled:"disabled";' : null}
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const Error = styled.div`
    color: #a82700;
`;

const Complete = styled.div`
    color: green;
`;

const BlogContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    position: relative;
    background-color: #fafafa;
    text-decoration: none;
    flex-direction: column;
    border: 1px solid #adadad;
    &:hover {
        border: 1px solid #000000;
    };
    a:visited  {
        color: #000000;
    };
    cursor: pointer;
    color: #000000;
    height: min-content;

    width: 200px;

    @media (min-width: 200px) {
        width: 48%;
    }

    @media (min-width: 550px) {
        width: 48%;
    }

    @media (min-width: 780px) {
        width: 30%;
    }    
    
    @media (min-width: 800px) {
        width: 30%;
    }

    @media (min-width: 1024px) {
        width: 32%;
    }
`;

const StyledLink = styled.div`
    color: #000000;
    padding: 10px;
`;

const BlogImageContainer = styled.div`
`;

const TextContainer = styled.div`
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const Buttons = styled.div`
display: flex;
flex-wrap:wrap;
flex-direction: row;
width:100%;
`;

export default function(props) {

    const error =  props.editOpeningTimes.error;
    const isLoading =  props.editOpeningTimes.isLoading;
    const isSaving =  props.editOpeningTimes.isSaving;
    const errorMessage = props.editOpeningTimes.error && props.editOpeningTimes.error.message;
    const saveMessage =  props.editOpeningTimes.saveMessage;
    const updateOpeningTimes =  props.editOpeningTimes.updateOpeningTimes;
    const onSubmit =  props.editOpeningTimes.onSubmit;
    const editOpeningTimes =  props.editOpeningTimes.editOpeningTimes;


const { handleSubmit, register, errors, setValue } = useForm();

const submitFrom = (form) => {
    onSubmit(editOpeningTimes);
};

return(
    <Container>
        <ColumnNoMargin>
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </ColumnNoMargin>
        <Column>
           
        <form onSubmit={handleSubmit(submitFrom)}>
            <Buttons>
                <Button color={'#696969'} disabled={isSaving}>{isSaving ? 'Saving' : 'Save'}</Button>
            </Buttons>
            <Complete>{saveMessage}</Complete>
            <Error>{errorMessage}</Error>
            <InputText 
                name='opening1'
                keyValue='opening1'
                label="Line 1" 
                value={editOpeningTimes && editOpeningTimes.opening1} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening1} 
            />
            <InputText 
                name='opening2'
                keyValue='opening2'
                label="Line 2" 
                value={editOpeningTimes && editOpeningTimes.opening2} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening2} 
            />
            <InputText 
                name='opening3'
                keyValue='opening3'
                label="Line 3" 
                value={editOpeningTimes && editOpeningTimes.opening3} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening3} 
            />
            <InputText 
                name='opening4'
                keyValue='opening4'
                label="Line 4" 
                value={editOpeningTimes && editOpeningTimes.opening4} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening4} 
            />
            <InputText 
                name='opening5'
                keyValue='opening5'
                label="Line 5" 
                value={editOpeningTimes && editOpeningTimes.opening5} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening5} 
            />
            <InputText 
                name='opening65'
                keyValue='opening6'
                label="Line 6" 
                value={editOpeningTimes && editOpeningTimes.opening6} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening6} 
            />
            <InputText 
                name='opening7'
                keyValue='opening7'
                label="Line 7" 
                value={editOpeningTimes && editOpeningTimes.opening7} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening7} 
            />
            <InputText 
                name='opening8'
                keyValue='opening8'
                label="Line 8" 
                value={editOpeningTimes && editOpeningTimes.opening8} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening8} 
            />
            <InputText 
                name='opening9'
                keyValue='opening9'
                label="Line 9" 
                value={editOpeningTimes && editOpeningTimes.opening9} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening9} 
            />
            <InputText 
                name='opening10'
                keyValue='opening10'
                label="Line 10" 
                value={editOpeningTimes && editOpeningTimes.opening10} 
                onChange={(keyValue, value) => {
                    updateOpeningTimes(keyValue, value);}
                } 
                ref={register()}
                error={errors.opening10} 
            />

           </form>
        </Column>
    </Container>
);
}