// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as adminBlogsActions } from '../../state/actions/blogs';

export function useBlogs(loadAll, blogName) {
  const dispatch = useDispatch();

  const blogStore = useSelector(state => state.blogs);

  const [selectedBlog, setSelectedBlog] = useState({blogName: '', selectedBlog: null});

  useEffect(() => {
    dispatch(adminBlogsActions.loadBlogs());
  }, []);

  useEffect(() => {
    if(blogName !== null && blogName !== undefined && blogName !== '' && blogName !== selectedBlog.blogName) {
      dispatch(adminBlogsActions.loadBlog(blogName));
      setSelectedBlog({...selectedBlog, blogName: blogName});
    }
    else {
      setSelectedBlog({...selectedBlog, selectedBlog: null});
    }
  }, [blogStore, blogName]);

  useEffect(() => {
    const blog = blogStore && blogStore.selectedBlog;
    setSelectedBlog({...selectedBlog, selectedBlog: blog});
  },[dispatch, blogStore.isLoading]);

  const setBlogToView = blogName => {
    dispatch(adminBlogsActions.loadBlog(blogName));
    setSelectedBlog({...selectedBlog, blogName: blogName});
  };

  return {
    error: blogStore.error,
    blogs: blogStore && blogStore.blogs ? blogStore.blogs : [],
    selectedBlog: selectedBlog,
    isLoading: blogStore.isLoading,
    setBlogToView,
  };
}
