const ROOT = '/';
const INDEX = ROOT;
const ABOUT = '/our-story';
const PRODUCTS = '/products';
const TERMS = '/terms';
const BRAND = '/brands';
const PRIVACY_POLICY = '/privacy-policy';
const PRODUCT = '/product';
const CONTACT = '/contact';
const BLOG = '/blog';
const LOGIN = '/login';
const UNAUTHORISED = '/unauthorised';

const ADMIN = '/site-admin';
const EDIT_PRODUCT = '/edit-product';
const ADMIN_PRODUCT_SEARCH = '/admin-product-search';
const ADMIN_VIEW_ORDERS = '/view-orders';
const ADMIN_BLOGS = '/edit-blogs';
const ADMIN_TEST_BLOG = '/admin-test-blog';
const ADMIN_EDIT_CATEGORIES = '/edit-site-categories';
const ADMIN_EDIT_OPENINGTIMES = '/edit-opening-times';

const MYACCOUNT = '/my-account';
const REQUEST_PASSWORD_RESET = '/reset-password-request';
const RESET_PASSWORD = '/reset-password';
const VIEW_ORDERS = '/my-orders';
const VIEW_CUSTOMER_DETAILS = '/my-details';

const BASKET = '/basket';
const CHECKOUT = '/checkout';
const ORDER_CONFIRMATION = '/order-confirmation';

const ADD_ACCOUNT = '/add-account';
const CREATE_USER_ACCOUNT = "/create-account"

const ADMIN_STYLED_PAGES = '/admin-styled-page';
const ADMIN_TEST_STYLED_PAGES = '/admin-test-styled-page';

const ROUTES = {
    INDEX,
    ABOUT,
    PRODUCTS,
    TERMS,
    BRAND,
    CONTACT,
    BLOG,
    PRIVACY_POLICY,
    PRODUCT,
    LOGIN,
    UNAUTHORISED,
    ADMIN,
    EDIT_PRODUCT,
    ADMIN_PRODUCT_SEARCH,
    MYACCOUNT,
    VIEW_ORDERS,
    REQUEST_PASSWORD_RESET,
    RESET_PASSWORD,
    BASKET,
    CHECKOUT,
    ORDER_CONFIRMATION,
    ADMIN_VIEW_ORDERS,
    ADMIN_BLOGS,
    ADMIN_TEST_BLOG,
    ADD_ACCOUNT,
    VIEW_CUSTOMER_DETAILS,
    CREATE_USER_ACCOUNT,
    ADMIN_STYLED_PAGES,
    ADMIN_TEST_STYLED_PAGES,
    ADMIN_EDIT_CATEGORIES,
    ADMIN_EDIT_OPENINGTIMES
};

export default ROUTES;