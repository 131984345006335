import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/adminOrders';
import { get, post } from '../../helpers/http';
import { ordersUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadAdminOrdersSearch() {
  try {
    
    const url = `${ordersUrl}`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadAdminOrdersSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.loadAdminOrdersSearchFailure(GetErrorResponseMessage(error ,"adminOrders", REQUEST_METHODS.GET)));
  }
}

function* postAdminUpdateOrder({payload}) {
  try {
    const url = `${ordersUrl}/update-order/${payload.orderId}`;

    const response = yield call(post, { url,  data: payload });

    yield put(actions.postAdminUpdateOrderSuccess(response.data));
  } catch (error) {
    yield put(actions.postAdminUpdateOrderFailure(GetErrorResponseMessage(error ,"adminOrders", REQUEST_METHODS.POST)));
  }
}

export default function* adminOrdersSaga() {
  yield takeLatest(constants.loadAdminOrdersSearch, loadAdminOrdersSearch);
  yield takeLatest(constants.postAdminUpdateOrder, postAdminUpdateOrder);
}
