export const constants = {
    loadStyledPage: 'LOAD_STYLED_PAGE',
    loadStyledPageSuccess: 'LOAD_STYLED_PAGE_SUCCESS',
    loadStyledPageFailure: 'LOAD_STYLED_PAGE_FAILURE',

    postImageUpload: 'POST_UPLOAD_IMAGE',
    postImageUploadSuccess: 'POST_UPLOAD_IMAGE_SUCCESS',
    postImageUploadFailure: 'POST_UPLOAD_IMAGE_FAILURE',

    postAddStyledPage: 'POST_ADD_STYLED_PAGE',
    postAddStyledPageSuccess: 'POST_ADD_STYLED_PAGE_SUCCESS',
    postAddStyledPageFailure: 'POST_ADD_STYLED_PAGE_FAILURE',

    postAddStyledPageItemBlock: 'POST_ADD_STYLED_PAGE_TEXT_BLOCK',
    postAddStyledPageItemBlockSuccess: 'POST_ADD_STYLED_PAGE_TEXT_BLOCK_SUCCESS',
    postAddStyledPageItemBlockFailure: 'POST_ADD_STYLED_PAGE_TEXT_BLOCK_FAILURE',

    deleteStyledPageItemBlock: 'DELETE_STYLED_PAGE_TEXT_BLOCK',
    deleteStyledPageItemBlockSuccess: 'DELETE_STYLED_PAGE_TEXT_BLOCK_SUCCESS',
    deleteStyledPageItemBlockFailure: 'DELETE_STYLED_PAGE_TEXT_BLOCK_FAILURE',

    postSaveItemBlockOrder: 'POST_SAVE_ITEM_BLOCK_ORDER',
    postSaveItemBlockOrderSuccess: 'POST_SAVE_ITEM_BLOCK_ORDER_SUCCESS',
    postSaveItemBlockOrderFailure: 'POST_SAVE_ITEM_BLOCK_ORDER_FAILURE',

    postPublishStyledPage: 'POST_PUBLISH_STYLED_PAGE',
    postPublishStyledPageSuccess: 'POST_PUBLISH_STYLED_PAGE_SUCCESS',
    postPublishStyledPageFailure: 'POST_PUBLISH_STYLED_PAGE_FAILURE',
};

export const actions = {
    loadStyledPage: payload => ({
        type: constants.loadStyledPage,
        payload
    }),
    loadStyledPageSuccess: payload => ({
        type: constants.loadStyledPageSuccess,
        payload,
    }),
    loadStyledPageFailure: error => ({
        type: constants.loadStyledPageFailure,
        error,
    }), 


    postImageUpload: payload => ({
        type: constants.postImageUpload,
        payload,
    }),
    postImageUploadSuccess: payload => ({
        type: constants.postImageUploadSuccess,
        payload,
    }),
    postImageUploadFailure: error => ({
        type: constants.postImageUploadFailure,
        error,
    }),

    
    postAddStyledPage: payload => ({
        type: constants.postAddStyledPage,
        payload,
    }),
    postAddStyledPageSuccess: payload => ({
        type: constants.postAddStyledPageSuccess,
        payload,
    }),
    postAddStyledPageFailure: error => ({
        type: constants.postAddStyledPageFailure,
        error,
    }),


    postAddStyledPageItemBlock: payload => ({
        type: constants.postAddStyledPageItemBlock,
        payload,
    }),
    postAddStyledPageItemBlockSuccess: payload => ({
        type: constants.postAddStyledPageItemBlockSuccess,
        payload,
    }),
    postAddStyledPageItemBlockFailure: error => ({
        type: constants.postAddStyledPageItemBlockFailure,
        error,
    }),
    
    deleteStyledPageItemBlock: payload => ({
        type: constants.deleteStyledPageItemBlock,
        payload,
    }),
    deleteStyledPageItemBlockSuccess: payload => ({
        type: constants.deleteStyledPageItemBlockSuccess,
        payload,
    }),
    deleteStyledPageItemBlockFailure: error => ({
        type: constants.deleteStyledPageItemBlockFailure,
        error,
    }),

    postSaveItemBlockOrder: payload => ({
        type: constants.postSaveItemBlockOrder,
        payload,
    }),
    postSaveItemBlockOrderSuccess: payload => ({
        type: constants.postSaveItemBlockOrderSuccess,
        payload,
    }),
    postSaveItemBlockOrderFailure: error => ({
        type: constants.postSaveItemBlockOrderFailure,
        error,
    }),

    postPublishStyledPage: payload => ({
        type: constants.postPublishStyledPage,
        payload,
    }),
    postPublishStyledPageSuccess: payload => ({
        type: constants.postPublishStyledPageSuccess,
        payload,
    }),
    postPublishStyledPageFailure: error => ({
        type: constants.postPublishStyledPageFailure,
        error,
    }),
};