// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from '../../state/actions/resetPassword';

type ResetPasswordHook = {
  changePasswordSubmit: Function,
};

export function useResetPassword(): ResetPasswordHook {
    const dispatch = useDispatch();

    const passwordResetStore = useSelector(state => state.changePassword);

    const changePasswordSubmit = (formValues) => {
        dispatch(authActions.loadResetPassword(formValues));
    };

    useEffect(() => {
        
    },[dispatch]);

    return {
        error: passwordResetStore.error,
        message: passwordResetStore.message,
        changePasswordSubmit,
    };
}
