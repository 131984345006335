export const constants = {
    loadResetPasswordRequest: 'LOAD_RESET_PASSWORD_REQUEST',
    loadResetPasswordRequestSuccess: 'LOAD_RESET_PASSWORD_REQUEST_SUCCESS',
    loadResetPasswordRequestFailure: 'LOAD_RESET_PASSWORD_REQUEST_FAILURE',

    loadResetPassword: 'LOAD_RESET_PASSWORD',
    loadResetPasswordSuccess: 'LOAD_RESET_PASSWORD_SUCCESS',
    loadResetPasswordFailure: 'LOAD_RESET_PASSWORD_FAILURE',
};

export const actions = {
    loadResetPasswordRequest: payload => ({
        type: constants.loadResetPasswordRequest,
        payload: payload,
    }),
    loadResetPasswordRequestSuccess: payload => ({
        type: constants.loadResetPasswordRequestSuccess,
        payload,
    }),
    loadResetPasswordRequestFailure: error => ({
        type: constants.loadResetPasswordFailure,
        error,
    }),

    loadResetPassword: payload => ({
        type: constants.loadResetPassword,
        payload: payload,
    }),
    loadResetPasswordSuccess: payload => ({
        type: constants.loadResetPasswordSuccess,
        payload,
    }),
    loadResetPasswordFailure: error => ({
        type: constants.loadResetPasswordFailure,
        error,
    })
};