// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as adminAccountsActions } from '../../state/actions/adminAccounts';

export function useAdminAccounts(props) {
    const dispatch = useDispatch();

    const adminAccountsStore = useSelector(state => state.adminAccounts);

    useEffect(() => {
    },[]);

    const newAccountSubmit = form => {
        dispatch(adminAccountsActions.postAdminCreateAccount(form));
    };
    
    return {
        newAccountCreated: adminAccountsStore && adminAccountsStore.newAccountCreated,
        newAccountErrorMessage: adminAccountsStore.error && adminAccountsStore.error.message,
        isLoading: adminAccountsStore.isLoading,
        newAccountSubmit,
    };
}
