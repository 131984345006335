// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as styledPageActions } from '../../state/actions/home';

export function useHomePage(pageId) {
  const dispatch = useDispatch();

  const storeStore = useSelector(state => state.publishedStyledPages);

  const [homePage, setHomePage] = useState({
      error: null,
      page: null,
      isLoading: true});


    const lodedPage = storeStore && storeStore.pages.length === 0 ? [] : storeStore.pages.filter(x => x.title.toLowerCase() === pageId.toLowerCase());

    if(lodedPage.length === 0) {
      if(storeStore.isLoading === false) {
            if(storeStore.error && storeStore.error.message && storeStore.error.message.toLowerCase().includes(pageId.toLowerCase())) {
              if(homePage.error !== storeStore.error) {
                const updatedState = homePage;
                updatedState.error = storeStore.error;
                updatedState.isLoading = storeStore.isLoading;
                setHomePage(updatedState);
              }
            }
            else {
              dispatch(styledPageActions.loadHomePage(pageId));
              // const updatedState = homePage;
              // updatedState.page = null;
              // setHomePage(updatedState);
            }
          }
    }
    else {

      if(homePage.page !== lodedPage[0]) {
        const updatedState = homePage;
        updatedState.page = lodedPage[0];
        updatedState.error = storeStore.error;
        updatedState.isLoading = storeStore.isLoading;
        setHomePage(updatedState);
      }
    }

  
  useEffect(() => {
        const lodedPage = storeStore && storeStore.pages.length === 0  ? [] : storeStore.pages.filter(x => x.title.toLowerCase() ===pageId.toLowerCase());

        if(lodedPage.length === 0) {
          const updatedState = homePage;
          setHomePage({...homePage,
            error: updatedState.error,
            page: null,
            isLoading: updatedState.isLoading});
        }
        else {
          const updatedState = homePage;
          updatedState.page = lodedPage[0];
          updatedState.error = updatedState.error;
          updatedState.isLoading = updatedState.isLoading;
          setHomePage(updatedState);
        }
  },[storeStore.isLoading]);

  return homePage;
}
  