// @flow
import { constants } from '../actions/menu';
import { Error } from '../types/error';

export type SubSubCategories = {
  name: string,
};

export type SubCategories = {
  name: string,
  SubsubCategories: SubSubCategories[],
};

export type Categories = {
  name: string,
  brands: Brand[],
  subCategories: SubCategories[],
};

export type State = {
  isLoading: boolean,
  error: ?Error,
  categories: Categories[],
  openingHours: []
};

const initialState: State = {
  isLoading: true,
  error: null,
  categories: [],
  openingHours: [],
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadMenuDetails:
      return { ...state, isLoading: true };

    case constants.loadMenuDetailsSuccess: {
      return {
        ...state,
        categories: action.payload.menu,
        openingHours: action.payload.openingHours,
        isLoading: false,
        error: null
      };
    }
    case constants.loadMenuDetailsFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}
