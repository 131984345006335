export const constants = {
    loadCustomerDetails: 'LOAD_CUSTOMER_DETAILS',
    loadCustomerDetailsSuccess: 'LOAD_CUSTOMER_DETAILS_SUCCESS',
    loadCustomerDetailsFailure: 'LOAD_CUSTOMER_DETAILS_FAILURE',

    postUpdateCustomerDetails: 'POST_UPDATE_CUSTOMER_DETAILS',
    postUpdateCustomerDetailsSuccess: 'POST_UPDATE_CUSTOMER_DETAILS_SUCCESS',
    postUpdateCustomerDetailsFailure: 'POST_UPDATE_CUSTOMER_DETAILS_FAILURE',
};

export const actions = {
    loadCustomerDetails: () => ({
        type: constants.loadCustomerDetails,
    }),
    loadCustomerDetailsSuccess: payload => ({
        type: constants.loadCustomerDetailsSuccess,
        payload,
    }),
    loadCustomerDetailsFailure: error => ({
        type: constants.loadCustomerDetailsFailure,
        error,
    }), 
    
    postUpdateCustomerDetails: payload => ({
        type: constants.postUpdateCustomerDetails,
        payload,
    }),
    postUpdateCustomerDetailsSuccess: payload => ({
        type: constants.postUpdateCustomerDetailsSuccess,
        payload,
    }),
    postUpdateCustomerDetailsFailure: error => ({
        type: constants.postUpdateCustomerDetailsFailure,
        error,
    }),
};