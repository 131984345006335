import { call, put, takeLatest, take } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { eventChannel } from 'redux-saga';
import { actions, constants } from '../actions/router';

export const history = createBrowserHistory();

function navigateTo(action) {
    let path = action.payload;
    const pattern = new RegExp('(http)(s*)(:*/*/*)');
    if(!action.payload.startsWith('/')) {
        path = '/'.concat(path);
    }

    if(action.payload.match(pattern) !== null) {
        window.open(action.payload);
    } else {
        history.push(path);
    }
}

function createNavigationWatcher() {
    return eventChannel(emitter => 
        history.listen(location => {
            emitter(actions.navigateToSuccess(location));
        }),
    );
}

function* watchNavigations() {
    yield put(actions.navigateToSuccess(history.location));

    const navigationChannel = yield call(createNavigationWatcher);

    while (true) {
        const action = yield take(navigationChannel);
        yield put(action);
    }
}

function* routerSaga() {
    const inittialPath = history.location.pathname;
    yield put(actions.navigateTo(inittialPath));

    yield takeLatest(constants.navigateTo, navigateTo);
}

export default [routerSaga, watchNavigations];