/* eslint-env browser */

const appSettings = window.AppSettings || {};

export const {
  authOn,
  accountsUrl,
  adminAccountsUrl,
  redirectUri,
  menuDetailsUrl,
  productsUrl,
  blogsUrl,
  styledPagesUrl,
  homePageUrl,
  pageSize = 12,
  imagesLocation,
  contactUsUrl,
  ordersUrl,
  adminOpeningTimesUrl,
  adminCategoriesUrl,
  checkoutEnabled,
  loginEnabled,
  createAccountEnabled,
  paymentKey,
  analyticsId,
} = appSettings;
