// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { useResetPasswordRequest } from './useResetPasswordRequest';
import Input from '../common/Input';

const Button = styled.button`
    padding:5px;
    min-width: 100px;
    margin-top: 10px;
    margin-right: 10px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    color:#5e5e5e;
    background-color: #fafafa;
    width: 100%;
    margin: 5px;

    @media (max-width: 799px) {
        flex-direction: column; 
    }
`;

const Error = styled.div`
    color: #a82700;
    font-weight: bold;
    font-size: 0.9em;
`;

const LoginForm = styled.div`
    min-width:250px;
    width: 50%;
    padding: 5px 10px 5px 10px;

    @media (max-width: 799px) {
        width: 98%;
    }
`;

export default function(props) {      
    const {
        error,
        message,
        passwordChangeRequestSubmit,
    } = useResetPasswordRequest();

    const { handleSubmit, register, errors } = useForm();

    return (
        <Column>
            <LoginForm>
            
            <p>Please enter your email address and we will send you an email to reset your password.</p>
            <form onSubmit={handleSubmit(passwordChangeRequestSubmit)}>
                <Input
                    label='Email Address' 
                    type='text' 
                    placeholder='' 
                    name="emailAddress"
                    error={errors.emailAddress}
                    ref={register({
                    required: 'Please enter your email address.',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address"
                    }})}
                    onChange={(value) => {}}
                    required
                    />

                <Button type='submit'>Request Password Reset</Button> 

                <p>{message}</p>
                
                <Error>{error?.message}</Error>
            </form>
            </LoginForm>
        </Column>
    );
}