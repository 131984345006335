// @flow
import { constants } from '../actions/adminAccounts';
import { Error } from '../types/error';

export type State = {
  newAccountCreated: Boolean,
  isLoading: boolean,
  error: ?Error,
};

const initialState: State = { 
  newAccountCreated: false,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.postAdminCreateAccount:
      return { ...state, newAccountCreated: false, isLoading: true };

    case constants.postAdminCreateAccountSuccess: {
      return {
        ...state,
          error: null,
          isLoading: false,
          newAccountCreated: true,
      };
    }
    case constants.postAdminCreateAccountFailure:
      return { ...state, error: action.error, isLoading: false, newAccountCreated: false };

    default:
      return state;
    }
}
