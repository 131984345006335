// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as viewOrdersActions } from '../../state/actions/adminOrders';

export function useViewOrders(props) {
  const dispatch = useDispatch();

  const ordersStore = useSelector(state => state.adminOrders);
  const [orders, setOrders ] = useState(ordersStore);
  const [viewOrderPaneVisible, setViewOrderPaneVisible] = useState(false);
  const [order, setOrder] = useState(null);

  let reloadOrders = false;

  useEffect(() => {
    dispatch(viewOrdersActions.loadAdminOrdersSearch());
  },[]);

  useEffect(() => {
    setOrders(ordersStore);
  },[ordersStore]);

  useEffect(() => {

  },[order]);

  useEffect(() => {
    if(reloadOrders === true)
    {
      dispatch(viewOrdersActions.loadAdminOrdersSearch());
      reloadOrders = false;
    }
  },[dispatch, orders, reloadOrders]);

  const GetOrderToEdit = orderId => {
      setOrder(orders.orders.filter(x => x.orderId === orderId)[0]);
      setViewOrderPaneVisible(true);
  };

  const UpdateViewOrderPaneVisible = () => {
    setViewOrderPaneVisible(!viewOrderPaneVisible);
  };

  const UpdateOrder = order => {
    const index = orders.orders.findIndex(x => x.orderId === order.orderId);

    const updatedCompleteOrders = orders.orders;

    updatedCompleteOrders[index] = order;

    const updatedOrders = { ...orders, orders: updatedCompleteOrders };

    dispatch(viewOrdersActions.postAdminUpdateOrder(order));

    GetOrderToEdit(order.orderId);
    reloadOrders = true;
    setOrders(updatedOrders);
  };

  return {
    error: ordersStore.error,
    orders: ordersStore && ordersStore.orders,
    isLoading: ordersStore.isLoading,
    GetOrderToEdit,
    viewOrderPaneVisible,
    UpdateViewOrderPaneVisible,
    UpdateOrder,
    order
  };
}
