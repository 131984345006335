// @flow
import { constants } from '../actions/adminProducts';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  searchResults: object[],
  productDetails: object,
  filters: object,
};

const initialState: State = { 
  searchResults: null,
  filters: null,
  productDetails: null,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadAdminProductSearch:
      return { ...state,  ...state.adminProducts, isLoading: true };

    case constants.loadAdminProductSearchSuccess: {
      return {
        ...state,
          searchResults: action.payload,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadAdminProductSearchFailure:
      return { ...state, error: action.error, adminProducts: null, isLoading: false };
    default:
      return state;
    }
}
