import { lensPath, view } from 'ramda';
import { useEffect } from 'react';

export function useEditStyledPagesForm(styledPage, isSaving, setValue, setError, register) {

  const blogLens = (...propPath) => view(lensPath(propPath), styledPage);

  useEffect(() => {
    register({name: 'text'}, {
      required: "Enter some text",
    });
}, []);

useEffect(() => {
  if(styledPage && styledPage.text)
  {
    setValue('text', styledPage.botextdy);
  }
}, [styledPage]);

  useEffect(() => {

      if(isSaving === false)
      {
        let body = blogLens('text');

        if(body === '<p><br></p>' || body === '' || body === undefined)
        {
            body = '';

          setError("text", {
            message: "Enter some text"
          });
        }
        else{
          setValue('text', body);
        }
      }
  }, [styledPage, isSaving]);
}
