// @flow
import React from 'react';
import styled from 'styled-components';
import { useTestBlog } from './useTestBlog';
import LoadingAnimation from '../common/LoadingAnimation';

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    position:relative;
    width: 100%;
`;

const BlogBody = styled.div`
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

export default function({ props }) { 
    const blogId = props.match.params.blogId;

    const { blog, isLoading, error } = useTestBlog(blogId);

    let testBlog = null;
    if(blog && blog.id)
    {
        testBlog = blog;
    }

    return isLoading === true ? <Container><Loading><LoadingAnimation /></Loading></Container> :
            testBlog !== null ? <Container><BlogBody dangerouslySetInnerHTML={{__html: testBlog.body}} /></Container>:  
            <Container><Error>{error && error.message}</Error></Container>
    ;
}