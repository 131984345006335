// @flow
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
`;

function getImages(image: object, idx: Number, baseImagesLocation: string) {
    try {
        return(
        {
            original: `${baseImagesLocation}${image.path}`,
            thumbnail: `${baseImagesLocation}${image.path}`,
        });
    } catch (error) {
        return(
        {
            original: '',
            thumbnail: '',
        });
    }
}

const Carousel = (props) => {

    const images = props.images && props.images.map((image, idx) => getImages(image, idx, props.baseImagesLocation));
    
    return (
    <Container>
      <ImageGallery 
        items={images} 
        showThumbnails={props.showThumbnails} 
        showNav={props.showNav} 
        showFullscreenButton={false} 
        useBrowserFullscreen={false} 
        showPlayButton={false} 
        lazyLoad={true}c
        showBullets={props.showBullets}
        autoPlay={props.autoPlay}
        slideInterval={5000}
        />
    </Container>
  )
}

export default Carousel