import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ROUTES from '../../constants/routes';

import { useAuth } from '../../components/auth/useAuth';

import Menu from '../Menu';

import logo from '../../logo.png';
import basket from '../../assets/icons/basket.svg';
import userIcon from '../../assets/icons/User.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import loginIcon from '../../assets/icons/login.svg';

import { useHeader } from './useHeadder';

const Header = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;

    ${props => props.logoVisible ? 'align-items: center;' : 'align-items: start;' }
`;

const Links = styled.div` 
    display: flex;
    flex-direction: row;
    vertical-alige:center;
`;

const AccountLinks = styled.div` 
    display: flex;
    align-items: end;
    flex-direction: column;
    right: 10px;
    margin-top: 15px;
    position: absolute;
    z-index: 2;  
}`;

const AccountLinkItem = styled(Link)`
    display: flex;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
    align-items: center;

    &:active {
        border: none;
        text-decoration: none;
    }
`;

const SiteTitle = styled.div`
    display: flex;
    text-decoration: none;
    color: #2b2b2b;
    text-align: center;
    justify-content: center;
    align-items:center;
    height: 100%;

    ${props => props.show ? '' : 'display:none;'}
    ${props => props.row ? 'padding-left: 50px;' : '' }
    ${props => props.row ? 'flex-direction: row;' : 'flex-direction: column;'}
`;

const Title = styled.div`
    display: flex;
    white-space: nowrap;

    ${props => props.logoVisible ? 'font-size: 3em;' : 'font-size: 2em;' }

    @media (min-width: 768px) {
        ${props => props.logoVisible ? 'font-size: 5em;' : 'font-size: 2em;' }
    }

    @media (min-width: 1024px) {
        ${props => props.logoVisible ? 'font-size: 7em;' : 'font-size: 2em;' }
    }
`;

const SubTitle = styled.div`
    display: flex;
    margin-top: -4%;
    font-size: 1.5em;
    white-space: nowrap;
    justify-content: center;

    ${props => props.logoVisible ? 'font-size: 3em;' : 'font-size: 1em;' }

    @media (min-width: 768px) {
        ${props => props.logoVisible ? 'font-size: 2em;' : 'font-size: 1em;' }
    }

    @media (min-width: 1024px) {
        ${props => props.logoVisible ? 'font-size: 3em;' : 'font-size: 1em;' }
    }
`;

const LinkItem = styled(Link)`
    font-family: 'seagull';
    text-decoration: none;
    color: #a29062;
    border: none;
    outline: 0;
    
    &:active {
        border: none;
        text-decoration: none;
    }
`;

const HeaderTitle = styled.div` 
    display: flex;
    font-weight: bold;
    flex-direction: column; 
    width: 90%;
    text-align: center;
    justify-content: space-between;
`;

const Img = styled.img` 
    border: none;
    outline: 0;
    src: url(${props => props.src});
    height: 30px;
    margin-left: 10px;
    padding-top: ${ props => props.paddingTop };

    @media (max-width: 799px) {
        height: 20px;
    }
`;

const ImgLoginLogout = styled.img`
    border: none;
    outline: 0;
    src: url(${props => props.src});
    height: 1.2em;
    margin-left: 3px;
    padding-top: ${ props => props.paddingTop };

    @media (max-width: 799px) {
        height: 0.9em;
        padding-top:4px;
    }
`;

const ImgBasket = styled.img`
    border: none;
    outline: 0;
    src: url(${props => props.src});
    height: 1.4em;
    margin-left: 10px;
    padding-top: ${ props => props.paddingTop };

    @media (max-width: 799px) {
        height: 1em;
        padding-top:4px;
    }
`;

const HeaderBanner = styled.div`
    display: flex;
    text-align: center;
    width: 100%;
    font-size: 1.1em;
    z-index:200;
    color:#ffffff;
    position: fixed;

    ${props => props.logoVisible ? 'justify-content: center;' : 'justify-content: start;' }
    ${props => props.logoVisible ? '' : 'border-bottom: solid 1px #000;'}
    ${props => props.logoVisible ? '' : 'background-color: #fff;'}
    height: 60px;
`;

const HorizontalRule = styled.div`
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0 10px 0;

    @media (max-width: 799px) {
        border: none;
        outline: 0;
        width: 0;
        margin: 0;
    }
`;

const LogoImage = styled.img`
    border: none;
    outline: 0;
    padding-top:0;
    padding-right:10px;
    src: url(${props => props.src});
    height: 60px;

    @media (min-width: 768px) {
        padding-top:10px;
    }

    @media (min-width: 1024px) {
        padding-top:10px;
    }

    ${props => props.logoVisible ? 'height: 100px;' : 'height: 40px;' }

    @media (min-width: 768px) {
        ${props => props.logoVisible ? 'height: 100px;' : 'height: 40px;' }
    }

    @media (min-width: 1024px) {
        ${props => props.logoVisible ? 'height: 120px;;' : 'height: 40px;' }
    }
`;

export default function() {

const {
    loginDetails,
    logout,
    isLoggedIn
} = useAuth();

const ref = useRef();

const { 
    logoVisible
} = useHeader(ref);

return(
<Header logoVisible={logoVisible}>
    <HeaderBanner logoVisible={logoVisible}>
        <SiteTitle show={!logoVisible} row={true}>
            <LogoImage src={logo} logoVisible={logoVisible} />
            <LinkItem to={ROUTES.INDEX} show={true}>
                <Title ref={ref} logoVisible={logoVisible}>
                    GALERIE MIRAGES
                </Title>
                <SubTitle logoVisible={logoVisible}>Est 1987</SubTitle>
            </LinkItem>
        </SiteTitle>

        {
            isLoggedIn && loginDetails && loginDetails.firstName ? 
            <AccountLinks>
                <Links>
                    <AccountLinkItem to={ROUTES.BASKET}><ImgBasket src={basket} alt={'Basket'} paddingTop={'0px'} /></AccountLinkItem>
                    <AccountLinkItem to={ROUTES.MYACCOUNT}><Img src={userIcon} alt={'Account'}  /></AccountLinkItem>
                    <AccountLinkItem onClick={logout} to={ROUTES.INDEX}><ImgLoginLogout src={logoutIcon} alt={'Logout'} paddingTop={'0px'} /></AccountLinkItem>
                </Links>          
            </AccountLinks>
            :
            <AccountLinks>
                <Links>
                    <AccountLinkItem to={ROUTES.LOGIN}>Login<ImgLoginLogout src={loginIcon} alt={'Login'} paddingTop={'0px'} /></AccountLinkItem>
                    <AccountLinkItem to={ROUTES.BASKET}><ImgBasket src={basket} alt={'Basket'} paddingTop={'0px'} /></AccountLinkItem>
                </Links>
            </AccountLinks>
        }
    </HeaderBanner>
    <HeaderTitle>
        <SiteTitle show={true} row={false}>
            <LogoImage src={logo} logoVisible={logoVisible} />
            <LinkItem to={ROUTES.INDEX}>
                <Title ref={ref} logoVisible={logoVisible}>
                    GALERIE MIRAGES
                </Title>
                <SubTitle logoVisible={logoVisible}>Est 1987</SubTitle>
            </LinkItem>
            
        </SiteTitle>
    </HeaderTitle>                                    
    <Menu />
</Header>
);
}