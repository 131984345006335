// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as contactUsActions } from '../../state/actions/basket';
import { actions as accountActions } from '../../state/actions/auth';
import { useAuth } from '../auth/useAuth';

export function useCheckoutUserSelection(paymentDetails, updatePaymentDetails, existingAccountGetValues, newAccountGetValues) {
  const dispatch = useDispatch();

  const deliveryAndBillingDetailsStore = useSelector(state => state.deliveryDetails);

  const {
    error,
    loginSubmit,
    loginDetails,
  } = useAuth();

  const existingAccountSubmit = form => {
      loginSubmit(form);
  };

  const newAccountSubmit = form => {
      dispatch(accountActions.postCreateCustomerAccount(form));
  };

  useEffect(() => {

    if(loginDetails && loginDetails.username && paymentDetails.userDetailsSet === false)
    {
        updatePaymentDetails({
            ...paymentDetails,
            userDetails: { 
                ...paymentDetails.userDetails, 
                emailAddress: loginDetails.username,
                firstName: loginDetails.firstName,
                lastName: loginDetails.lastName 
            },
            userDetailsSet: true,
        });

        dispatch(contactUsActions.loadDeliveryAndBillingDetails());
    }
  }, [loginDetails, paymentDetails, dispatch]);

  useEffect(() => {
    if(loginDetails && loginDetails.username && paymentDetails.billingDeliverySet === false && paymentDetails.userDetailsSet === true)
    {
        if( deliveryAndBillingDetailsStore && deliveryAndBillingDetailsStore.deliveryBillingDetails && deliveryAndBillingDetailsStore.deliveryBillingDetails.deliveryAddress)
        {
            const deliveryDetails = deliveryAndBillingDetailsStore?.deliveryBillingDetails?.deliveryAddress;
            const billingDetails = deliveryAndBillingDetailsStore?.deliveryBillingDetails?.billingAddress;
            
            updatePaymentDetails({
                ...paymentDetails,
                deliveryAddress: { 
                    ...paymentDetails.deliveryAddress, 
                    address: deliveryDetails?.address,
                    address1: deliveryDetails?.address1,
                    address2: deliveryDetails?.address2,
                    county: deliveryDetails?.county,
                    city: deliveryDetails?.city,
                    postcode: deliveryDetails?.postCode,
                    country: deliveryDetails?.country,
                },
                billingAddress: { 
                    ...paymentDetails.billingAddress, 
                    address: billingDetails?.address,
                    address1: billingDetails?.address1,
                    address2: billingDetails?.address2,
                    county: billingDetails?.county,
                    city: billingDetails?.city,
                    postcode: billingDetails?.postCode,
                    country: billingDetails?.country,
                },
                sameBillingAddress: false,
                billingDeliverySet: true,
            });
        }
    }    
  }, [deliveryAndBillingDetailsStore]);

  return {
    existingAccountSubmit,
    newAccountSubmit,
    loginErrors: error,
  };
}
