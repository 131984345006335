import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/adminProducts';
import { get, post, postFiles } from '../../helpers/http';
import { productsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadAdminProductSearch({ payload }) {
  try {
    
    const url = `${productsUrl}/edit-product-search`;

    const config = {
      params: {
        page: payload.page,
        minPrice: payload.minPrice,
        maxPrice: payload.maxPrice,
        searchText: payload.searchText,
        brandName: payload.brand,
        categoryName: payload.category,
        subCategoryName: payload.subCategory,
        subSubCategoryName: payload.subSubCategory,
        pageSize: payload.pageSize,
        location: payload.location,
        sortBy: payload.sortBy,
        sortByOptions: payload.sortByOptions,
        filterableSearchString: payload.filterableSearchString,
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadAdminProductSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.loadAdminProductSearchFailure(GetErrorResponseMessage(error, "AdminProductSearch", REQUEST_METHODS.GET)));
  }
}

function* loadAdminProductDetails({ payload }) {
  try {
    
    let id = payload === undefined || payload === null ? '' : payload;

    const url = `${productsUrl}/edit-product-details/${id}`;

    const config = {};

    const response = yield call(get, { url, config });

    yield put(actions.loadAdminProductDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadAdminProductDetailsFailure(GetErrorResponseMessage(error, "AdminProductSearch-Details", REQUEST_METHODS.GET)));
  }
}

function* saveProductDetails({ payload }) {
  try {
    
    const url = `${productsUrl}`;

    const response = yield call(post, { url,  data: payload });

    yield put(actions.saveProductDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.saveProductDetailsFailure(GetErrorResponseMessage(error, "AdminProductSearch-Details", REQUEST_METHODS.POST)));
  }
}

function* imageUpload({ payload }) {
  try {
    const url = `${productsUrl}/images`;

    const response = yield call(postFiles, { url,  data: payload });

    yield put(actions.imageUploadSuccess(response.data));

  } catch (error) {
    yield put(actions.imageUploadFailure(GetErrorResponseMessage(error, "AdminProductSearch-Details", REQUEST_METHODS.UPLOAD)));
  }
}
export default function* productSaga() {
  yield takeLatest(constants.loadAdminProductSearch, loadAdminProductSearch);
  yield takeLatest(constants.loadAdminProductDetails, loadAdminProductDetails);
  yield takeLatest(constants.saveProductDetails, saveProductDetails);
  yield takeLatest(constants.imageUpload, imageUpload);
}
