// @flow
import React from 'react';
import styled from 'styled-components';
import { imagesLocation } from '../../settings';
import { default as NumberFormat } from 'react-number-format';
import ROUTES from '../../constants/routes';
import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js/pure';
// import { paymentKey } from '../../settings';
import CheckoutForm from './CheckoutForm';

const Img = styled.img`
    src: url(${props => props.src});
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 5px;
`;

const RowCentre = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const AddressRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PaymentColumn = styled.div`
    display: flex;
    flex-direction: column;
    min-width: auto;

    @media (min-width: 1000px) {
        min-width: 300px;
    }
`;

const InternationalDelivery = styled.div`
    color: red;
    font-size: 0.8em;
`;

const Terms = styled.div`
    font-size: 0.8em;
`;

const TermsLink = styled.div`
    display: inline;
    color: #3d3d3d;
    font-weight: bold;
`;

const Table = styled.table`
    width: 100%;
  &&& {
    table {
        border-bottom: solid 1px #a29062;
    },
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      border-bottom: solid 1px #a29062;
    }
    th {
      text-align: left;
    }
  }
`;

function getImages(image: object) {
    try {
        return(<><Img src={`${imagesLocation}products/${image.path}`} /><br /></>);
    } catch (error) {
        return(null);
    }
}

const BasketItem = (item, id) => 
    {
        return(
            <tr key={id}>
                <td>
                    {getImages(item.image)}
                    {item.name}
                </td>
                <td>
                    <RowCentre>
                        {item.quantity}
                    </RowCentre>
                </td>
                <td>
                    <RowCentre>
                        <NumberFormat value={ item.quantity * item.price } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                    </RowCentre>
                </td>
            </tr>
        );
    };

export default function CheckoutOrderSummary(props) { 

    const {basket, paymentDetails, order, GetTotalPriceAndDelivery, stripePromise} = props.props;

    let totalPriceAndDelivery = null;

    if(paymentDetails.billingDeliverySet === true && paymentDetails.billingDeliveryConfirmed === true && paymentDetails.userDetailsSet === true)
    {
        totalPriceAndDelivery = GetTotalPriceAndDelivery();
    }

    //let stripePromise = null;

    // if(paymentDetails.billingDeliverySet === true && 
    //     paymentDetails.billingDeliveryConfirmed === true && 
    //     paymentDetails.userDetailsSet === true && 
    //     order.intentSecret !== null &&
    //     order.isCreatingOrder === false &&
    //     order.orderCreated === false)
    // {
    //     loadStripe.setLoadParameters({advancedFraudSignals: false});
    //     stripePromise = loadStripe(paymentKey);
    // }
    
    return paymentDetails.billingDeliverySet === true && 
    paymentDetails.billingDeliveryConfirmed === true && 
    paymentDetails.userDetailsSet === true ? (
        <>
            <Column>
                <AddressRow>
                    <Column>
                        <strong>Order Summary and Payment</strong>
                    </Column>
                </AddressRow>
                <AddressRow>
                    <Column>
                        <strong>Delivery To</strong><br />
                        {paymentDetails.userDetails && paymentDetails.userDetails.firstName} {paymentDetails.userDetails && paymentDetails.userDetails.lastName}
                    </Column>
                </AddressRow>
                <AddressRow>
                    <Column>
                        <strong>Delivery Address</strong><br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address}<br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address1}<br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.address2}<br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.postcode}<br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.county}<br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.city} <br />
                        {paymentDetails.deliveryAddress && paymentDetails.deliveryAddress.country}<br />
                    </Column>

                    <Column>
                        <strong>Billing Address</strong><br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.address}<br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.address1}<br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.address2}<br />                
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.postcode}<br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.county}<br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.city}<br />
                        {paymentDetails.billingAddress && paymentDetails.billingAddress.country}<br />                           
                    </Column>
                </AddressRow>

                <Row>
                    <Table>
                        <thead>
                            <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { basket.items.map( (item, xid) => BasketItem(item, xid)) }
                            <tr key={'deliveryCost'}>
                                <td>{totalPriceAndDelivery.delivery.description}
                                    {paymentDetails.deliveryAddress.country !== 'UK' && totalPriceAndDelivery.delivery.description === 'International Delivery' ? <InternationalDelivery>We will contact you with a delivery quote, if you are not happy with the quote we will refund your order in full.</InternationalDelivery> : null }
                                </td>
                                <td>
                                    <RowCentre>1</RowCentre>
                                </td>
                                <td>
                                    <RowCentre>
                                        <NumberFormat value={totalPriceAndDelivery.delivery.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                                    </RowCentre>
                                </td>
                            </tr>
                            <tr key={'total'}>
                                <td></td>
                                <td><RowCentre>Total</RowCentre></td>
                                <td>
                                    <RowCentre>
                                        <NumberFormat value={totalPriceAndDelivery.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                                    </RowCentre>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Column> 
            <PaymentColumn>
                <Elements stripe={stripePromise}>
                    <CheckoutForm props={{clientSecret: order.intentSecret, paymentDetails: paymentDetails, basketItems: basket.items}} />
                </Elements>
                <Terms>By clicking <strong>Pay</strong> you agree to the terms and condition set out in our <a href={ROUTES.TERMS} style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer"><TermsLink>Terms and Conditions</TermsLink></a> policy.</Terms>
            </PaymentColumn>
        </>
    ) : <></>; 
}