import React from "react";
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import BannerCarousel from '../../components/common/ImageGallery';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Row = styled.div`
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color:#5e5e5e;
    background-color: #fafafa;
    width: 100%;
`;

const BlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    color:#5e5e5e;
    background-color: #fafafa;
    align-items: center ;
    border: solid 1px #5e5e5e;
    position:relative;
    z-index: 2;
    margin: 5px;
`;

const Img = styled.img`
    src: url(${props => props.src});
    overflow: hidden;
    padding: 5px;
    width: 200px;

    @media (max-width: 799px) {
        width:100px;
    }
`;

const BannerCarouselContainer = styled.div`
    z-index: 0;
    width: 100%;
`;

const DeleteIcon = styled.div`
    position: absolute;
    top:0px;
    right:3px;
    padding: 5px;
    z-index:3;

    &:hover {
      cursor: pointer;
      color: #b51616;
    };
`;

const MultiImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    z-index:1;
`;

const PictureBlockImage = styled.img`
    src: url(${props => props.src});
    overflow: hidden;
    width: 100%;
`;

const ImageContainer = styled.div`
  z-index:1;
`;

const ImageContainerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height:100%;
  z-index:2;
`;

const StyledElementContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const textBlockItem = (itemContent, moveItems, removeItem, pageId, baseImagesLocation) => {
const imagePath = itemContent && itemContent.image && itemContent.image.path ? `${baseImagesLocation}${itemContent.image.path}` : null;
{
  return itemContent && itemContent.image && itemContent.image.path ? 
      itemContent.imagePositionLeft ?
      <BlockContainer>
        <ImageContainerOverlay/>
        <ImageContainer>
          <Img src={imagePath} alt={itemContent.title}/>
        </ImageContainer>
        <TextContainer>
          <strong>{itemContent.title}</strong>
          <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
        </TextContainer>
        <DeleteIcon onClick={() => removeItem({pageId: pageId, id: itemContent.id, blockType: itemContent.blockType})}><FontAwesomeIcon icon={faTrashAlt} size="1x" /></DeleteIcon>
      </BlockContainer> : 
      <BlockContainer>
        <ImageContainerOverlay/>
        <TextContainer>
          <strong>{itemContent.title}</strong>
          <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
        </TextContainer>
        <ImageContainer>
          <Img src={imagePath} alt={itemContent.title}/>
        </ImageContainer>
        <DeleteIcon onClick={() => removeItem({pageId: pageId, id: itemContent.id, blockType: itemContent.blockType})}><FontAwesomeIcon icon={faTrashAlt} size="1x" /></DeleteIcon>
      </BlockContainer>
    :
    <BlockContainer>
      <ImageContainerOverlay/>
      <TextContainer>
        <strong>{itemContent.title}</strong>
        <StyledElementContent dangerouslySetInnerHTML={{__html: itemContent.text}}></StyledElementContent>
      </TextContainer>
      <DeleteIcon onClick={() => removeItem({pageId: pageId, id: itemContent.id, blockType: itemContent.blockType})}><FontAwesomeIcon icon={faTrashAlt} size="1x" /></DeleteIcon>
    </BlockContainer>
  }
};

const pictureBlockItem = (itemContent, moveItems, removeItem, pageId, baseImagesLocation) => {
{
  const images = itemContent.images.map((image) => { return image && image.path ? {path: `${image.path}`} : null });
  return itemContent.isCarosel === true ?
  <BlockContainer>
    <ImageContainerOverlay/>
      <BannerCarouselContainer>
        <BannerCarousel images={images} 
          showThumbnails={false} 
          showNav={false} 
          showBullets={true} 
          autoPlay={true} 
          baseImagesLocation={baseImagesLocation} 
        />
      </BannerCarouselContainer>
      <DeleteIcon onClick={() => removeItem({pageId: pageId, id: itemContent.id, blockType: itemContent.blockType})}><FontAwesomeIcon icon={faTrashAlt} size="1x" /></DeleteIcon>
    </BlockContainer> :
    <BlockContainer>
      <ImageContainerOverlay/>
      <MultiImageContainer>
        {
          itemContent.images !== null && itemContent.images.length > 0 && itemContent.images.map((image, idx) => 
          {
            const imagePath = image && image.path ? `${baseImagesLocation}${image.path}` : null;
            return <PictureBlockImage key={imagePath + idx} src={imagePath} alt={'image'}/>;
          })
        }
      </MultiImageContainer>
      <DeleteIcon onClick={() => removeItem({pageId: pageId, id: itemContent.id, blockType: itemContent.blockType})}><FontAwesomeIcon icon={faTrashAlt} size="1x" /></DeleteIcon>
    </BlockContainer>;
  }
};

const ItemList = React.memo(function ItemList({ itemContents, moveItems, removeItem, pageId, baseImagesLocation }) {
  return(
    <>
    {itemContents !== null && itemContents !== undefined && itemContents && itemContents.map((itemContent, idx) => {
      return itemContent && itemContent.orderPostion >= 0 ?(          
          <Draggable 
            draggableId={itemContent.id}
            itemContent={itemContent}
            key={itemContent.id}
            index={idx}
            pageId={pageId}
            moveItems={moveItems}
            removeItem={removeItem} 
          >
            {
              provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {itemContent.blockType === 0 ? 
                    textBlockItem(itemContent, moveItems, removeItem, pageId, baseImagesLocation) : 
                    pictureBlockItem(itemContent, moveItems, removeItem, pageId, baseImagesLocation)}
                </div>
              )
            }
          </Draggable>
      ) : null;
  })}
</>);
});

const DragableList = ({ itemContents, moveItems, removeItem, pageId, baseImagesLocation }) => {
  
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    moveItems(result.source.index, result.destination.index);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <Row ref={provided.innerRef} {...provided.droppableProps}>
            <ItemList itemContents={itemContents}
              moveItems={moveItems}
              removeItem={removeItem}
              pageId={pageId} 
              baseImagesLocation={baseImagesLocation}/>
              {provided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragableList;
