// @flow
import React from 'react';
import { useState } from 'react';
import type { Node } from 'react';
import ReactGA from 'react-ga';
import { analyticsId } from '../settings';
import styled from 'styled-components';

const Wrapper = styled.div``;

export default function App({ children }: { children: Node }){

    const [location, setLocation ] = useState('');

    function onClickHandler(e) {  
        if(location !== e.target.baseURI)
        {
            setLocation(e.target.baseURI);

            if(!e.target.baseURI.includes('localhost'))
            {
                ReactGA.initialize(analyticsId);
                ReactGA.pageview(e.target.baseURI);
            }
        }
        e.stopPropagation();
    }

    return <Wrapper onClick={onClickHandler}>{children}</Wrapper>;
}