// @flow
import React from 'react';
import styled from 'styled-components';
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const FormInput = styled.select`
  padding:5px;
  font-size: 1.0em;
  ${props => props.error ? `border-color ${ERROR_COLOUR};` : `border-color ${INPUT_BORDER_COLOR};`}
  ${props => props.disabled ? `background-color: ${INPUT_DISABLED_COLOUR};` : `background-color: #fff;`}
  ${props => props.disabled ? `cursor: not-allowed;` : `cursor: inherit;`}
  color: ${INPUT_TEXT_COLOUR};

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
`;

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -4px;
`;

function mapOptins(option: object, idx: Number, name: string) {
    return(<option value={option} key={`${name}-${idx}`}>{option}</option>);
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  label: string,
  required: boolean,
  keyValue: string,
  value: string,
  onChange:  (input: { keyValue: string, value: any }) => void,
  onKeyPress: (event: Object) => void,
  options: Object,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  multiple: Boolean,
  showDefaultValue: Boolean,
};

const Input = React.forwardRef(({
  label,
  required,
  keyValue,
  value,
  options,
  onChange,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  reference,
  multiple,
  name,
  showDefaultValue,
}: Props, ref) => {
  return (
    <InputContainer>
        {label == null ? '' : <LabelContainer><Label>{label}{required ? <Required error={error && error.message}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>}
        <FormInput  
          name={name}
          error={error && error.message}
          ref={ref}
          onChange={({ target: { value: val, options: op, selectedIndex } }) => {
            const optionLabel = op[selectedIndex].innerText;
            onChange(keyValue, { value: val, label: optionLabel } );
          }}
          multiple={multiple}
          value={value === null || value === undefined || value === '' ? null : value}
          disabled={disabled}
        >
            {showDefaultValue === undefined || showDefaultValue === false ? null : <option value={''} key={`default`}>Select...</option>}
            {options.map((option, idx, name) => mapOptins(option, idx, name))}
        </FormInput>
    </InputContainer>
  )
});

export default Input