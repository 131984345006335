// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';

import { imagesLocation } from '../../settings';
import { ScrollToTop } from '../../helpers/scrollToTop';
import InputText from '../common/Input';
import DropdownObjectList from '../common/DropdownObjectList';
import ImageUploader from '../common/ImageUploader';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;


const ColumnNoMargin = styled.div`
    padding:10px;
    width: 50%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    padding:10px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Error = styled.div`
    color: #a82700;
`;

const Complete = styled.div`
    color: green;
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    padding-left: 10px;
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 5px;
    ${props => props.disabled ? 'background-color:#9e9e9e;' : `background-color:${props.color};`}
    ${props => props.disabled ? 'disabled:"disabled";' : null}
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const NewCategoryInputContaner = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 100%;
    display:none;
    top:0;
    left:0;
    z-index: 1000;
    padding-top: 10px;
    color: white;
    background-color: rgba(61, 61, 61, 0.8);
    overflow-y:scroll;
`;

const NewCategoryInputControls = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: auto;
    background-color: #fafafa;
    padding: 10px;
    color: #5e5e5e;
    border: solid rgba(168,39,0,0.9);

    @media (max-width: 799px) {
        width: 90%;
    }
`;

export default function(props) {

    const error = props.editCategories?.error;
    const isLoading = props.editCategories?.isLoading;
    const isUploading = props.editCategories?.isUploading;
    const lastUploadedImages = props.editCategories?.lastUploadedImages;
    const saveCompleteMessage = props.editCategories?.saveCompleteMessage;
    const errorMessage = props.editCategories.error && props.editCategories?.error?.message;
    const updateImages = props.editCategories?.updateImages;
    const updateLastUploadedImages = props.editCategories?.updateLastUploadedImages;
    const updateCategory = props.editCategories?.updateCategory;
    const onNewCategorySubmit = props.editCategories?.onSubmit;
    const uploadImages = props.editCategories?.uploadImages;
    const isSaving = props.editCategories?.isSaving;
    const categories = props.editCategories.categories?.categories;

    const { handleSubmit: categoryHandleSubmit, register: categoryInputRegister, triggerValidation: categoryInputValidate, errors: categoryInputErrors, setValue: categoryInputSetValue, getValues: categoryInputGetValues} = useForm();
    const { handleSubmit: editCategoryHandleSubmit, register: editCategoryRegister, errors: editCategoryErrors, setValue: editCategorySetValue} = useForm();

    const resetForms = () =>
    { 
        categoryInputSetValue('categoryd', undefined);
        categoryInputSetValue('name', undefined);
        categoryInputSetValue('images', undefined);
        categoryInputSetValue('type', '');
        updateLastUploadedImages(null);
    }

    function submitImageUpload(form, images, files) {

        const itemForm = {
            id: categoryInputGetValues("categoryd"), 
            name: categoryInputGetValues("name"), 
            type: categoryInputGetValues("type"),
        };

        uploadImages(form, itemForm, images, files);
    }

    const addUpdateCategorySubmit = form =>
    { 
        onNewCategorySubmit(form);
        resetForms();
    }

    const onSubmit = form => {

    };

    const typeOptions = [
        {label: 'Brand', value: 'Brand'},
        {label: 'Category', value: 'Category'},
        {label: 'Sub Category', value: 'SubCategory'},
        {label: 'Sub Sub Category', value: 'SubSubCategory'}
    ];

    const listTextValue = lastUploadedImages && lastUploadedImages.type ? lastUploadedImages.type : '';

    let categoryTypeSelectedOption = typeOptions.filter(x => x.value == listTextValue);

    if(categoryTypeSelectedOption.length > 0) {
        categoryTypeSelectedOption = categoryTypeSelectedOption[0].value;
    }
    else {
        categoryTypeSelectedOption = '';
    }

    const categoryBrands = (categories, type) => {

        const listTextValue = lastUploadedImages && lastUploadedImages.name && lastUploadedImages.type == type ? lastUploadedImages.name : '';

        const options = categories.map((category) => { return { label: category.name, value: category } });

        let listValue = options.filter(x => x.label == listTextValue);
        if(listValue.length > 0) {
            listValue = listValue[0].value;
        }
        else {
            listValue = '';
        }
        
        return <Column>{
            <DropdownObjectList 
                name={type}
                keyValue={type}
                label={type}
                value={listValue}
                ref={editCategoryRegister()}
                options={options}
                onChange={(keyValue, value) => {
                    if(value.value !== 'Select...')
                    {
                        updateLastUploadedImages({
                            categoryId:  value.value.categoryId,
                            name: value.value.name,
                            type: type,
                            images: [{ path: value.value.imagePath, order: 0}]
                        });
                    }
                    else {
                        resetForms();
                    }
                }}
                error={editCategoryErrors} 
                showDefaultValue={true}
            />
        }</Column>
    };

    return(
        <Container>
            <ColumnNoMargin>
                <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
                <Column>
                <NewCategoryInputContaner id='newCategoryContainer'>
                    <NewCategoryInputControls>
                        <form onSubmit={categoryHandleSubmit(addUpdateCategorySubmit)}>
                            <Row>
                                New Category or brand
                            </Row>
                            <Buttons>
                                <Button color={'#363636'} disabled={isSaving} onClick={async(e) => {

                                    const result = await categoryInputValidate();

                                    document.getElementById("newCategoryContainer").style.display = result === true ? 'none' : 'block'; 

                                    }}>{isSaving ? 'Saving' : lastUploadedImages && lastUploadedImages.categoryId ? 'Delete' : 'Save'}
                                </Button>

                                <Button color={'#363636'} disabled={isSaving} onClick={(e) => {
                                    resetForms(); 
                                    document.getElementById("newCategoryContainer").style.display = 'none'; 
                                    e.preventDefault(); return false;}}>{'Cancel'}
                                </Button>
                            </Buttons>

                            <InputText 
                                name='name'
                                keyValue='name'
                                value={lastUploadedImages && lastUploadedImages.name ? lastUploadedImages.name : null}
                                onChange={(keyValue, value) => {
                                    updateCategory(keyValue, value);
                                }} 
                                label="Name" 
                                ref={categoryInputRegister({
                                    required: 'Enter a name.',
                                })}
                                error={categoryInputErrors.name}
                                required
                            />
    
                            <InputText 
                                name='categoryId'
                                keyValue='categoryId'
                                value={lastUploadedImages && lastUploadedImages.categoryId}
                                onChange={(keyValue, value) => {
                                    updateCategory(keyValue, value);

                                    categoryInputValidate(keyValue);
                                }} 
                                type="hidden" 
                                ref={categoryInputRegister('categoryId')}
                            />

                            <DropdownObjectList 
                                name='type'
                                keyValue='type'
                                label="Category type" 
                                value={categoryTypeSelectedOption}
                                ref={categoryInputRegister({
                                    required: 'Select a category type.',
                                })}

                                options={typeOptions}
                                onChange={(keyValue, value) => {
                                    if(value.value !== 'Select...')
                                    {
                                        updateCategory(keyValue, value.value);
                                    }

                                    categoryInputValidate(keyValue);
                                }}
                                error={categoryInputErrors.type} 
                                showDefaultValue={true}
                                required={true}
                                disabled={lastUploadedImages && lastUploadedImages.categoryId}
                            />
                            
                            <Error>{errorMessage}</Error>
                           
                            <InputText 
                                name='images'
                                keyValue='images'
                                value={lastUploadedImages && lastUploadedImages.images ? JSON.stringify(lastUploadedImages.images) : null}
                                onChange={(keyValue, value) => {
                                    updateCategory(keyValue, lastUploadedImages.images);
                                }} 
                                type="hidden" 
                                ref={categoryInputRegister({
                                    required: 'Select an image.',
                                })}
                            />
                            <Error>{categoryInputErrors.images && categoryInputErrors.images.message}</Error>
                            <Complete>{saveCompleteMessage}</Complete>
                            <Error>{isUploading === true ? 'UPLOIADING' : null}</Error>
                        </form>   

                        <ImageUploader 
                            name='imageUpload'
                            keyValue='imageUpload'
                            value={lastUploadedImages && lastUploadedImages.images && lastUploadedImages.images.map((image) => image.path)} 
                            onSubmit={submitImageUpload}
                            updateImages={updateImages}
                            label="Upload Images" 
                            baseImagesLocation={`${imagesLocation}category-images/`}
                            isUploading={isUploading}
                        />
                    </NewCategoryInputControls>
                  
                </NewCategoryInputContaner>
            </Column>

            </ColumnNoMargin>
            <Column>
                <Row>
                    <Buttons>
                        <Button color={'#696969'} disabled={isSaving} 
                            onClick={(e) => {
                                resetForms();
                                document.getElementById("newCategoryContainer").style.display = 'block'; 
                                e.preventDefault(); 
                                return false;}}>{'New Category or brand'}</Button>
                    </Buttons>

                    {
                        lastUploadedImages && lastUploadedImages.name 
                            ? <Buttons>
                                <Button color={'#696969'} disabled={isSaving} 
                                    onClick={(e) => {
                                        document.getElementById("newCategoryContainer").style.display = 'block'; 
                                        e.preventDefault(); 
                                        return false;}}>{`Edit ${lastUploadedImages.name}`}</Button>
                            </Buttons>
                            : null
                    }
                </Row>
                <Error>{errorMessage}</Error>

                <form onSubmit={editCategoryHandleSubmit(onSubmit)}>
                {
                    categories && categories.categoryBrands 
                        ? categoryBrands(categories.categoryBrands, 'Brand')
                        : null
                }

                {
                    categories && categories.categories 
                        ? categoryBrands(categories.categories, 'Category')
                        : null
                }

                {
                    categories && categories.subCategories 
                        ? categoryBrands(categories.subCategories, 'SubCategory')
                        : null
                }

                {
                    categories && categories.subCategories 
                        ? categoryBrands(categories.subSubCategories, 'SubSubCategory')
                        : null
                }
                </form>
            </Column>
        </Container>
    );
    
}