// @flow
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
export function useOpeningHours() {

    const menuStore = useSelector(state => state.menuDetails);

    useEffect(() => {
    }, [menuStore]);
  
    return {
        openingHours: menuStore.openingHours,
    };
  }
  