// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as adminCategoriesActions } from '../../state/actions/adminCategories';

export function useAdminCategories() {
  
  const dispatch = useDispatch();

  const categoriesStore = useSelector(state => state.adminCategories);
  const [editCategories, setEditCategories] = useState(null);

  const [lastUploadedImages, setLastUploadedImages] = useState(null);

  useEffect(() => {
    setLastUploadedImages(null);
    setEditCategories(null);
    dispatch(adminCategoriesActions.loadAdminCategories());
  }, []);

  useEffect(() => {
    setEditCategories(categoriesStore);
  },[categoriesStore.isLoading, categoriesStore.isUploading, categoriesStore.isSaving]);

  useEffect(() => {
  },[lastUploadedImages]);

  const updateCategory = (keyValue, newValue) => {

    const lensSet = (key, newValue, updateCategory) => {

        let updatedCategory = set(lensPath(key.split('.')), newValue, updateCategory);
        setLastUploadedImages(updatedCategory);
    };

    lensSet(keyValue, newValue, lastUploadedImages);
  };

  const onSubmit = form => {
    const category = {
      categoryId: lastUploadedImages.categoryId, 
      name:lastUploadedImages.name, 
      imagePath:lastUploadedImages.images[0].path, 
      categoryType: lastUploadedImages.type
    };
    dispatch(adminCategoriesActions.postAdminUpdateCategories(category));   
  };

  const uploadImages = (form, itemForm, images, files) => {
    const updateCategories = {
      categoryId: itemForm.categoryId, 
      name:itemForm.name, 
      images:images, 
      type: itemForm.type
    };
  
    setLastUploadedImages(updateCategories);

    dispatch(adminCategoriesActions.postCategoryImageUpload({images: form}));
  };

  function updateImages(images) {
    setLastUploadedImages({
      ...lastUploadedImages, 
        images: images
    });
  }

  function updateLastUploadedImages(newState) {
    setLastUploadedImages(newState);
  };

  return {
    error: categoriesStore.error,
    isLoading: categoriesStore.isLoading,
    isUploading: categoriesStore.isUploading,
    isSaving: categoriesStore.isSaving,
    updateLastUploadedImages,
    onSubmit,
    uploadImages,
    updateImages,
    updateCategory,
    categories: editCategories,
    lastUploadedImages,
    saveCompleteMessage: categoriesStore.saveCompleteMessage,
  };
}
