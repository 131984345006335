// @flow
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import Moment from 'moment';
import { default as NumberFormat } from 'react-number-format';
import ArrowUp from '../common/ArrowUp';
import ArrowDown from '../common/ArrowDown';
import PrintOrderNote from './PrintOrderNote';
import PrintShopOrderNote from './PrintShopOrderNote';
import { useReactToPrint } from 'react-to-print';
import { ScrollToTop } from '../../helpers/scrollToTop';

const ActionLink = styled(Link)`
    text-decoration: none;
    border: none;
    outline: 0;
    color: #3d3d3d;

    &a {
        text-decoration: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;

const CollapsibleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const ExpandableRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${props => props.visible ? 'height: auto;' : 'height: 20px;'}

    border: 1px solid #d9d9d9;
    padding: 10px; 
    height: 100%;
`;

const ExpandableRowExpanderIcon = styled.div`
    height: 4px;
    width: 4px;
    position: absolute;
    right: 10px;
    z-index: 2;
    cursor: pointer;
`;

const ArrowContainer = styled.div`
    position: absolute;
    right: 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const PaddedColumn = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 20px 10px 0;
`;

const Table = styled.table`
    width: 100%;
  &&& {
    table {
        border-bottom: solid 1px #a29062;
    },
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      border-bottom: solid 1px #a29062;
      border-collapse: collapse;
    }
    th {
      text-align: left;
    }
  }
`;

const Tr = styled.tr`
    ${props => props.selected === true ? 'background-color:#d9d9d9;' : ''}
`;

const Icon = styled.div`
    font-size: 1.5em;
    border: none;
`;

const Button = styled.button`
    padding: 5px;
    min-width: 70px;
    margin: 10px 10px 10px 10px;
    cursor: pointer;

    @media (min-width: 800px) {
        min-width: 100px;
    }
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const StockCode = styled.div`
    font-size: 0.8em;
    white-space: nowrap;
    font-style: italic;
`;

export default function(props) {

    const {
        orders,     
        GetOrderToEdit,
        viewOrderPaneVisible,
        UpdateViewOrderPaneVisible,
        UpdateOrder,
        order
    } = props.props;
    
    const customerOrderNoteRef = useRef();

    const shopOrderNoteRef = useRef();

    const handleDeliveryNotePrint = useReactToPrint({
        content: () => customerOrderNoteRef.current,
    });

      const handleShopOrderNotePrint = useReactToPrint({
        content: () => shopOrderNoteRef.current,
    });

    const GetOrders = (orderItem, idx, order) => {
        return(
            <Tr key={idx} selected={ order && orderItem.orderId === order.orderId ? true : false }>
                <td>
                    {Moment(orderItem.dateAdded).format('D-M-YYYY')}
                </td>
                <td>
                    {orderItem.user.fullName}
                </td>
                <td>
                    {orderItem.items.length - 1}
                </td>
                <td>
                    <NumberFormat value={ orderItem.totalOrderPrice } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                </td>
                <td>
                    <ActionLink to={`#`} onClick={() => {GetOrderToEdit(orderItem.orderId);  ScrollToTop();}} ><Icon>&#9998;</Icon></ActionLink>
                </td>
            </Tr>);
    };

    const OrderItems = (item, id) => 
    {
        return(
            <tr key={id}>
                <td>
                    {item.producNameAtTimeOfSale}<br />
                    {item.producStockCodeAtTimeOfSale !== '' ? <StockCode>({item.producStockCodeAtTimeOfSale})</StockCode> : null}
                </td>
                <td>{item.quantity}</td>
                <td><NumberFormat value={ item.quantity * item.unitPrice } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /></td>
            </tr>
        );
    };

return(
    <Content> 
        <Column> 
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </Column> 
        { order !== null ?

        (viewOrderPaneVisible ?
            <ExpandableRow visible={viewOrderPaneVisible}>
                <Column>
                    <Row>
                    <strong>Order Id&nbsp;-&nbsp;</strong> {order.orderId}
                    </Row>
                    <Row>
                        <strong>Delivery To&nbsp;-&nbsp;</strong> {order.user && order.user.firstName} {order.user && order.user.lastName}
                    </Row>
                    <Row>
                        <strong>Email&nbsp;-&nbsp;</strong> {order.user && order.user.email}
                    </Row>
                    <Row>
                        <strong>Phone&nbsp;-&nbsp;</strong> {order.user && order.user.phoneNumber}
                    </Row>
                    <ExpandableRowExpanderIcon onClick={() => UpdateViewOrderPaneVisible()}><ArrowContainer><ArrowUp open={viewOrderPaneVisible}/></ArrowContainer></ExpandableRowExpanderIcon>
                </Column>
                <Column>
                    <Row>
                        <PaddedColumn>
                            <strong>Delivery Address</strong><br />
                            {order.deliveryAddress && order.deliveryAddress.address}<br />
                            {order.deliveryAddress && order.deliveryAddress.address1}<br />
                            {order.deliveryAddress && order.deliveryAddress.address2}<br />
                            {order.deliveryAddress && order.deliveryAddress.city} <br />
                            {order.deliveryAddress && order.deliveryAddress.county}<br />
                            {order.deliveryAddress && order.deliveryAddress.postCode}<br />
                            {order.deliveryAddress && order.deliveryAddress.country}<br />
                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Billing Address</strong><br />
                            {order.billingAddress && order.billingAddress.address}<br />
                            {order.billingAddress && order.billingAddress.address1}<br />
                            {order.billingAddress && order.billingAddress.address2}<br /> 
                            {order.billingAddress && order.billingAddress.city}<br />     
                            {order.billingAddress && order.billingAddress.county}<br />          
                            {order.billingAddress && order.billingAddress.postCode}<br />
                            {order.billingAddress && order.billingAddress.country}<br />                           
                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Order Status</strong><br />
                            {/* 
                            AwaitingPayment = 0,
                            Processing      = 1,
                            Sent            = 2,
                            Returned        = 3,
                            Ready to Collect= 4, 
                            Collected       = 5, 
                            */}
                            {order.orderStatus === 0 ? 'Awaiting Payment' : null}
                            {order.orderStatus === 1 ? 'Processing' : null}
                            {order.orderStatus === 2 ? 'Sent' : null}
                            {order.orderStatus === 3 ? 'Returned' : null}
                            {order.orderStatus === 4 ? 'Ready to Collect' : null}
                            {order.orderStatus === 5 ? 'Collected' : null}

                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Payment Status</strong><br />
                            {/* 
                            AwaitingPayment    = 0,
                            Succeses           = 1,
                            Failure            = 2,
                            Refunded           = 3,
                            */}
                            {order.paymentStatus === 0 ? 'Awaiting Payment' : null}
                            {order.paymentStatus === 1 ? 'Success' : null}
                            {order.paymentStatus === 2 ? 'Failure' : null}
                            {order.paymentStatus === 3 ? 'Refunded' : null}
                                                  
                        </PaddedColumn>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                order.items.map( (item, xid) => OrderItems(item, xid)) }
                                
                                <tr key={'total'}>
                                    <td></td>
                                    <td>Total</td>
                                    <td>
                                        <NumberFormat value={order.totalOrderPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                                    </td>
                                </tr>
                                <tr key={'options'}>
                                    <td>
                                        <Button onClick={() => UpdateOrder({...order, orderStatus: 2})} 
                                            disabled={(order.paymentStatus === 3 || order.deliveryType === 1 || order.orderStatus === 0 || order.orderStatus === 2 || order.orderStatus === 3  || order.orderStatus === 4) ? true : false}>Sent</Button>
                                        
                                        <Button onClick={() => UpdateOrder({...order, paymentStatus: 3})} 
                                            disabled={(order.orderStatus === 0 || order.paymentStatus === 3 || order.paymentStatus === 3 || order.orderStatus === 5) ? true : false}>Refund</Button>
                                        
                                        <Button onClick={() => UpdateOrder({...order, orderStatus: 3})} 
                                            disabled={(order.orderStatus === 0 || order.orderStatus === 3 || order.orderStatus === 4 || order.orderStatus === 1 ) ? true : false}>Returned</Button>
                                        
                                        <Button onClick={() => UpdateOrder({...order, orderStatus: 4})} 
                                            disabled={(order.deliveryType === 0 || order.orderStatus === 4 || order.orderStatus === 3 || order.orderStatus === 5) ? true : false}>Ready to Collect</Button>
                                        
                                        <Button onClick={() => UpdateOrder({...order, orderStatus: 5})} 
                                            disabled={(order.paymentStatus === 3 || order.deliveryType === 0 || order.orderStatus === 5 || order.orderStatus === 3) ? true : false}>Collected</Button>
                                    </td>
                                    <td></td>
                                    <td>
                                        <Button onClick={handleDeliveryNotePrint}>Print Delivery Note</Button>
                                        <div style={{display: 'none'}}><PrintOrderNote  props={order} ref={customerOrderNoteRef} /></div>

                                        <Button onClick={handleShopOrderNotePrint}>Print Shop Copy</Button>
                                        <div style={{display: 'none'}}><PrintShopOrderNote  props={order} ref={shopOrderNoteRef} /></div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                
                </Column>
            </ExpandableRow> : 
            
            <ExpandableRow visible={viewOrderPaneVisible}>
                <Column>
                    <Row>
                        
                        <strong>Order Id&nbsp;-&nbsp;</strong> {order.orderId}&nbsp;&nbsp;&nbsp;
                        <strong>Delivery To&nbsp;-&nbsp;</strong> {order.user && order.user.firstName} {order.user && order.user.lastName}
                    </Row>
                    <ExpandableRowExpanderIcon onClick={() => UpdateViewOrderPaneVisible()}><ArrowContainer><ArrowDown open={viewOrderPaneVisible}/></ArrowContainer></ExpandableRowExpanderIcon>
                </Column>
            </ExpandableRow>)
        : null
        }
        <CollapsibleRow>
            <Column>
                <p><strong>New Orders</strong></p>
                <Table key={'orders'}>
                    <thead>
                        <tr>
                            <th>Date Sold</th>
                            <th>Customer Name</th>
                            <th>Number of items</th>
                            <th>Total Price</th>
                            <th>Process</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.filter(x => x.orderStatus === 1 || x.orderStatus === 4).map((orderItem, idx) => GetOrders(orderItem, idx, order))}
                    </tbody>
                </Table>
            </Column>

            <Column>
                <p><strong>Complete Orders</strong></p>
                <Table key={'orders'}>
                    <thead>
                        <tr>
                            <th>Date Sold</th>
                            <th>Customer Name</th>
                            <th>Number of items</th>
                            <th>Total Price</th>
                            <th>Process</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.filter(x => x.orderStatus !== 1 && x.orderStatus !== 4).map((orderItem, idx) => GetOrders(orderItem, idx, order))}
                    </tbody>
                </Table>
            </Column>
        </CollapsibleRow>
    </Content>
);
}