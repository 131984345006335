// @flow
import { constants } from '../actions/styledPages';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  saveComplete: boolean,
  saveCompleteMessage: String,
  error: ?Error,
  styledPage: object,
  isUploading: boolean,
};

const initialState: State = { 
    styledPage: null,
    isLoading: false,
    isSaveComplete: true,
    saveCompleteMessage: '',
    error: null,
    isUploading: false,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadStyledPage:
      return { ...state, styledPage: null, isLoading: true };

    case constants.loadStyledPageSuccess: {
      return {
        ...state,
          styledPage: action.payload,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadStyledPageFailure:
      return { ...state, error: action.error, styledPage: null, isLoading: false };

    case constants.postAddStyledPage:
      return { ...state, isLoading: true, saveCompleteMessage: '' };
    case constants.postAddStyledPageSuccess: {
      return {
        ...state,
          error: null,
          styledPage: action.payload,
          saveCompleteMessage: 'New styled page has been created',
          isLoading: false,
      };
    }
    case constants.postAddStyledPageFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };

    
    case constants.postImageUpload:
      return { ...state, isUploading: true };
    case constants.postImageUploadSuccess: {
      return {
        ...state,
        error: null,
        isUploading: false,
        saveCompleteMessage: action.payload.saveMessage
      };
    }
    case constants.postImageUploadFailure:
      return { ...state, error: action.error, isUploading: false };

    case constants.postAddStyledPageItemBlock:
      return { ...state, isSaveComplete: false, saveCompleteMessage: '' };
    case constants.postAddStyledPageItemBlockSuccess: {
      let itemBlocks = state.styledPage.styledPage.itemBlocks;
      itemBlocks = itemBlocks === undefined || itemBlocks === null ? [] : itemBlocks;
      itemBlocks.push(action.payload);

      const styledPage = state.styledPage;
      styledPage.styledPage.published = false;
      styledPage.styledPage.itemBlocks = itemBlocks;

      return {
        ...state,
          error: null,
          styledPage: styledPage,
          saveCompleteMessage: action.payload.message,
          isSaveComplete: true,
      };
    }
    case constants.postAddStyledPageItemBlockFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };


    case constants.deleteStyledPageItemBlock:
      return { ...state, isSaveComplete: false, saveCompleteMessage: '' };
    case constants.deleteStyledPageItemBlockSuccess: {
      const styledPage = state.styledPage;
      styledPage.styledPage = action.payload.styledPage;
      return {
        ...state,
          error: null,
          styledPage: styledPage,
          saveCompleteMessage: action.payload.message,
          isSaveComplete: true,
      };
    }
    case constants.deleteStyledPageItemBlockFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };


    case constants.postSaveItemBlockOrder:
      return { ...state, isSaveComplete: false, saveCompleteMessage: '' };
    case constants.postSaveItemBlockOrderSuccess: {
      const styledPage = state.styledPage;
      styledPage.styledPage.published = false;

      return {
        ...state,
          error: null,
          styledPage: styledPage,
          saveCompleteMessage: action.payload.message,
          isSaveComplete: true,
      };
    }
    case constants.postSaveItemBlockOrderFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };


    case constants.postPublishStyledPage:
      return { ...state, isSaveComplete: false, saveCompleteMessage: '' };
    case constants.postPublishStyledPageSuccess: {
      const styledPage = state.styledPage;
      styledPage.styledPage.published = true;

      return {
        ...state,
          error: null,
          styledPage: styledPage,
          saveCompleteMessage: action.payload.message,
          isSaveComplete: true,
      };
    }
    case constants.postPublishStyledPageFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };

    default:
      return state;
    }
}
