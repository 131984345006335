// @flow
import { useEffect, useState } from 'react';

export function useCookie() {
    const cookiePreferancesData = localStorage.getItem('cookiePreferances');
    
    let cookiePreferances = {
            accepted: false,
        };

    if(cookiePreferancesData !== null)
    {
      cookiePreferances = JSON.parse(cookiePreferancesData);
    }

    const [ userCookiePreferances, setUserCookiePreferances ] = useState(cookiePreferances);

    useEffect(() => {
        const cookiePreferancesData = localStorage.getItem('cookiePreferances');

        if(cookiePreferancesData !== null)
        {
            cookiePreferances = JSON.parse(cookiePreferancesData);
        }
    }, [userCookiePreferances]);

    function SetCookiePreferances()
    {
        cookiePreferances = {
            accepted: true,
        };
        localStorage.setItem('cookiePreferances',  JSON.stringify(cookiePreferances));
        setUserCookiePreferances(cookiePreferancesData);
    }

    return ({
        SetCookiePreferances,
        hasAccepted: cookiePreferances?.accepted
        });
}
