// @flow
import React from 'react';
import styled from 'styled-components';

const Arrow = styled.div`
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin: 5px;
`;

const ArrowUp = () =>
{
    return (<Arrow />);
};

export default ArrowUp;