// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import Moment from 'moment';
import { default as NumberFormat } from 'react-number-format';
import ArrowUp from '../common/ArrowUp';
import ArrowDown from '../common/ArrowDown';
import { ScrollToTop } from '../../helpers/scrollToTop';

const ActionLink = styled(Link)`
    text-decoration: none;
    border: none;
    outline: 0;
    color: #3d3d3d;

    &a {
        text-decoration: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;

const ExpandableRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${props => props.visible ? 'height: auto;' : 'height: 20px;'}

    border: 1px solid #d9d9d9;
    padding: 10px; 
    height: 100%;
`;

const ExpandableRowExpanderIcon = styled.div`
    height: 4px;
    width: 4px;
    position: absolute;
    right: 10px;
    z-index: 2;
    cursor: pointer;
`;

const ArrowContainer = styled.div`
    position: absolute;
    right: 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const PaddedColumn = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 20px 10px 0;
`;

const Table = styled.table`
    width: 100%;
  &&& {
    table {
        border-bottom: solid 1px #a29062;
    },
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      border-bottom: solid 1px #a29062;
      border-collapse: collapse;
    }
    th {
      text-align: left;
    }
  }
`;

const Tr = styled.tr`
    ${props => props.selected === true ? 'background-color:#d9d9d9;' : ''}
`;

const Icon = styled.div`
    font-size: 1.5em;
    border: none;
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

export default function(props) {

    const {
        error,
        orders,     
        GetOrderToView,
        viewOrderPaneVisible,
        UpdateViewOrderPaneVisible,
        order,
    } = props.props;
    
    const GetOrders = (orderItem, idx, order) => {
        return(
            <Tr key={idx} selected={ order && orderItem.orderId === order.orderId ? true : false }>
                <td>
                    {Moment(orderItem.dateAdded).format('D-M-YYYY')}
                </td>
                <td>
                    {orderItem.user.fullName}
                </td>
                <td>
                    {orderItem.items.length - 1}
                </td>
                <td>
                    <NumberFormat value={ orderItem.totalOrderPrice } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                </td>
                <td>
                    <ActionLink to={`#`} onClick={() => {GetOrderToView(orderItem.orderId);  ScrollToTop();}} ><Icon>&#9998;</Icon></ActionLink>
                </td>
            </Tr>);
    };

    const OrderItems = (item, id) => 
    {
        return(
            <tr key={id}>
                <td>{item.producNameAtTimeOfSale}</td>
                <td>{item.quantity}</td>
                <td><NumberFormat value={ item.quantity * item.unitPrice } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /></td>
            </tr>
        );
    };

return(
    <Content> 
        <Column> 
            <AccountActionLink to={ROUTES.MYACCOUNT} ><ArrowLeft /> Back To Account</AccountActionLink>
            <strong>My Orders</strong>
        </Column> 
        { order !== null ?

        (viewOrderPaneVisible ?
            <ExpandableRow visible={viewOrderPaneVisible}>
                <Column>
                    <Row>
                        <strong>Delivery To&nbsp;-&nbsp;</strong> {order.user && order.user.firstName} {order.user && order.user.lastName}&nbsp;&nbsp;&nbsp;
                        <strong>Order Id&nbsp;-&nbsp;</strong> {order.orderId}
                    </Row>
                    <ExpandableRowExpanderIcon onClick={() => UpdateViewOrderPaneVisible()}><ArrowContainer><ArrowUp open={viewOrderPaneVisible}/></ArrowContainer></ExpandableRowExpanderIcon>
                </Column>
                <Column>
                    <Row>
                        <PaddedColumn>
                            <strong>Delivery Address</strong><br />
                            {order.deliveryAddress && order.deliveryAddress.address}<br />
                            {order.deliveryAddress && order.deliveryAddress.address1}<br />
                            {order.deliveryAddress && order.deliveryAddress.address2}<br />
                            {order.deliveryAddress && order.deliveryAddress.city} <br />
                            {order.deliveryAddress && order.deliveryAddress.county}<br />
                            {order.deliveryAddress && order.deliveryAddress.postcode}<br />
                            {order.deliveryAddress && order.deliveryAddress.country}<br />
                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Billing Address</strong><br />
                            {order.billingAddress && order.billingAddress.address}<br />
                            {order.billingAddress && order.billingAddress.address1}<br />
                            {order.billingAddress && order.billingAddress.address2}<br /> 
                            {order.billingAddress && order.billingAddress.city}<br />
                            {order.billingAddress && order.billingAddress.county}<br />               
                            {order.billingAddress && order.billingAddress.postcode}<br />
                            {order.billingAddress && order.billingAddress.country}<br />                           
                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Order Status</strong><br />
                            {/* 
                            AwaitingPayment     = 0,
                            Processing          = 1,
                            Sent                = 2,
                            Returned            = 3,
                            Click and Collect   = 4, 
                            Collected           = 4, 
                            */}
                            {order.orderStatus === 0 ? 'Awaiting Payment' : null}
                            {order.orderStatus === 1 ? 'Processing' : null}
                            {order.orderStatus === 2 ? 'Sent' : null}
                            {order.orderStatus === 3 ? 'Returned' : null}
                            {order.orderStatus === 4 ? 'Ready to Collect' : null}
                            {order.orderStatus === 5 ? 'Collected' : null}
                                                 
                        </PaddedColumn>

                        <PaddedColumn>
                            <strong>Payment Status</strong><br />
                            {/* 
                            AwaitingPayment     = 0,
                            Succeses            = 1,
                            Failure             = 2,
                            Refunded            = 3 ,
                            */}
                            {order.paymentStatus === 0 ? 'Awaiting Payment' : null}
                            {order.paymentStatus === 1 ? 'Success' : null}
                            {order.paymentStatus === 2 ? 'Failure' : null}
                            {order.paymentStatus === 3 ? 'Refunded' : null}

                                                  
                        </PaddedColumn>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                order.items.map( (item, xid) => OrderItems(item, xid)) }
                                
                                <tr key={'total'}>
                                    <td></td>
                                    <td>Total</td>
                                    <td>
                                        <NumberFormat value={order.totalOrderPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                
                </Column>
            </ExpandableRow> : 
            
            <ExpandableRow visible={viewOrderPaneVisible}>
                <Column>
                    <Row>
                        <strong>Delivery To&nbsp;-&nbsp;</strong> {order.user && order.user.firstName} {order.user && order.user.lastName}&nbsp;&nbsp;&nbsp;
                        <strong>Order Id&nbsp;-&nbsp;</strong> {order.orderId}
                    </Row>
                    <ExpandableRowExpanderIcon onClick={() => UpdateViewOrderPaneVisible()}><ArrowContainer><ArrowDown open={viewOrderPaneVisible}/></ArrowContainer></ExpandableRowExpanderIcon>
                </Column>
            </ExpandableRow>)
        : null
        }

        <Column>
            <Table key={'orders'}>
                <thead>
                    <tr>
                        <th>Date Sold</th>
                        <th>Customer Name</th>
                        <th>Number of items</th>
                        <th>Total Price</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders.map((orderItem, idx) => GetOrders(orderItem, idx, order))}
                </tbody>
            </Table>
        </Column>

    </Content>
);
}