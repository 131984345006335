import React from 'react';
import styled from 'styled-components';
import CardSection from './CardSection';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useCheckoutPayment } from './useCheckoutPayment';

const Pointer = styled.div`
    padding-top: 10px;
    cursor: pointer;
    height:100%;
`;

const Button = styled.button`
    cursor: pointer;
    font-weight: bold;
    font-size:1.2em;
    padding:5px;
    min-width: 100px;
    width: 100%;
`;

const Error = styled.div`
    color: #a82700;
    ${props => props.error ? 'border-color: #a82700;' : 'border-color: default;'};
    font-weight: bold;
    font-size: 0.9em;
`;

export default function CheckoutForm(props) {

  const stripe = useStripe();
  const elements = useElements();

  const paymentDetails = props.props.paymentDetails;
  const basketItems = props.props.basketItems;
  const clientSecret = props.props.clientSecret; 

  const { 
      handleSubmitPayment,
      paymentErrors,
  } = useCheckoutPayment(paymentDetails, basketItems, clientSecret, stripe, elements);

  return (
    <form style={{width:'auto'}} onSubmit={handleSubmitPayment}>
      <CardSection />
      <Error>{paymentErrors}</Error>
      <Pointer><Button disabled={!stripe}>Pay</Button></Pointer>
    </form>
  );
}