// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

import worldMap from '../assets/maps/world-map.png';

const MapContainer = styled.div`
    position: relative;
    width:100%;
`;

const Heading = styled.div`
    display: flex;
    font-weight: bold;
    font-size: 1.5em;
    width: 100%;
    height: min-content;
`;

const Text = styled.div`
    display: flex;
    width: 100%;
    height: min-content;
`;

const PointContentContainer = styled.div`
    display: flex;
    flex-direction: column; 
    border: none;
    z-index: 1;
    overflow: hidden;
    transition: all 1s ease-in-out;
    height: auto;
    width: auto;
    max-height: 10px;
    max-width: 10px;
    color: transparent;
`;

const Pin = styled.div`
    display:flex;
    cursor: pointer;
    position: absolute;
    ${props =>  `top: ${props.positionTop};` }
    ${props =>  props.positionleft > 50 ? `right: ${100 - props.positionleft}%;` : `left: ${props.positionleft}%;` }
    border: solid 4px #a82700;
    height: auto;
    width: auto;
    z-index: 5;
    border-radius: 25px;
    background-color: #f5f5f5;
    transition: all .5s ease-in-out;

    max-height: 10px;
    max-width: 10px;

    @media (max-width: 1000px) {
        ${ props => props.active ? `max-height: ${props.height};` : 'max-height: 5px;'}
        ${ props => props.active ? `max-width: ${props.width};` : 'max-width: 5px;'}
    }

    @media (max-width: 799px) {
        ${ props => props.active ? `max-height: ${props.height};` : 'max-height: 0px;'}
        ${ props => props.active ? `max-width: ${props.width};` : 'max-width: 0px;'}
    }

    ${ props => props.active ? 'z-index: 100;' : ''}
    ${ props => props.active ? 'padding: 10px;' : ''}
    ${ props => props.active ? `max-height: ${props.height};` : ''}
    ${ props => props.active ? `max-width: ${props.width};` : ''}
    ${ props => props.active ? `transition: all .5s ease-in-out;` : ''}

    ${ props => props.active ? `height: auto;` : ''}
    ${ props => props.active ? `width: auto;` : ''}

    ${ props => props.active ? `${PointContentContainer} {color: #000000;}` : ''}
    ${ props => props.active ? `${PointContentContainer} {max-height: ${props.height};}` : ``}
    ${ props => props.active ? `${PointContentContainer} {max-width: ${props.width};}` : ``}
`;

const WorldMap = () => {
  
  const [pin, SetPin] = useState('');

  function setActivePin(name)  { SetPin(name); }

  return (<>
            <MapContainer>
                <img src={worldMap} alt="map of the earth" width='100%' onClick={() => setActivePin('')} />
                
                <Pin onClick={() => setActivePin('America')} active={pin === 'America'} positionTop={'27%'} positionleft={'12'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>America</Heading>
                        <Text>Designer Navajo Native American Silver jewellery.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Mexico')} active={pin === 'Mexico'} positionTop={'38%'} positionleft={'9.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Mexico</Heading>
                        <Text>Designer Silver Jewellery.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Ecuador')} active={pin === 'Ecuador'} positionTop={'56%'} positionleft={'16'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Ecuador</Heading>
                        <Text>Fair Trade colourful Tagua Nut (Vegetable Ivory) jewellery.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('United Kingdom')} active={pin === 'United Kingdom'} positionTop={'17%'} positionleft={'40'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>United Kingdom</Heading>
                        <Text>Galerie Mirages exclusive Designer Jewellery, Designer Silver and Gold plated jewellery as well as Fashion jewellery and greeting cards.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Latvia')} active={pin === 'Latvia'} positionTop={'15.5%'} positionleft={'47'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Latvia</Heading>
                        <Text>Stunning Baltic Amber set in Silver.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Poland')} active={pin === 'Poland'} positionTop={'18.5%'} positionleft={'45.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Poland</Heading>
                        <Text>Designer Silver and Gold plated jewellery. Stunning Baltic Amber set in Silver.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Germany')} active={pin === 'Germany'} positionTop={'19%'} positionleft={'43.25'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Germany</Heading>
                        <Text>Modern Designer Silver jewellery.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('France')} active={pin === 'France'} positionTop={'22%'} positionleft={'41.10'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>France</Heading>
                        <Text>Designer Jewellery and a Designer Collection of men's jewellery.  </Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Belgium')} active={pin === 'Belgium'} positionTop={'20%'} positionleft={'42'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Belgium</Heading>
                        <Text>Designer Collection of wood Jewellery.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Turkey')} active={pin === 'Turkey'} positionTop={'27%'} positionleft={'50'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Turkey</Heading>
                        <Text>Modern Designer Silver jewellery, and traditional Kilim rugs and cushions.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Yemen')} active={pin === 'Yemen'} positionTop={'44%'} positionleft={'56.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Yemen</Heading>
                        <Text>Original old jewellery in Silver and old Coral.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Iran')} active={pin === 'Iran'} positionTop={'32%'} positionleft={'58'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Iran</Heading>
                        <Text>Tradtional Kilim rugs.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Uzbekistan')} active={pin === 'Uzbekistan'} positionTop={'25%'} positionleft={'59.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Uzbekistan</Heading>
                        <Text>Unique jewellery, stunning lengths of Ikat silk, Ikat cushions and jackets, embriodered wall hangings and cushions and face masks.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Afghanistan')} active={pin === 'Afghanistan'} positionTop={'31.5%'} positionleft={'61'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Afghanistan</Heading>
                        <Text>Beautiful traditional silver jewellery, Lapis Lazuli, Kilim rugs and cushions, embroideries, ceramics.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Pakistan')} active={pin === 'Pakistan'} positionTop={'34%'} positionleft={'62.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Pakistan</Heading>
                        <Text>Hand carved furniture and boxes from the Swat Valley. Yak wool scarves and Fair Trade gifts and toys.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('India')} active={pin === 'India'} positionTop={'39%'} positionleft={'65.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>India</Heading>
                        <Text>Outstanding and one-off pieces of traditional jewellery, and an extensive range of modern silver and semi precious stone jewellery. Silk and wool scarves, Fair Trade Buddhas, cotton rugs, hand block printed bedspreads and quilts, silk jackets, kantha scarves and gifts.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Nepal')} active={pin === 'Nepal'} positionTop={'35%'} positionleft={'67'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Nepal</Heading>
                        <Text>Tradtional silver jewellery, as well as Fair Trade singing bowls and felted gifts and decorations.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('China')} active={pin === 'China'} positionTop={'30%'} positionleft={'71'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>China</Heading>
                        <Text>Fresh water Pearl jewellery, tradtional bone boxes and cricket boxes, and antique Chinese opera Puppets.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Japan')} active={pin === 'Japan'} positionTop={'29%'} positionleft={'82'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Japan</Heading>
                        <Text>Beautiful original silk Haori jackets and indigo textiles, Noren Doorway Curtains, Shibori, tradtional fans and postcards. </Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Myanmar (Burma)')} active={pin === 'Myanmar (Burma)'} positionTop={'40%'} positionleft={'70.80'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Myanmar (Burma)</Heading>
                        <Text>Burmese Ikat fabric and lacquerware.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Vietnam')} active={pin === 'Vietnam'} positionTop={'40%'} positionleft={'73.80'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Vietnam</Heading>
                        <Text>Ikat Silk Jackets.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Thailand')} active={pin === 'Thailand'} positionTop={'44%'} positionleft={'72.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Thailand</Heading>
                        <Text>Modern Designer jewellery in Silver and Gold plating, Bronzes, Buddhas and Fair Trade gifts. </Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Laos')} active={pin === 'Laos'} positionTop={'41%'} positionleft={'72.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Laos</Heading>
                        <Text>Indigo fabrics and scarves and Batik fabric.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Indonesia')} active={pin === 'Indonesia'} positionTop={'58%'} positionleft={'79'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Indonesia</Heading>
                        <Text>Designer Silver Jewellery. Fabrics in Ikat and Batik, Puppets, Fair Trade musical instruments and gifts.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Morocco')} active={pin === 'Morocco'} positionTop={'32%'} positionleft={'38.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Morocco</Heading>
                        <Text>Tuareg jewellery, Throws and cushions, Metal platters, Mirrors, Lanters, Fair Trade leather bags and boxes.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Tunisia')} active={pin === 'Tunisia'} positionTop={'31%'} positionleft={'43'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Tunisia</Heading>
                        <Text>Beautiful Ceramics.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Egypt')} active={pin === 'Egypt'} positionTop={'36%'} positionleft={'49'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Egypt</Heading>
                        <Text>Basket work and woven mats.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Mali')} active={pin === 'Mali'} positionTop={'42%'} positionleft={'39.75'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Mali</Heading>
                        <Text>Masks, artifacts and Bogolan (mudcloth) fabric and cushions. A selection of Dogon Indigo, Bronzes and cabinets.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Ghana')} active={pin === 'Ghana'} positionTop={'49%'} positionleft={'39.75'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Ghana</Heading>
                        <Text>Artifacts and woven basketwork, Fair Trade musical instruments.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Nigera')} active={pin === 'Nigera'} positionTop={'48%'} positionleft={'42.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Nigera</Heading>
                        <Text>Artifacts, fabrics and a selection of woven mats.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Gabon')} active={pin === 'Gabon'} positionTop={'55.75%'} positionleft={'43.75'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Gabon</Heading>
                        <Text>Masks.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Congo')} active={pin === 'Congo'} positionTop={'56%'} positionleft={'47.5'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Congo</Heading>
                        <Text>Kuba cloth textiles, masks.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Kenya')} active={pin === 'Kenya'} positionTop={'54.5%'} positionleft={'53.40'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Kenya</Heading>
                        <Text>Fair Trade ceramic, bone and horn jewellery. Fair trade soapstone gifts and decorations, and Galerie Mirages own 'Bags for Life' project. This project supports refugees from South Sudan that are living in Kakuma Refugee Camp in Kenya. Please see our Ethical Policy page for more information.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Ethiopia')} active={pin === 'Ethiopia'} positionTop={'49%'} positionleft={'54'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Ethiopia</Heading>
                        <Text>Coptic Crosses, jewellery and hand painted miniture Icons.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('Zimbabwe')} active={pin === 'Zimbabwe'} positionTop={'68.5%'} positionleft={'49.25'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>Zimbabwe</Heading>
                        <Text>Fair Trade baskets and seedpod birds.</Text>
                    </PointContentContainer>
                </Pin>

                <Pin onClick={() => setActivePin('South Africa')} active={pin === 'South Africa'} positionTop={'75.5%'} positionleft={'48'} height={'1600px'} width={'300px'}>
                    <PointContentContainer>
                        <Heading>South Africa</Heading>
                        <Text>Fair Trade gifts.</Text>
                    </PointContentContainer>
                </Pin>
            </MapContainer>
        </>
  );
}

export default WorldMap