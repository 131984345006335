// @flow
export const constants = {
    loadDeliveryAndBillingDetails: 'LOAD_DELIVERY_AND_BILLING_DETAILS',
    loadDeliveryAndBillingDetailsSuccess: 'LOAD_DELIVERY_AND_BILLING_DETAILS_SUCCESS',
    loadDeliveryAndBillingDetailsFailure: 'LOAD_DELIVERY_AND_BILLING_DETAILS_FAILURE',

    loadOrderClientSecret: 'LOAD_ORDER_CLIENT_SECRET',
    loadOrderClientSecretSuccess: 'LOAD_ORDER_CLIENT_SECRET_SUCCESS',
    loadOrderClientSecretFailure: 'LOAD_ORDER_CLIENT_SECRET_FAILURE',

    postOrderNewDetails: 'POST_NEW_ORDER_DETAILS',
    postOrderNewDetailsSuccess: 'POST_NEW_ORDER_DETAILS_SUCCESS',
    postOrderNewDetailsFailure: 'POST_NEW_ORDER_DETAILS_FAILURE',

    resetOrderNewDetails: 'RESET_NEW_ORDER_DETAILS',
    resetOrderNewDetailsSuccess: 'RESET_NEW_ORDER_DETAILS_SUCCESS',
};

export const actions = {
    loadDeliveryAndBillingDetails: () => ({
        type: constants.loadDeliveryAndBillingDetails
    }),
    loadDeliveryAndBillingDetailsSuccess: payload => ({
        type: constants.loadDeliveryAndBillingDetailsSuccess,
        payload,
    }),
    loadDeliveryAndBillingDetailsFailure: error => ({
        type: constants.loadDeliveryAndBillingDetailsFailure,
        error,
    }),

    loadOrderClientSecret: payload => ({
        type: constants.loadOrderClientSecret,
        payload
    }),
    loadOrderClientSecretSuccess: payload => ({
        type: constants.loadOrderClientSecretSuccess,
        payload,
    }),
    loadOrderClientSecretFailure: error => ({
        type: constants.loadOrderClientSecretFailure,
        error,
    }),

    postOrderNewDetails: payload => ({
        type: constants.postOrderNewDetails,
        payload
    }),
    postOrderNewDetailsSuccess: payload => ({
        type: constants.postOrderNewDetailsSuccess,
        payload,
    }),
    postOrderNewDetailsFailure: error => ({
        type: constants.postOrderNewDetailsFailure,
        error,
    }),
    resetOrderNewDetails: () => ({
        type: constants.resetOrderNewDetails
    }),
    resetOrderNewDetailsSuccess: () => ({
        type: constants.resetOrderNewDetailsSuccess
    })
};