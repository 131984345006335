import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/adminOpeningTimes';
import { get, httpPut } from '../../helpers/http';
import { adminOpeningTimesUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadAdminOpeningTimes() {
  try {
    
    const url = `${adminOpeningTimesUrl}`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadAdminOpeningTimesSuccess(response.data));
  } catch (error) {
    yield put(actions.loadAdminOpeningTimesFailure(GetErrorResponseMessage(error ,"loadAdminOpeningTimes", REQUEST_METHODS.GET)));
  }
}

function* postAdminUpdateOpeningTimes({payload}) {
  try {
    const url = `${adminOpeningTimesUrl}`;

    const response = yield call(httpPut, { url,  data: payload });

    yield put(actions.postAdminUpdateOpeningTimesSuccess(response.data));
  } catch (error) {
    yield put(actions.postAdminUpdateOpeningTimesFailure(GetErrorResponseMessage(error ,"postAdminUpdateOpeningTimes", REQUEST_METHODS.POST)));
  }
}

export default function* adminOrdersSaga() {
  yield takeLatest(constants.loadAdminOpeningTimes, loadAdminOpeningTimes);
  yield takeLatest(constants.postAdminUpdateOpeningTimes, postAdminUpdateOpeningTimes);
}
