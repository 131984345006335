// @flow
import { constants } from '../actions/blogs';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  isSaving: Boolean,
  error: ?Error,
  designBlogs: object[],
  designBlog: object,
  blogs: object[],
  selectedBlog: object,
  saveMessage: string,
};

const initialState: State = { 
  designBlogs: [],
  designBlog: null,
  blogs: [],
  selectedBlog: null,
  isLoading: true,
  isSaving: false,
  error: null,
  saveMessage: null
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {

    case constants.loadBlogs:
      return { ...state, isLoading: true, selectedBlog: null };
    case constants.loadBlogsSuccess: {
      return {
        ...state,
        blogs: action.payload,
        selectedBlog: null,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadBlogsFailure:
      return { ...state,  blogs: null, error: action.error, isLoading: false, selectedBlog: null};

    case constants.loadBlog:
      return { ...state, isLoading: true };
    case constants.loadBlogSuccess: {
      return {
        ...state,
        selectedBlog: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadBlogFailure:
      return { ...state,  selectedBlog: null, error: action.error, isLoading: false};

    case constants.loadBlogDesigns:
      return { ...state, isLoading: true };
    case constants.loadBlogDesignsSuccess: {
      return {
        ...state,
        designBlogs: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadBlogDesignsFailure:
      return { ...state,  designBlogs: null, error: action.error, isLoading: false};

    case constants.loadBlogDesign:
      return { ...state, isLoading: true };
    case constants.loadBlogDesignSuccess: {
      return {
        ...state,
        designBlog: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadBlogDesignFailure:
      return { ...state,  designBlog: null, error: action.error, isLoading: false};

    case constants.saveBlogDesign:
      return { ...state, isSaving: true, saveMessage: null };
    case constants.saveBlogDesignSuccess: {
      return {
        ...state,
        error: null,
        isSaving: false,
        saveMessage: action.payload.saveMessage
      };
    }
    case constants.saveBlogDesignFailure:
      return { ...state, error: action.error, isSaving: false, saveMessage: null };

    case constants.newBlogDesign:
      return { ...state, isSaving: true, saveMessage: null };
    case constants.newBlogDesignSuccess: {
      const designBlogs = state.designBlogs;
      designBlogs.push(action.payload.blog);

      return {
        ...state,
        error: null,
        isSaving: false,
        designBlogs: designBlogs,
        selectedBlog: action.payload.blog,
        saveMessage: action.payload.saveMessage
      };
    }
    case constants.newBlogDesignFailure:
      return { ...state, error: action.error, isSaving: false, saveMessage: null, selectedBlog: null };

    case constants.publishBlogDesign:
      return { ...state, isSaving: true, saveMessage: null };
    case constants.publishBlogDesignSuccess: {
      return {
        ...state,
        error: null,
        isSaving: false,
        saveMessage: action.payload.saveMessage
      };
    }
    case constants.publishBlogDesignFailure:
      return { ...state, error: action.error, isSaving: false, saveMessage: null };

    case constants.removeBlog:
      return { ...state, isSaving: true, saveMessage: null };
    case constants.removeBlogSuccess: {
      return {
        ...state,
        error: null,
        isSaving: false,
      };
    }
    case constants.removeBlogFailure:
      return { ...state, error: action.error, isSaving: false };
  
    case constants.imageUpload:
      return { ...state, isUploading: true };
    case constants.imageUploadSuccess: {
        return {
          ...state,
          error: null,
          isUploading: false,
          saveMessage: action.payload.saveMessage
        };
      }
    case constants.imageUploadFailure:
        return { ...state, error: action.error, isUploading: false };
    default:
      return state;
    }
}
