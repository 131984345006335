import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router, Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';
import store, { persistor } from './state/store'; 
import { history } from './state/effects/router';
import theme from './theme';
import App from './containers/App';

import { useCookie } from './components/cookies/useCookie';

import Footer from './containers/root/Footer';
import Header from './containers/root/Header';
import Login from './containers/accounts/Login';
import { MyAccount, ResetPassword, ResetPasswordRequest, ViewOrders, ViewCustomerDetails, CreateUserAccount } from './containers/accounts';

import RestrictedPage from './containers/accounts/LogoutWrapper';

import ROUTES from './constants/routes';
import ROLES from './constants/roles';

import { Unauthorised } from './containers/accounts';

import { Basket, Checkout, OrderConfirmation } from './containers/basket';

import { Products, Contact, Brands, Product, Blogs } from './containers/pages';

import { StyledPage, Home } from './containers/styledPages';


import { Admin, EditProduct, AdminProductSearch, ViewOrders as AdminViewOrders, AddAccount, EditBlogs, TestBlog,
    EditStyledPage, TestStyledPage, EditCategories, EditOpeningTimes } from './containers/siteAdmin';

import pageNotFound from './containers/PageNotFound';

const Content = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column; 
`;

const Column = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column; 
`;

const HorizontalRule = styled.div`
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0 10px 0;
`;

const HorizontalRuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
`;

const CookiePolicyContainer = styled.div`
    display:flex;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    ${props => `height: ${props.height}px;` }
    overflow: hidden;
    bottom: 0px;
    background-color: rgba(217, 217, 217, 0.9);
    align-items:center;
    justify-content: center;
    font-size: 1.1em;
    z-index:100;
    transition: all .5s;
`;

const CookiePolicy = styled.div`
    width: 80%;
    @media (max-width: 799px) {
        width: 100%;
    };
`;

const AcceptCookiesButton = styled.button`
    padding:5px;
    min-width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 1.2em;
    cursor: pointer;
`;

const LinkItem = styled(Link)`
    text-decoration: none;
    text-decoration: underline;
    color: #000000;
`;


class DebugRouter extends Router {
    constructor(props){
      super(props);
      console.log('initial history is: ', JSON.stringify(history, null,2))

      history.listen(props => {

        const {
            hash,
            key,
            pathname,
            search,
            state,
            action } = props;

        console.log(
            `The current URL is ${pathname}${search}${hash}`
        );
    
        //console.log(`The last navigation action was ${action}`, JSON.stringify(props, null, 2));
      });

    }
}

export default function Root() {

    const { SetCookiePreferances, hasAccepted } = useCookie();

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <ThemeProvider theme={theme}>
                        <App>
                            <Column>
                                <Header />
                                <Content>
                                <HorizontalRuleContainer><HorizontalRule /></HorizontalRuleContainer>
                                <Switch>
                                        <Route path={ROUTES.UNAUTHORISED}
                                                component={Unauthorised}
                                                exact 
                                        />

                                        <Route path={ROUTES.INDEX}
                                                component={Home}
                                                exact 
                                        />

                                        <Route path={`${ROUTES.INDEX}index.html`}
                                                component={Home}
                                                exact 
                                        />

                                        <Route path={ROUTES.LOGIN}
                                                component={Login}
                                                exact 
                                        />
                                    
                                        <Route path={`${ROUTES.PRODUCTS}/:category/:subCategory/:subSubCategory`}
                                                component={Products}
                                                exact
                                        />
                                        <Route path={`${ROUTES.PRODUCTS}/:category/:subCategory/`}
                                                component={Products}
                                                exact
                                        />
                                        <Route path={`${ROUTES.BRAND}/:category/:brand`}
                                                component={Brands}
                                                exact
                                        />
                                        <Route path={`${ROUTES.BRAND}/:category`}
                                                component={Brands}
                                                exact
                                        />
                                        <Route path={ROUTES.CONTACT}
                                                component={Contact}
                                                exact 
                                        />
                                        <Route path={`${ROUTES.BLOG}/:blogName`}
                                                component={Blogs}
                                                exact
                                        />
                                        <Route path={`${ROUTES.BLOG}`}
                                                component={Blogs}
                                                exact
                                        />

                                        <Route path={`${ROUTES.PRODUCT}/:name`}
                                                component={Product}
                                                exact 
                                        />

                                        <Route path={ROUTES.REQUEST_PASSWORD_RESET}
                                                component={ResetPasswordRequest}
                                                exact 
                                        />

                                        <Route path={`${ROUTES.RESET_PASSWORD}/:token`}
                                                component={ResetPassword}
                                                exact 
                                        />

                                        <Route path={ROUTES.BASKET}
                                                component={Basket}
                                                exact 
                                        />

                                        <Route path={ROUTES.CHECKOUT}
                                                component={Checkout}
                                                exact 
                                        />

                                        <Route path={ROUTES.CREATE_USER_ACCOUNT}
                                                component={CreateUserAccount}
                                                exact 
                                        />

                                        <Route path={ROUTES.ORDER_CONFIRMATION}
                                                component={OrderConfirmation}
                                                exact 
                                        />

                                        <Route path={ROUTES.ADMIN} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN}
                                                component={Admin} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={`${ROUTES.EDIT_PRODUCT}/:id`} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={`${ROUTES.EDIT_PRODUCT}/:id`}
                                                component={EditProduct} />
                                            </RestrictedPage>
                                        </Route>
                                        <Route path={`${ROUTES.EDIT_PRODUCT}`} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={`${ROUTES.EDIT_PRODUCT}`}
                                                component={EditProduct} />
                                            </RestrictedPage>
                                        </Route>
                                        <Route path={ROUTES.ADMIN_PRODUCT_SEARCH} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_PRODUCT_SEARCH}
                                                component={AdminProductSearch} />
                                            </RestrictedPage>
                                        </Route>
                                        <Route path={ROUTES.ADMIN_VIEW_ORDERS} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_VIEW_ORDERS}
                                                component={AdminViewOrders} />
                                            </RestrictedPage>
                                        </Route>
      
                                        <Route path={ROUTES.MYACCOUNT} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.CUSTOMER}>
                                                <Route path={ROUTES.MYACCOUNT}
                                                component={MyAccount} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.VIEW_CUSTOMER_DETAILS} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.CUSTOMER}>
                                                <Route path={ROUTES.VIEW_CUSTOMER_DETAILS}
                                                component={ViewCustomerDetails} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ADD_ACCOUNT} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADD_ACCOUNT}
                                                component={AddAccount} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.VIEW_ORDERS} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.CUSTOMER}>
                                                <Route path={ROUTES.VIEW_ORDERS}
                                                component={ViewOrders} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ADMIN_BLOGS} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_BLOGS}
                                                component={EditBlogs} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={`${ROUTES.ADMIN_TEST_BLOG}/:blogId`} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={`${ROUTES.ADMIN_TEST_BLOG}/:blogId`}
                                                component={TestBlog} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ADMIN_STYLED_PAGES} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_STYLED_PAGES}
                                                component={EditStyledPage} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={`${ROUTES.ADMIN_TEST_STYLED_PAGES}/:pageId`} exact >
                                            <RestrictedPage parent={ROUTES.INDEX} role={ROLES.ADMINISTRATOR}>
                                                <Route path={`${ROUTES.ADMIN_TEST_STYLED_PAGES}/:pageId`}
                                                component={TestStyledPage} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ADMIN_EDIT_CATEGORIES} exact >
                                            <RestrictedPage parent={ROUTES.ADMIN_EDIT_CATEGORIES} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_EDIT_CATEGORIES}
                                                component={EditCategories} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ADMIN_EDIT_OPENINGTIMES} exact >
                                            <RestrictedPage parent={ROUTES.ADMIN_EDIT_OPENINGTIMES} role={ROLES.ADMINISTRATOR}>
                                                <Route path={ROUTES.ADMIN_EDIT_OPENINGTIMES}
                                                component={EditOpeningTimes} />
                                            </RestrictedPage>
                                        </Route>

                                        <Route path={ROUTES.ETHICAL_POLICY}
                                                component={StyledPage}
                                                exact 
                                        />
                                        <Route path={ROUTES.PRIVACY_POLICY}
                                                component={StyledPage}
                                                exact 
                                        />
                                        <Route path={ROUTES.ABOUT} 
                                                component={StyledPage} 
                                                exact 
                                        />
                                        <Route path={ROUTES.TERMS}
                                                component={StyledPage}
                                                exact 
                                        />
                                        <Route path={ROUTES.EXHIBITIONS}
                                                component={StyledPage}
                                                exact 
                                        />

                                        <Route 
                                            component={pageNotFound}
                                            exact 
                                        />
                                </Switch>
                                </Content>
                            </Column>
                            <Footer />

                            {<CookiePolicyContainer height={hasAccepted ? '0' : '200'} > 
                                <CookiePolicy >
                                    <b>Cookie Consent</b>
                                    <p>By continuing to browse or by clicking ‘Accept’, you agree to the storing of cookies on your device to enhance your site experience and for analytical purposes.<br/>
                                    To learn more about how we use the cookies, please see our <LinkItem to={ROUTES.PRIVACY_POLICY}>Privacy Policy</LinkItem>.</p>

                                    <AcceptCookiesButton type='submit' onClick={SetCookiePreferances} >Accept</AcceptCookiesButton> 
                                </CookiePolicy>
                            </CookiePolicyContainer>}
                            
                        </App>
                    </ThemeProvider>
                </Router>
            </PersistGate>
        </Provider>
    );
}