// @flow
import { constants } from '../actions/home';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  pages: [],
};

const initialState: State = { 
  pages: [],
  isLoading: false,
  error: null
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadHomePage:
      return { ...state, isLoading: true, error: null };

    case constants.loadHomePageSuccess: {
      let updatedState = state.pages;
      updatedState.push(action.payload);

      return {
        ...state,
        pages: updatedState,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadHomePageFailure:
      return { ...state,  pages: [], error: action.error, isLoading: false};

    default:
      return state;
    }
}
