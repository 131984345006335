import { useEffect } from 'react';

export function useEditAccountDetailsForm(customerDetails, setValue, register) {

  useEffect(() => {
    register('country', {
        required: 'Select a country.',
    });

    register('billingCountry', {
        required: 'Enter a country.',
    });
}, []);

useEffect(() => {
  if(customerDetails && customerDetails.deliveryAddress && customerDetails.deliveryAddress.country)
  {
    setValue('country', customerDetails.deliveryAddress.country);
  }

  if(customerDetails && customerDetails.billingAddress && customerDetails.billingAddress.country)
  {
    setValue('billingCountry', customerDetails.billingAddress.country);
  }
}, [customerDetails]);
}
