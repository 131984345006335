export const constants = {
    loadAdminProductSearch: 'LOAD_ADMIN_PRODUCT_SEARCH',
    loadAdminProductSearchSuccess: 'LOAD_ADMIN_PRODUCT_SEARCH_SUCCESS',
    loadAdminProductSearchFailure: 'LOAD_ADMIN_PRODUCT_SEARCH_FAILURE',

    loadAdminProductDetails: 'LOAD_ADMIN_PRODUCT_DETAILS',
    loadAdminProductDetailsSuccess: 'LOAD_ADMIN_PRODUCT_DETAILS_SUCCESS',
    loadAdminProductDetailsFailure: 'LOAD_ADMIN_PRODUCT_DETAILS_FAILURE',

    saveProductDetails: 'SAVE_ADMIN_PRODUCT_DETAILS',
    saveProductDetailsSuccess: 'SAVE_ADMIN_PRODUCT_DETAILS_SUCCESS',
    saveProductDetailsFailure: 'SAVE_ADMIN_PRODUCT_DETAILS_FAILURE',

    imageUpload: 'IMAGE_UPLOAD',
    imageUploadSuccess: 'IMAGE_UPLOAD_SUCCESS',
    imageUploadFailure: 'IMAGE_UPLOAD_FAILURE',
};

export const actions = {
    loadAdminProductSearch: products => ({
        type: constants.loadAdminProductSearch,
        payload: products,
    }),
    loadAdminProductSearchSuccess: payload => ({
        type: constants.loadAdminProductSearchSuccess,
        payload,
    }),
    loadAdminProductSearchFailure: error => ({
        type: constants.loadAdminProductSearchFailure,
        error,
    }),

    loadAdminProductDetails: productDetails => ({
        type: constants.loadAdminProductDetails,
        payload: productDetails,
    }),
    loadAdminProductDetailsSuccess: payload => ({
        type: constants.loadAdminProductDetailsSuccess,
        payload,
    }),
    loadAdminProductDetailsFailure: error => ({
        type: constants.loadAdminProductDetailsFailure,
        error,
    }),

    saveProductDetails: updatedPoductDetails => ({
        type: constants.saveProductDetails,
        payload: updatedPoductDetails,
    }),
    saveProductDetailsSuccess: payload => ({
        type: constants.saveProductDetailsSuccess,
        payload,
    }),
    saveProductDetailsFailure: error => ({
        type: constants.saveProductDetailsFailure,
        error,
    }),

    imageUpload: imageUpload => ({
        type: constants.imageUpload,
        payload: imageUpload,
    }),
    imageUploadSuccess: payload => ({
        type: constants.imageUploadSuccess,
        payload,
    }),
    imageUploadFailure: error => ({
        type: constants.imageUploadFailure,
        error,
    })

    
};