import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/menu';
import { get } from '../../helpers/http';
import { menuDetailsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadMenuDetails() {
  try {
    
    const url = `${menuDetailsUrl}`;

    const response = yield call(get, { url });

    yield put(actions.loadMenuDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadMenuDetailsFailure(GetErrorResponseMessage(error, "siteMenu", REQUEST_METHODS.GET)));
  }
}

export default function* menuSaga() {
  yield takeLatest(constants.loadMenuDetails, loadMenuDetails);
}
