// @flow
import React from 'react';
import styled from 'styled-components';
import ArrowLeft from '../common/ArrowLeft';
import { Link } from 'react-router-dom';
import { default as NumberFormat } from 'react-number-format';
import { imagesLocation } from '../../settings';
import { ScrollToTop } from '../../helpers/scrollToTop';
import ROUTES from '../../constants/routes';
import LoadingAnimation from '../common/LoadingAnimation';

import DropdownList from '../common/DropdownList';
import Input from '../common/Input';
import Checkbox from '../common/Checkbox';

import soldOut from '../../assets/icons/SoldOut.png';

import '../../styles/Slider/sliderStyle.css';
import Slider from 'rc-slider';

import { useAdminProductSearch } from './useAdminProductSearch';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    flex-wrap: wrap;
    position:relative;
    width: 100%;
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const Products = styled.div`
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items:stretch;
    width: 100%;
    position:relative;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const FilterTypeTitle = styled.div`
    display: flex;
    width: 100%;
    font-weight: bold;
    padding-top:10px;
`;

const Price = styled.div`
    display: flex;
    justify-content: center;
    font-weight: bold;
    width: 100%;
`;

const ProductContainer = styled.div`
    display: flex;
    position: relative;
    text-decoration: none;
    flex-direction: column;
    border: 1px solid #ffffff;
    &:hover {
        border: 1px solid #adadad;
    };
    a:visited  {
        color: #000000;
    };
    cursor: pointer;
    color: #000000;
    height: min-content;

    width: 200px;

    @media (min-width: 200px) {
        width: 48%;
    }

    @media (min-width: 550px) {
        width: 48%;
    }

    @media (min-width: 780px) {
        width: 30%;
    }    
    
    @media (min-width: 800px) {
        width: 30%;
    }

    @media (min-width: 1024px) {
        width: 32%;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
    
    margin: 5px;
    height:100%;

    @media (max-width: 799px) {
        width: 150px;
    }

    @media (min-width: 800px) {
        width: 150px;
    }

    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 1024px) {
        width: 300px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    padding: 10px;
    ${props => props.inStock ? null : 'border: solid 1px red'}
`;

const SoldOut = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
`;

const StyledImage = styled.div`
    align-items: stretch;
`;

const ProductImageContainer = styled.div`
`;

const TextContainer = styled.div`
`;

const PageUp = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: 0;
    background-color: #ffffff;
    font-size: 1em;
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const PageDown = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: 0;
    background-color: #ffffff;
    font-size: 1em;
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space:nowrap;
`;

const PaginationButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
`;

const PaginationButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
        width:100%;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 200px;
    justify-content: space-around;

    @media (max-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
        width:100%;
    }
`;

const SearchResults = styled.div`
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
`;

const PageSize = styled.div`
    display: flex;
    flex-direction: row;
    align-content: end;
    width: auto;
    padding-right:10px;
`;

const FilterItemContainerItems = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
`;

const FilterItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    ${props => props.inherit ? 'width: inherit;' : 'null'}
`;

const SliderContainer= styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: inherit;
`;

const RightArrow = styled.div`
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin: 5px;
`;

const LeftArrow = styled.div`
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 5px;
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

const Img = styled.img` 
    border: none;
    src: url(${props => props.src});
    height: 80px;
`;

const searchTextRef = React.createRef();

function getProduct(product: object) {

    let img = '';
    try {
        img = product.images && `${imagesLocation}products/${product.images.filter(x => x.order === 0)[0].path}`;
    } catch (error) {
        img = ``;
    }
    
    return product && product.hasOwnProperty('name') ? (
    <ProductContainer>
            <StyledLink 
                to={`${ROUTES.EDIT_PRODUCT}/${product.id}`}
                inStock={product.quantity === 0 ? false : true}>
                {product.quantity === 0 ? <SoldOut><Img src={soldOut} alt={'Sold Out'} /></SoldOut> : null}
                <ProductImageContainer>
                    {product.images && product.images[0] ?
                        <img key={img} 
                        src={img} 
                        title={product.name} 
                        alt={''} 
                        width='100%' /> :
                        <></>
                    }
                </ProductImageContainer>
                <TextContainer>
                    <Title>{product.name}</Title>
                    <Price><NumberFormat value={product.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /></Price>
                </TextContainer>          
            </StyledLink>
        </ProductContainer>) :
        ( 
        <StyledImage>
            <ImageContainer>
                <ImageContainer>
                    <img key={product.path} src={`${imagesLocation}products/${product.path}`} alt={product.name} width='100%' />
                </ImageContainer>
            </ImageContainer>
        </StyledImage>
        );
}

export default function(props) {

    ScrollToTop();
    
    const {
      searchText, 
      brand, 
      category, 
      subCategory, 
      subSubCategory
  } = props.props.match.params;

    const defaultProps = {
        searchText, 
        brand, 
        category, 
        subCategory, 
        subSubCategory,
        maxPrice: 0,
        minPrice: 0,
        page: 0,
        pageSize: 10
      };

    const {
        error,
        products,
        isLoading,
        filters,
        productFilters,
        updateFilters,
        totalPages
    } = useAdminProductSearch(defaultProps);


    const pageUp = () => { 
        const page = (filters.page + 1) < totalPages ? filters.page + 1 : filters.page;
        updateFilters({...filters,
            page: page
        });
    }

    const pageDown = () => { 
        const page = (filters.page - 1) <= 0 ? 0 : filters.page - 1
        updateFilters({...filters,
            page: page
        });
    }

    const updateFilterableSearch = (value, selected) => { 
        let newFilterableSearchString = filters.filterableSearchString;

        if(!selected) {
            newFilterableSearchString += `${value}^`;
        }
        else {
            newFilterableSearchString = newFilterableSearchString.replace(`${value}^`, '');
        }
        
        updateFilters({
            ...filters,
            filterableSearchString: newFilterableSearchString
        });
    }

    let previousType = "";
    function getProductFilters(filterableItem: Object) {

        return (<FilterItemContainer inherit={false}><FilterItemContainerItems>
            {filterableItem.map((item) => {
        let typeString = '';
        if(previousType !== item.type) {
            if(item.type === "Brand"){ typeString = "Collection"; }
            if(item.type === "Category"){ typeString = "Category"; }
            if(item.type === "SubCategory"){ typeString = "Sub Category"; }
            if(item.type === "SubSubCategory"){ typeString = "Sub Sub Category"; }
            previousType = item.type;
        }

        return (
        <>
            { typeString ? <FilterTypeTitle>{typeString}<br /></FilterTypeTitle> : null}
            <Checkbox 
                keyValue={filterableItem.name} 
                name={item.name} 
                value={item.selected} 
                inline={true}
                onChange={() => updateFilterableSearch(`${item.type}<>${item.name}`, item.selected)}
                label={item.name} 
            />
        </>)})}
        </FilterItemContainerItems></FilterItemContainer>);

    }

    const priceRangeChange = (e) => { 

        if(e[0] <= e[1])
        {
            updateFilters({...filters,
                minPrice: e[0],
                maxPrice: e[1],
                page: 0
            });
        }
        else
        {
            updateFilters({...filters,
                minPrice: e[1],
                maxPrice: e[0],
                page: 0
            });
        }
    }

    let filterableProductCategoriesGroups = productFilters && productFilters.filterableProductCategories ? 
    productFilters.filterableProductCategories.reduce((r, a) => {
        r[a.type] = [...r[a.type] || [], a];
        return r;
       }, {}) : [];

       filterableProductCategoriesGroups = Array.from(Object.values(filterableProductCategoriesGroups));

    return isLoading === true || products === undefined && filters === null ? 
        (<Container><Loading><LoadingAnimation /></Loading></Container>) : error && error.message ? 
        (<Container><Error>{error.message}</Error></Container>) :
        (<Container>
            <Column> 
                <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
            </Column> 
            <PaginationContainer>
                <PaginationButtonsContainer>
                    <PaginationButtons>
                        <PageDown disabled={(filters.page === 0)} onClick={pageDown} ><LeftArrow /> {'Back'} &nbsp;</PageDown>
                        <PageUp disabled={(filters.page + 1 === totalPages)} onClick={pageUp}>&nbsp; {'Next'} <RightArrow /></PageUp>
                        &nbsp;{`page ${filters.page + 1} of ${totalPages == null ? (filters.page + 1):totalPages}`}
                    </PaginationButtons>
                    <PageSize>
                        <DropdownList 
                            name="pageSize" 
                            value={filters.pageSize}
                            options={['12', '20', '40', '80']}
                            onChange={(keyValue, values) => {
                            updateFilters({
                                ...filters,
                                pageSize: values.value,
                                page: 0,
                            });
                            }}
                        /> 
                        &nbsp;per page
                    </PageSize> 
                </PaginationButtonsContainer>  
            </PaginationContainer>
            <Products>
                <Filters>
                    <FiltersContainer>
                        <FilterItemContainer inherit={true}>
                            <>Price range</>
                            <SliderContainer>
                            £{productFilters.minPrice}&nbsp;&nbsp;
                            <Range onAfterChange={priceRangeChange} max={productFilters.maxPrice} min={productFilters.minPrice} defaultValue={
                                [filters.maxPrice === null ? productFilters.maxPrice : filters.maxPrice, 
                                filters.minPrice === null ? productFilters.minPrice : filters.minPrice, ]} 
                                tipFormatter={value => `£${value}`} /> 
                            &nbsp;&nbsp;£{productFilters.maxPrice}
                            </SliderContainer>
                        </FilterItemContainer>
                        <FilterItemContainer inherit={true}>
                            <DropdownList 
                                name="orderBy"
                                label="Sort By" 
                                value={productFilters.sortBy}
                                options={productFilters.sortByOptions.map(option => option)}
                                onChange={(keyValue, values)  => {
                                updateFilters({
                                    ...filters,
                                    sortBy: values.value,
                                });
                            }}
                            /> 
                        </FilterItemContainer>
                        <FilterItemContainer inherit={true}>
                            <Input 
                                name="searchText"
                                label="Search" 
                                keyValue="searchText"
                                isSearch={true}
                                ref={searchTextRef}
                                placeholder={productFilters.searchText}
                                onChange={(keyValue, value)  => {
                                    updateFilters({
                                        ...filters,
                                        searchText: value,
                                    });
                                }}
                            /> 
                        </FilterItemContainer>
                    </FiltersContainer>
                    <FiltersContainer>
                        {filterableProductCategoriesGroups !== null ? filterableProductCategoriesGroups.map((filterableItem) => getProductFilters(filterableItem)) : null}
                    </FiltersContainer>           
                </Filters> 
                <SearchResults>
                    {products && products.map((product, images) => getProduct(product))}
                </SearchResults>
            </Products>
            <PaginationContainer>
                <PaginationButtonsContainer>
                    <PaginationButtons>
                        <PageDown disabled={(filters.page === 0)} onClick={pageDown} ><LeftArrow /> {'Back'} &nbsp;</PageDown>
                        <PageUp disabled={(filters.page + 1 === totalPages)} onClick={pageUp}>&nbsp; {'Next'} <RightArrow /></PageUp>
                        &nbsp;{`page ${filters.page + 1} of ${totalPages == null ? (filters.page + 1):totalPages}`}
                    </PaginationButtons>
                </PaginationButtonsContainer>  
            </PaginationContainer>
         </Container>)
}