export const constants = {
    loadAdminOpeningTimes: 'LOAD_ADMIN_OPENING_TIMES',
    loadAdminOpeningTimesSuccess: 'LOAD_ADMIN_OPENING_TIMES_SUCCESS',
    loadAdminOpeningTimesFailure: 'LOAD_ADMIN_OPENING_TIMES_FAILURE',

    postAdminUpdateOpeningTimes: 'POST_ADMIN_UPDATE_OPENING_TIMES',
    postAdminUpdateOpeningTimesSuccess: 'POST_ADMIN_UPDATE_OPENING_TIMES_SUCCESS',
    postAdminUpdateOpeningTimesFailure: 'POST_ADMIN_UPDATE_OPENING_TIMES_FAILURE',
};

export const actions = {
    loadAdminOpeningTimes: () => ({
        type: constants.loadAdminOpeningTimes,
    }),
    loadAdminOpeningTimesSuccess: payload => ({
        type: constants.loadAdminOpeningTimesSuccess,
        payload,
    }),
    loadAdminOpeningTimesFailure: error => ({
        type: constants.loadAdminOpeningTimesFailure,
        error,
    }), 
    
    postAdminUpdateOpeningTimes: payload => ({
        type: constants.postAdminUpdateOpeningTimes,
        payload,
    }),
    postAdminUpdateOpeningTimesSuccess: payload => ({
        type: constants.postAdminUpdateOpeningTimesSuccess,
        payload,
    }),
    postAdminUpdateOpeningTimesFailure: error => ({
        type: constants.postAdminUpdateOpeningTimesFailure,
        error,
    }),
};