// @flow
import React, { useRef } from 'react';
import styled from 'styled-components';
import Input from '../common/Input';
import { CreateAccountAccessRestrictions } from '../../helpers/SiteRestrictionAccess';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import { useCreateUserAccount } from './useCreateUserAccount';
import { ScrollToTop } from '../../helpers/scrollToTop';

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Button = styled.button`
    padding: 5px;
    margin-top: 10px;
    min-width: 100px;
    max-width: 150px;
    cursor: pointer;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color:#5e5e5e;
`;

const RowColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
    padding: 10px;
    width:100%;

    @media (max-width: 799px) {
        width: auto;
        padding: 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const CustomerDetailsForm = styled.div`
    min-width:250px;
    width: 100%;
`;

const SaveComplete = styled.div`
    color: green;
`;

const Error = styled.div`
    color: red;
`;

export default function(props) {
    
    const {
        error,
        saveAccountSubmit,
        isLoggedIn
    } = useCreateUserAccount();

    const { isEnabled, message } = CreateAccountAccessRestrictions();
     
    const { handleSubmit, register, errors, watch } = useForm();  

    const password = useRef({});
    password.current = watch("password", "");

    setTimeout(() => ScrollToTop(), 100);

    return (
    <Content>
        <Row>
            <Column> 
                <AccountActionLink to={ROUTES.LOGIN} ><ArrowLeft /> Cancel</AccountActionLink>
                <strong>Create an new account</strong>
            </Column> 
        </Row>
        {
            isEnabled === false ? 
            <Row><RowColumn dangerouslySetInnerHTML={{__html: message}}/></Row> :
            <Row>
                <CustomerDetailsForm>
                    <form style={{width:'100%'}} onSubmit={handleSubmit(saveAccountSubmit)}>
                        <RowColumn>
                            <Column>
                                <Input
                                    keyValue='firstName'
                                    label='First Name' 
                                    type='text' 
                                    
                                    placeholder='' 
                                    name="firstName"
                                    error={errors.firstName}
                                    onChange={(keyValue, value) => {}}
                                    ref={register({
                                    required: 'Please enter your first name.',
                                    minLength: { value: 3, message: "First name must be longer than 3 characters" },
                                    })}
                                    required />

                                <Input
                                    keyValue='lastName'
                                    label='Last Name' 
                                    type='text' 
                                    
                                    placeholder='' 
                                    name="lastName"
                                    error={errors.lastName}
                                    onChange={(keyValue, value) => {}} 
                                    ref={register({
                                    required: 'Please enter your last name.',
                                    minLength: { value: 3, message: "Last name must be longer than 3 characters" },
                                    })}
                                    required />
                            </Column>
                            <Column>
                                <Input
                                    keyValue='username'
                                    label='Email Address/Username' 
                                    type='text' 
                                    
                                    placeholder='' 
                                    name="username"
                                    error={errors.username}
                                    onChange={(keyValue, value) => {}} 
                                    ref={register({
                                    required: 'Please enter your email address.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Invalid email address"
                                    }})}
                                    required
                                    />

                                <Input
                                    keyValue='contactNumber'
                                    label='Contact number' 
                                    type='text' 
                                    
                                    placeholder='' 
                                    name="contactNumber"
                                    error={errors.contactNumber}
                                    onChange={(keyValue, value) => {}} 
                                    ref={register({
                                        required: 'Please enter your contact number.',
                                        minLength: { value: 11, message: "Contact number must be longer than 11 characters" },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Invalid contact number"
                                        }
                                    })}
                                    required
                                    />
                            </Column>
                        </RowColumn> 
                        <RowColumn>
                            <Column>
                                <Input 
                                    type='password' 
                                    label='Password'
                                    placeholder='' 
                                    name="password"
                                    error={errors.password}
                                    onChange={(value) => {}}
                                    ref={register({
                                        required: 'Please enter a password.',
                                    })}
                                    required />

                                </Column>
                                <Column>

                                <Input 
                                    type='password' 
                                    label='Confirm Password'
                                    placeholder='' 
                                    name="confirmPassword"
                                    error={errors.confirmPassword}
                                    onChange={(value) => {}}
                                    ref={register({
                                        required: 'Please re-enter your password.',
                                        validate: value => value === password.current || "The passwords do not match"
                                    })}
                                    required />

                            </Column>
                        </RowColumn>
                        <RowColumn>
                            <Column> 
                                <Button type='submit' >Create</Button> 
                                <SaveComplete>{isLoggedIn && isLoggedIn === true ? 'Thanks! Your account has been created.' : null}</SaveComplete>
                                <Error dangerouslySetInnerHTML={{__html: error}} />
                            </Column> 
                        </RowColumn>
                    </form>
                </CustomerDetailsForm>
            </Row>
        }
    </Content>
    );
}