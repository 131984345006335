import { lensPath, view } from 'ramda';
import { useEffect } from 'react';

export function useEditBlogForm(editBlog, isSaving, setValue, setError, register, trigger) {

  const blogLens = (...propPath) => view(lensPath(propPath), editBlog);

  useEffect(() => {
    register({name: 'body'}, {
      required: "Enter blog text",
    });
  }, []);

  useEffect(() => {
    if(editBlog && editBlog.body)
    {
      setValue('body', editBlog.body);
    }
  }, [editBlog]);

  useEffect(() => {

      if(isSaving === false)
      {
        let body = blogLens('body');

        if(body !== undefined && body !== null)
        {
          if(body === '<p><br></p>' || body.replace('<p><br></p>', '') === '' || body === '')
          {
            setError("body", {
              message: "Enter blog text"
            });
          }
          else {
            setValue('body', body);
          }
        }
        else {
          body = '';
          setValue('body', body);
        }
      }
  }, [editBlog, isSaving]);
}
