// @flow
import { useSelector } from 'react-redux';

export function useMyAccount() {

    const authStore = useSelector(state => state.authDetails);

    return {
        loginDetails: authStore.loginDetails, 
    };
}
