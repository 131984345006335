import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/styledPages';
import { get, httpDelete, httpPut, post } from '../../helpers/http';
import { styledPagesUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadStyledPage({payload}) {
  try {
    
    const url = payload === null ? `${styledPagesUrl}` : `${styledPagesUrl}/design/${payload}`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadStyledPageSuccess(response.data));
  } catch (error) {
    yield put(actions.loadStyledPageFailure(GetErrorResponseMessage(error, "styledPages",REQUEST_METHODS.GET)));
  }
}

function* postImageUpload({payload}) {
  try {
    const url = `${styledPagesUrl}/images/${payload.pageId}`;

    const response = yield call(post, { url, data: payload.images });

    yield put(actions.postImageUploadSuccess(response.data));
  } catch (error) {
    yield put(actions.postImageUploadFailure(GetErrorResponseMessage(error, "styledPages-add-styled-page",REQUEST_METHODS.POST)));
  }
}

function* postAddStyledPage({payload}) {
  try {
    const url = `${styledPagesUrl}/add-styled-page`;

    const response = yield call(post, { url, data: { title: payload.title } });

    yield put(actions.postAddStyledPageSuccess(response.data));
  } catch (error) {
    yield put(actions.postAddStyledPageFailure(GetErrorResponseMessage(error, "styledPages-add-styled-page",REQUEST_METHODS.POST)));
  }
}

function* postAddStyledPageItemBlock({payload}) {
    try {
      let url = `${styledPagesUrl}/add-text-block/${payload.pageId}`;
  
      const {id,
        title,
        text,
        orderPostion,
        imageOnLeft,
        blockType,
        isCarosel,
      } = payload;

      let response = null;

      if(blockType === '0')
      {
        url = `${styledPagesUrl}/add-text-block/${payload.pageId}`;

        const images = payload.images === null || payload.images === undefined || payload.images === '' ? null : JSON.parse(payload.images);
        const image = images !== null && images.length > 0 ? images[0] : null;

        response = yield call(httpPut, { url,  data: { 
          id: id === '' ? undefined : id,
          title: title,
          text: text,
          orderPostion: orderPostion,
          imagePositionLeft: imageOnLeft === undefined || imageOnLeft === '' ? 'true' : imageOnLeft,
          image: image,
        }});
      }
      else if(blockType === '1') {

        url = `${styledPagesUrl}/add-picture-block/${payload.pageId}`;

        const images = payload.images === null || payload.images === undefined || payload.images === '' ? null : JSON.parse(payload.images);

        response = yield call(httpPut, { url,  data: { 
          id: id === '' ? undefined : id,
          orderPostion: orderPostion,
          isCarosel: isCarosel,
          images: images,
        }});
      }
      else {
        throw `Unsupported block type '${blockType}'.`;
      }

      yield put(actions.postAddStyledPageItemBlockSuccess(response.data));
    } catch (error) {
      yield put(actions.postAddStyledPageItemBlockFailure(GetErrorResponseMessage(error, "styledPages-add-styled-page-text-block",REQUEST_METHODS.PUT)));
    }
}

function* deleteStyledPageItemBlock({payload}) {
  try {
    const url = `${styledPagesUrl}/delete-block/${payload.blockType}/${payload.pageId}/${payload.id}`;

    const response = yield call(httpDelete, { url });

    yield put(actions.deleteStyledPageItemBlockSuccess(response.data));
  } catch (error) {
    yield put(actions.deleteStyledPageItemBlockFailure(GetErrorResponseMessage(error, "styledPages-delete-styled-page-text-block",REQUEST_METHODS.DELETE)));
  }
}
 
function* postSaveItemBlockOrder({payload}) {
  try {
    const url = `${styledPagesUrl}/item-block-order/${payload.id}`;

    const itemBlocks = payload.itemBlocks.map(x => { return { id: x.id, 
      orderPostion: x.orderPostion, 
      blockType: x.blockType 
    }});

    const response = yield call(post, { url,  data: { 
      id: payload.id,
      title: payload.title,
      itemBlocks,
      textBlocks: payload.textBlocks,
      pictureBlocks: payload.pictureBlocks,
     } });

    yield put(actions.postSaveItemBlockOrderSuccess(response.data));
  } catch (error) {
    yield put(actions.postSaveItemBlockOrderFailure(GetErrorResponseMessage(error, "styledPages-post-save-order-changes",REQUEST_METHODS.POST)));
  }
}

function* postPublishStyledPage({payload}) {
  try {
    const url = `${styledPagesUrl}/publish/${payload.id}`;

    const response = yield call(post, { url });

    yield put(actions.postPublishStyledPageSuccess(response.data));
  } catch (error) {
    yield put(actions.postPublishStyledPageFailure(GetErrorResponseMessage(error, "styledPages-post-publish-styled-page",REQUEST_METHODS.POST)));
  }
}

export default function* styledPageSaga() {
  yield takeLatest(constants.loadStyledPage, loadStyledPage);
  yield takeLatest(constants.postAddStyledPage, postAddStyledPage);
  yield takeLatest(constants.postImageUpload, postImageUpload);
  yield takeLatest(constants.postAddStyledPageItemBlock, postAddStyledPageItemBlock);
  yield takeLatest(constants.deleteStyledPageItemBlock, deleteStyledPageItemBlock);
  yield takeLatest(constants.postSaveItemBlockOrder, postSaveItemBlockOrder);
  yield takeLatest(constants.postPublishStyledPage, postPublishStyledPage);
}
