// @flow
import React from 'react';
import styled from 'styled-components';
import ViewCustomerDetails from '../../components/accounts/ViewCustomerDetails';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;  
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;

    @media (max-width: 799px) {
        width: 100%;
    }
`;

export default function(props) {
    return(
            <Container>
                <Header></Header>
                <Content>
                    <ViewCustomerDetails/>
                </Content>
            </Container> 
        );
}