// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { default as NumberFormat } from 'react-number-format';
import ROUTES from '../../constants/routes';
import LoadingAnimation from '../common/LoadingAnimation';
import { imagesLocation } from '../../settings';

import DropdownList from '../common/DropdownList';

import '../../styles/Slider/sliderStyle.css';
import Slider from 'rc-slider';

import { useProductSearch } from './useProductSearch';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    flex-wrap: wrap;
    position:relative;
    width: 100%;
`;

const Products = styled.div`
    display: flex;
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
    position:relative;
    width: 100%;
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const Price = styled.div`
    display: flex;
    justify-content: center;
    font-weight: bold;
    width: 100%;
`;

const ProductContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ffffff;
    &:hover {
        border: 1px solid #adadad;
    };
    margin: 5px;
    cursor: pointer;
    height:90%;
    color: #000000;

    @media (max-width: 799px) {
        width: 150px;
    }

    @media (min-width: 800px) {
        width: 150px;
    }

    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 1024px) {
        width: 300px;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
    
    margin: 5px;
    height:100%;

    @media (max-width: 799px) {
        width: 150px;
    }

    @media (min-width: 800px) {
        width: 150px;
    }

    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 1024px) {
        width: 300px;
    }
`;

const StyledLink = styled(Link)`
    align-items: stretch;
    text-decoration: none;
`;

const StyledImage = styled.div`
    align-items: stretch;
`;

const ProductImageContainer = styled.div`
`;

const TextContainer = styled.div`
`;

const PageUp = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: 0;
    background-color: #ffffff;
    font-size: 1em;
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const PageDown = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: 0;
    background-color: #ffffff;
    font-size: 1em;
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space:nowrap;
`;

const PaginationButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
`;

const PaginationButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px 0px 19px -12px rgba(217,217,217,1);
    justify-content: space-between;
    padding: 10px 10px 20px 10px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const PageSize = styled.div`
    display: flex;
    flex-direction: row;
    align-content: end;
    width: auto;
`;

const FilterItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const SliderContainer= styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 370px;
`;

const RightArrow = styled.div`
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin: 5px;
`;

const LeftArrow = styled.div`
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 5px;
`;

const SoldOut = styled.div`
    color: red;
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

function getProduct(product: object) {

    let img = '';
    try {
        img = product.images && `${imagesLocation}products/${product.images.filter(x => x.order === 0)[0].path}`;
    } catch (error) {
        img = ``;
    }

    return product && product.hasOwnProperty('name') ? (
        <StyledLink to={`${ROUTES.PRODUCT}/${product.name}`}>
            <ProductContainer>
                <ProductImageContainer>
                    {product.images && product.images[0] ?
                        <img key={img} src={img} title={product.name} alt={product.name} width='100%' /> :
                        <></>
                    }
                </ProductImageContainer>
                <TextContainer>
                    <Title>{product.name}</Title>
                <Price>{product.quantity > 0 ? <NumberFormat value={product.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /> : <SoldOut>Sold out</SoldOut>}</Price>
                </TextContainer>
            </ProductContainer>
        </StyledLink>) :
        ( 
        <StyledImage>
            <ImageContainer>
                <ImageContainer>
                    <img key={product.path} src={`${imagesLocation}products/${product.path}`} alt={product.name} width='100%' />
                </ImageContainer>
            </ImageContainer>
        </StyledImage>
        );
}

export default function({props, location}) {
    
    let {
        searchText, 
        brand, 
        category, 
        subCategory, 
        subSubCategory,
        hideIfNoProducts,
    } = props;
    
    if(props?.match?.params !== undefined) {
        searchText = props.match.params?.searchText;
        brand = props.match.params?.brand;
        category = props.match.params?.category;
        subCategory = props.match.params?.subCategory;
        subSubCategory = props.match.params?.subSubCategory;
        hideIfNoProducts = false;
    }

    const defaultProps = {
        searchText, 
        brand, 
        category, 
        subCategory, 
        subSubCategory,
        location: location,
        hideIfNoProducts: true,
      };

    const {
        products,
        isLoading,
        filters,
        productFilters,
        updateFilters,
        totalPages,
        error,
    } = useProductSearch(defaultProps);


    const pageUp = () => { 
        const page = (filters.page + 1) < totalPages ? filters.page + 1 : filters.page;
        updateFilters({...filters,
            page: page
        });
    }

    const pageDown = () => { 
        const page = (filters.page - 1) <= 0 ? 0 : filters.page - 1
        updateFilters({...filters,
            page: page
        });
    }

    const priceRangeChange = (e) => { 

        if(e[0] <= e[1])
        {
            updateFilters({...filters,
                minPrice: e[0],
                maxPrice: e[1],
                page: 0
            });
        }
        else
        {
            updateFilters({...filters,
                minPrice: e[1],
                maxPrice: e[0],
                page: 0
            });
        }
    }

    return isLoading === true || products === undefined 
        ? (<Container><Loading><LoadingAnimation /></Loading></Container>) 
        : error && error.message 
            ? (<Container><Error>{error.message}</Error></Container>) 
            : filters.hideIfNoProducts && products && products.length === 0
                ? null
                : (<Container>
                        <PaginationContainer>
                            <PaginationButtonsContainer>
                                <PaginationButtons>
                                    <PageDown disabled={(filters.page === 0)} onClick={pageDown} ><LeftArrow /> {'Back'} &nbsp;</PageDown>
                                    <PageUp disabled={(filters.page + 1 === totalPages)} onClick={pageUp}>&nbsp; {'Next'} <RightArrow /></PageUp>
                                    &nbsp;{`page ${filters.page + 1} of ${totalPages == null ? (filters.page + 1):totalPages}`}
                                </PaginationButtons>
                                <PageSize>
                                    <DropdownList 
                                        name="pageSize" 
                                        value={filters.pageSize}
                                        options={['12', '20', '40', '80']}
                                        onChange={(keyValue, values)  => {
                                        updateFilters({
                                            ...filters,
                                            pageSize: values.value,
                                            page: 0,
                                        });
                                        }}
                                    /> 
                                    &nbsp;per page
                                </PageSize> 
                            </PaginationButtonsContainer>  
                            { productFilters && productFilters.minPrice ?
                                <FiltersContainer>
                                    <FilterItemContainer>
                                        <>Price range</>
                                        <SliderContainer>
                                        £{productFilters.minPrice.error}&nbsp;&nbsp;
                                        <Range onAfterChange={priceRangeChange} max={productFilters.maxPrice} min={productFilters.minPrice} defaultValue={
                                            [filters.maxPrice === null ? productFilters.maxPrice : filters.maxPrice, 
                                            filters.minPrice === null ? productFilters.minPrice : filters.minPrice, ]} 
                                            tipFormatter={value => `£${value}`} /> 
                                        &nbsp;&nbsp;£{productFilters.maxPrice}
                                        </SliderContainer>
                                    </FilterItemContainer>
                                    <FilterItemContainer>
                                        <>Sort By</>
                                        <DropdownList 
                                            name="orderBy"
                                            value={productFilters.sortBy}
                                            options={productFilters.sortByOptions.map(option => option)}
                                            onChange={(keyValue, values)  => {
                                            updateFilters({
                                                ...filters,
                                                sortBy: values.value,
                                            });
                                        }}
                                        /> 
                                    </FilterItemContainer>
                                </FiltersContainer> :
                                <FiltersContainer />
                            }      
                        </PaginationContainer>
                        <Products>
                            {products && products.map((product, images) => getProduct(product))}
                        </Products>
                        <PaginationContainer>
                            <PaginationButtonsContainer>
                                <PaginationButtons>
                                    <PageDown disabled={(filters.page === 0)} onClick={pageDown} ><LeftArrow /> {'Back'} &nbsp;</PageDown>
                                    <PageUp disabled={(filters.page + 1 === totalPages)} onClick={pageUp}>&nbsp; {'Next'} <RightArrow /></PageUp>
                                    &nbsp;{`page ${filters.page + 1} of ${totalPages == null ? (filters.page + 1):totalPages}`}
                                </PaginationButtons>
                            </PaginationButtonsContainer>  
                        </PaginationContainer>
                </Container>)
}