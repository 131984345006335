// @flow
import { constants } from '../actions/customerOrders';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  orders: object,
};

const initialState: State = { 
  orders: null,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadCustomerOrdersSearch:
      return { ...state, orders: null, isLoading: true };

    case constants.loadCustomerOrdersSearchSuccess: {
      return {
        ...state,
          orders: action.payload.orders,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadCustomerOrdersSearchFailure:
      return { ...state, error: action.error, orders: null, isLoading: false };
    default:
      return state;
    }
}
