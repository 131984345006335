// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';

import { useMenuDetails } from '../../components/menu/useMenuDetails';

const productsUrl = `/Products`;
const baseBrandUrl = `/Brands`;

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 200;
    flex-direction: row;
    position:fixed;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .5s ease-in-out;
    ${props => props.expanded ? 'background-color: rgba(0, 0, 0, 0.3);' : null}
    ${props => props.expanded ? 'width:100%;' : 'width:0;'}
    top: 0;
    left: 0;
`;

const MenuContainer = styled.div`
    display: flex;
    z-index: 200;
    font-size: 1.3em;
    background-color: #ffffff;
    width: auto;
    max-width: 0px;
    transition: all .8s ease-in-out;

    ${props => props.expanded ? 'max-width: 500px;' : null}
    
    overflow: hidden;
    flex-direction: column;
    align-self: flex-start;

    border-right: solid 1px #000;
    height: 100%;
    padding-top: 50px;
`;

const BlankContainer = styled.div`
    display: flex;
    flex-grow: 1;
`;

const MenuIconContainer = styled.div`
    cursor: pointer;
    width: 20px;
    padding-top: 15px;
    padding-left: 10px;

    position: fixed;
    top: 5px;
    left: 10px;
    z-index: 200;
`;

const MenuIconBar = styled.div`
    background-color: #5e5e5e;
    margin: 2px 0;

    ${props => props.expanded ? 'width:0' : 'width: 20px'};
    ${props => props.expanded ? 'height:0' : 'height: 3px'};
    ${props => props.expanded ? '' : 'overflow:hidden'};
`;

const MenuIconClose = styled.div`
    ${props => props.expanded ? 'width:20px' : 'width: 0'};
    ${props => props.expanded ? 'height:20px' : 'height: 0'};
    ${props => props.expanded ? '' : 'overflow:hidden'};
`;

const ExLeft = styled.div`
    height: 20px;
    width: 3px;
    margin-left: 12px;
    background-color: #5e5e5e;
    transform: rotate(45deg);
`;

const ExRight = styled.div`
    height: 20px;
    width: 3px;
    background-color: #5e5e5e;
    transform: rotate(90deg);
`;

const SubMenuContainer = styled.div` 
    display: flex;
    flex-direction: column; 
    
    background-color: #ffffff;
    border: 0px solid #ffffff;
    transition: all .4s ease-out 0s;
    width: auto;
    height: auto;
    padding-left: 10px;
    max-width: 0;
    ${props => props.selected ? 'max-width:5000px;' : 'max-width:0px;'}
    ${props => props.selected ? 'height: auto;' : 'height:0px;'}
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
`;

const StyledSubLink = styled(Link)`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #3d3d3d;
    &:hover {
        border-bottom: 1px solid #5e5e5e;
    };
    ${props => props.selected ? 'font-weight: bold;' : null};
    ${props => props.selected ? 'border-bottom: 1px solid #5e5e5e' : 'border-bottom: 1px solid #ffffff'};
`;

const SubLinkContainer = styled.div`
    width: min-content;
    overflow: hidden;
    padding-bottom: 0px;
`;

const SubMenuLinksContainer = styled.div`
    width: auto;
    max-width: 0;
    height: auto;
    max-height: 0;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
`;

const RowLinkContainer = styled.div`
    display: flex;
    flex-direction: column; 
    overflow: hidden;
    margin: 5px 5px 0 10px;

    padding-left: 30px;

    ${props => props.active ? `{  
        ${SubMenuLinksContainer} {
            max-width: 8000px;
            max-height: 8000px;
            padding-left: 30px;
            padding-right: 10px;
            transition: all .4s ease-in-out 0s;
        };
    };` : null};
`;

const CategoryTitle = styled.div`
    display: flex;
    white-space: nowrap;
    margin: 5px 5px 0 10px;
    justify-content: flex-start;
    
    ${props => props.selected ? 'color: #000000' : 'color: #5e5e5e'};
    ${props => props.selected ? 'font-weight: bold' : 'font-weight: normal'};
    ${props => props.selected ? 'border-bottom: 1px solid #5e5e5e' : 'border-bottom: 1px solid #ffffff'};

    &:hover {
        border-bottom: 1px solid #5e5e5e;
    };
`;

const Title = styled.div`
    white-space: nowrap;
    border-bottom: 1px solid #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
`;

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: min-content;
    
    ${props => props.active ? `{       
        ${SubMenuContainer} {
            max-height: 800px;
            z-index: 201;
            transition: all .4s ease-out 0s;
        };
    };` : null };
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3d3d3d;
`;

const CategoryLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 120px;
`;

const RightArrow = styled.div`
    display:flex;
    height: 6px;
    width: 6px;
    border: solid #5e5e5e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    ${ props => props.marginTop ? `margin-top: ${props.marginTop};` : null }
    margin-right: 10px;
    margin-left: 5px;
`;

type Props = {
    pathname: String;
}

function makeBrands(brand: object, idx: Number, categoryName: string, menuItemClick: function, pathname: string) {
    const to = `${baseBrandUrl}/${categoryName}/${brand.name}`;
    const selected = pathname.includes(`${baseBrandUrl}/${categoryName}/${brand.name}`);
    return(
        <Title>
            <StyledSubLink to={to || ''} key={idx}  selected={selected} onClick={() => {menuItemClick(to, false);}}>
                {brand.name}
            </StyledSubLink> 
        </Title>
        );
}

function makeSubCategory(subCategories: Object, idx: Number, categoryName: string, menuItem: Object, menuItemClick: function, pathname:string) {
    const to = `${productsUrl}/${categoryName}/${subCategories.name}`;
    const subSubCategories = subCategories.subSubCategory.map((subSubCategory, subSubCategoriesIdx, subCategoryName) => 
                                    makeSubSubCategory(subSubCategory, subSubCategoriesIdx, subCategories.name, categoryName, menuItem, menuItemClick, pathname));

    const selected = pathname.includes(`${productsUrl}/${categoryName}/${subCategories.name}`);

    const hasSubItems = subSubCategories && subSubCategories.length > 0;

    return(
        <RowLinkContainer active={to === menuItem} key={idx}>
            <CategoryLinkContainer>
                <Title>
                    <StyledSubLink  to={to || ''} key={idx} selected={selected} onClick={() => {menuItemClick(to, hasSubItems);}}>
                        {subCategories.name} 
                    </StyledSubLink>
                    
                    {hasSubItems ? <RightArrow marginTop={'13px'}/> : null}
                </Title>
                
            </CategoryLinkContainer>

            {
                subSubCategories && subSubCategories.length > 0 ? 
                <SubMenuLinksContainer>
                    {subSubCategories}
                </SubMenuLinksContainer> : <></>
            }

        </RowLinkContainer>
    );
}

function makeSubSubCategory(subSubCategory: object, idx: Number, subCategoryName: string, categoryName: string, menuItem: Object, menuItemClick: function, pathname:string) {
    const to = `${productsUrl}/${categoryName}/${subCategoryName}/${subSubCategory.name}`;
    const selected = pathname.includes(`${productsUrl}/${categoryName}/${subCategoryName}/${subSubCategory.name}`);

    return( 
        <Title>
            <StyledSubLink to={to || ''} key={idx} selected={selected} onClick={() => {menuItemClick(to, false);}} >
                {subSubCategory.name}
            </StyledSubLink>
        </Title>);
}

function makeList(category: Object, idx: Number, pathname: string, menuItem: Object, menuItemClick: function) {
    const to = `/${category.category}`;
    const selected = pathname.includes(category.category);

    const toBrand = `${baseBrandUrl}/${category.category}`;
    const brandSelected = pathname.includes(`${baseBrandUrl}/${category.category}`);

    const brands =  category.bands && category.bands.map((brands, brandIdx, categoryName) => 
            makeBrands(brands, brandIdx, category.category, menuItemClick, pathname));
    
    const subCategories =  category.subCategories && category.subCategories.map((subCategories, categoryXid, categoryName)  => 
            makeSubCategory(subCategories, categoryXid, category.category, menuItem, menuItemClick, pathname));

    return(
        <CategoryContainer active={selected} key={idx} > 
            
            <StyledLink to={to} key={idx} onClick={() => {menuItemClick(to, true);}}>
                <CategoryLinkContainer>
                    <CategoryTitle selected={selected}>
                        {category.category} 
                    </CategoryTitle>
                    <RightArrow marginTop={'18px'} />
                    
                </CategoryLinkContainer>
            </StyledLink>
            
            <SubMenuContainer selected={selected}>
                <RowLinkContainer active={toBrand === menuItem} key={category.category}>
                    <CategoryLinkContainer>
                        <Title>
                            <StyledSubLink selected={brandSelected} to={toBrand} key={idx} onClick={() => {menuItemClick(toBrand, true);}}>
                                Collections 
                            </StyledSubLink>
                            <RightArrow marginTop={'13px'} /> 
                        </Title>
                    </CategoryLinkContainer>
                    <SubMenuLinksContainer>                              
                        {brands}
                    </SubMenuLinksContainer>
                </RowLinkContainer>
                {subCategories}
            </SubMenuContainer>
        </CategoryContainer>
    );
}

export default function(props: Props) {

    const {
        categories,
        isLoading,
        toggleMenuExpandedState,
        menuToggleExpanded,
        menuItem,
        menuItemClick,
    } = useMenuDetails();

    const { pathname } = props;

    return (
        <Container expanded={menuToggleExpanded}>
            <MenuContainer expanded={menuToggleExpanded}>
                <MenuIconContainer onClick={toggleMenuExpandedState}>
                    <MenuIconBar expanded={menuToggleExpanded} />
                    <MenuIconBar expanded={menuToggleExpanded} />
                    <MenuIconBar expanded={menuToggleExpanded} />
                    <MenuIconClose expanded={menuToggleExpanded}><ExLeft><ExRight /></ExLeft></MenuIconClose>
                </MenuIconContainer>

                <SubLinkContainer>
                    <StyledLink to={'/'} key={`/home`} onClick={() => {menuItemClick(`/home`, false);}}>
                        <CategoryTitle selected={pathname === '/'}>
                            Home
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>         
                {/* <SubLinkContainer>
                    <StyledLink to={ROUTES.BLOG} key={ROUTES.BLOG} onClick={() => {menuItemClick(ROUTES.BLOG, false);}}>
                        <CategoryTitle selected={pathname === ROUTES.BLOG}>
                            Blogs
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>         */}
                
                { isLoading === true ? <></> : categories && categories.map(( category, idx ) => makeList(category, idx, pathname, menuItem, menuItemClick))}

                <SubLinkContainer>
                    <StyledLink to={ROUTES.ABOUT} key={ROUTES.ABOUT} onClick={() => {menuItemClick(ROUTES.ABOUT, false);}}>
                        <CategoryTitle selected={pathname === ROUTES.ABOUT}>
                        Our Story
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>  
                <SubLinkContainer>
                    <StyledLink to={ROUTES.CONTACT} key={ROUTES.CONTACT} onClick={() => {menuItemClick(ROUTES.CONTACT, false);}}>
                        <CategoryTitle selected={pathname === ROUTES.CONTACT}>
                        Contact Us
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>  
                <SubLinkContainer>
                    <StyledLink to={ROUTES.TERMS} key={ROUTES.TERMS} onClick={() => {menuItemClick(ROUTES.TERMS, false);}}>
                        <CategoryTitle selected={pathname === ROUTES.TERMS}>
                        T&Cs
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>  
                <SubLinkContainer>
                    <StyledLink to={ROUTES.PRIVACY_POLICY} key={ROUTES.PRIVACY_POLICY} onClick={() => {menuItemClick(ROUTES.PRIVACY_POLICY, false);}}>
                        <CategoryTitle selected={pathname === ROUTES.PRIVACY_POLICY}>
                        Privacy Policy
                        </CategoryTitle>
                    </StyledLink>
                </SubLinkContainer>  
            </MenuContainer>
            <BlankContainer onClick={toggleMenuExpandedState}>
            </BlankContainer>
        </Container>)
}