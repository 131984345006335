// @flow
import React from "react";
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import { ScrollToTop } from '../../helpers/scrollToTop';
import DragableList from './DragableList';
import TextEditor from '../common/TextEditor';
import InputText from '../common/Input';
import ImageUploader from '../common/ImageUploader';
import { useEditStyledPagesForm } from './useEditStyledPagesForm';
import { imagesLocation } from '../../settings';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const ColumnNoMargin = styled.div`
    padding:10px;
    width: 50%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    padding:10px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: 98%;
    }
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 5px;
    ${props => props.disabled ? 'background-color:#9e9e9e;' : `background-color:${props.color};`}
    ${props => props.disabled ? 'disabled:"disabled";' : null}
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const Error = styled.div`
    color: #a82700;
`;

const Complete = styled.div`
    color: green;
`;

const StyledPageContainer = styled.div`
    display: flex;
    margin: 5px;
    position: relative;
    text-decoration: none;
    flex-direction: column;
    border: 1px solid #adadad;
    &:hover {
        border: 1px solid #5e5e5e;
    };
    a:visited  {
        color: #000000;
    };
    cursor: pointer;
    color: #000000;
    height: min-content;

    width: 200px;

    @media (min-width: 200px) {
        width: 48%;
    }

    @media (min-width: 550px) {
        width: 48%;
    }

    @media (min-width: 780px) {
        width: 30%;
    }    
    
    @media (min-width: 800px) {
        width: 30%;
    }

    @media (min-width: 1024px) {
        width: 32%;
    }
`;

const StyledLink = styled.div`
    color: #000000;
    padding: 10px;
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const StyledPages = styled.div`
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    width:100%;
`;

const TextBlockInputContaner = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 100%;
    display:none;
    top:0;
    left:0;
    z-index: 1000;
    padding-top: 10px;
    color: white;
    background-color: rgba(61, 61, 61, 0.8);
    overflow-y: scroll;
`;

const TextBlockInputControls = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 50%;

    background-color: #fafafa;
    padding: 10px;
    color: #5e5e5e;
    border: solid rgba(168,39,0,0.9);

    @media (max-width: 799px) {
        width: 90%;
    }
`;

const PictureBlockInputContaner = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 100%;
    display:none;
    top:0;
    left:0;
    z-index: 1000;
    padding-top: 10px;
    color: white;
    background-color: rgba(61, 61, 61, 0.8);
    overflow-y:scroll;
`;

const PictureBlockInputControls = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: auto;
    background-color: #fafafa;
    padding: 10px;
    color: #5e5e5e;
    border: solid rgba(168,39,0,0.9);

    @media (max-width: 799px) {
        width: 90%;
    }
`;

const ItemsBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px #5e5e5e;
`;

const RadioOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

const RadioOptionItem = styled.div`
    display: inline;
`;

const TestPageLink = styled(Link)`
    text-decoration: none;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export default function(props) {

const isSaving = props.styledPage.isSaving;
const styledPages = props.styledPage.styledPage.styledPages;
const styledPage = props.styledPage.styledPage.styledPage;
const publishStyledPage = props.styledPage.publishStyledPage;
const uploadImages = props.styledPage.uploadImages;
const updateImages = props.styledPage.updateImages;
const isUploading = props.styledPage.isUploading;
const saveMessage = props.styledPage.saveMessage;
const errorMessage = props.styledPage.error && props.styledPage.error.message;
const removeItemBlockSubmitDispatch = props.styledPage.removeItemBlockSubmit;
const setStyledPageToEdit = props.styledPage.setStyledPageToEdit;

const moveDragableItem = props.styledPage.moveDragableItem;

const addItemBlock = props.styledPage.addItemBlock;
const addStyledPage = props.styledPage.addStyledPage;

const lastUploadedImages = props.styledPage.lastUploadedImages;
const resetItemBlockForm = props.styledPage.resetItemBlockForm;
const resetTextEditor = props.styledPage.resetTextEditor;

const { handleSubmit: textBlockHandleSubmit, setError: textBlockSetError, register: textBlockRegister, triggerValidation: textBlockValidate, errors: textBlockErrors, setValue: textBlockSetValue, getValues: textBlockGetValues} = useForm();
const { handleSubmit: pictureBlockHandleSubmit, register: pictureBlockRegister, triggerValidation: pictureBlockValidate, errors: pictureBlockErrors, setValue: pictureBlockSetValue, getValues: pictureBlockGetValues} = useForm();
const { handleSubmit: newStyledPageHandleSubmit, register: newStyledPageRegister, errors: newStyledPageErrors, setValue: newStyledPageSetValue} = useForm();

useEditStyledPagesForm(styledPage, isSaving, textBlockSetValue, textBlockSetError, textBlockRegister, textBlockValidate);

const addTextBlockSubmit = form =>
{ 
    addItemBlock(form);
    resetForms();
}

const addPictureBlockSubmit = form =>
{ 
    addItemBlock(form);
    resetForms();
}

const removeItemBlockSubmit = ({pageId, id, blockType}) =>
{ 
    removeItemBlockSubmitDispatch(pageId, id, blockType);
    resetForms();
}

const resetForms = () =>
{ 
    resetItemBlockForm();
    textBlockSetValue('imageOnLeft', undefined);
    textBlockSetValue('title', '');
    textBlockSetValue('text', '');
    textBlockSetValue('images', '');
    textBlockSetValue('orderPostion', '');

    pictureBlockSetValue('isCarosel', '');
    pictureBlockSetValue('images', '');
    pictureBlockSetValue('orderPostion', '');
}

function submitImageUpload(form, images, files) {

    const itemForm = {
        id: textBlockGetValues("pageId") ?? pictureBlockGetValues("pageId"), 
        title: textBlockGetValues("title"), 
        text: textBlockGetValues("text"),
        imageOnLeft: textBlockGetValues("imageOnLeft"),
        isCarosel: pictureBlockSetValue("isCarosel"),
        blockType: textBlockGetValues("text") !== '' && textBlockGetValues("text") !== '' ? textBlockGetValues("blockType") : pictureBlockGetValues("blockType"),
        orderPostion: textBlockGetValues("text") !== '' && textBlockGetValues("text") !== '' ? textBlockGetValues("orderPostion") : pictureBlockGetValues("orderPostion"),
    };

    uploadImages(form, itemForm, images, files);
}

const editStyledPage = (styledPage) => {
    return (
    <>
        <Column>
            <Buttons>
                <Button color={'#696969'} disabled={isSaving} onClick={(e) => {document.getElementById("textBlockInputContaner").style.display = 'block'; e.preventDefault(); return false;}}>{'New Text Block'}</Button>
                <Button color={'#696969'} disabled={isSaving} onClick={(e) => {document.getElementById("pictureBlockInputContaner").style.display = 'block'; e.preventDefault(); return false;}}>{'New Picture Block'}</Button>
                <Button color={styledPage.published === true ? '#4dbf4d' : '#b51616'} disabled={isSaving} onClick={(e) => {publishStyledPage(); e.preventDefault(); return false;}}>{'Publish'}</Button>
                <TestPageLink target='_blank' to={`${ROUTES.ADMIN_TEST_STYLED_PAGES}/${styledPage.id}`}>
                    <Button color={'#4dbf4d'} disabled={isSaving}>
                        {'View Page'} 
                    </Button> 
                </TestPageLink>
            </Buttons>
            <Error>{errorMessage}</Error>
            <InputText 
                name='title'
                keyValue='title'
                onChange={(keyValue, value) => {
                }} 
                label="Page" 
                value={styledPage && styledPage.title }
                ref={newStyledPageRegister}
                error={newStyledPageErrors.title} 
                required
                disabled 
            />
        
            <ItemsBlockContainer>
                {styledPage && styledPage.itemBlocks && styledPage.itemBlocks.length > 0 ? 
                <DragableList itemContents={styledPage.itemBlocks} 
                         moveItems={moveDragableItem} 
                         removeItem={removeItemBlockSubmit}
                         pageId={styledPage.id}
                         baseImagesLocation={`${imagesLocation}styled-pages/design/`} />
                : 
                null}
            </ItemsBlockContainer>

            <PictureBlockInputContaner id='pictureBlockInputContaner'>
                <form width='100%' onSubmit={pictureBlockHandleSubmit(addPictureBlockSubmit)}>
                    <PictureBlockInputControls>
                        <Row>
                            Add a new image block
                        </Row>
                        <Buttons>
                            <Button color={'#363636'} disabled={isSaving} onClick={async(e) => {
                                const result = await pictureBlockValidate();
                                document.getElementById("pictureBlockInputContaner").style.display = result === true ? 'none' : 'block'; 
                                }}>{isSaving ? 'Saving' : 'Save'}</Button>
                            <Button color={'#363636'} disabled={isSaving} onClick={(e) => {resetForms(); document.getElementById("pictureBlockInputContaner").style.display = 'none'; e.preventDefault(); return false;}}>{'Cancel'}</Button>
                        </Buttons>
                       
                        <InputText 
                            name='blockType'
                            keyValue='blockType'
                            value={1}
                            onChange={(keyValue, value) => {}} 
                            type="hidden" 
                            ref={pictureBlockRegister}
                        />

                        <InputText 
                            name='pageId'
                            keyValue='pageId'
                            value={styledPage && styledPage.id}
                            onChange={(keyValue, value) => {
                                pictureBlockSetValue('pageId', styledPage.id);
                            }} 
                            type="hidden" 
                            ref={pictureBlockRegister}
                        />
                            
                        <InputText 
                            name='orderPostion'
                            keyValue='orderPostion'
                            value={styledPage && styledPage.itemBlocks !== undefined && styledPage.itemBlocks ? styledPage.itemBlocks.length : 0}
                            onChange={(keyValue, value) => {
                                pictureBlockSetValue('orderPostion', value);
                            }} 
                            type="hidden" 
                            ref={pictureBlockRegister}
                        />
                        
                        <RadioOptionContainer>
                            <label>Images are part of carosel</label><br />
                            <RadioOptionItem>
                                <input type="radio" 
                                    id="true" 
                                    name="isCarosel" 
                                    value="true" 
                                    onChange={event => {
                                        pictureBlockSetValue('isCarosel', event.target.value);
                                    }}
                                    ref={pictureBlockRegister}>
                                </input>
                                <label>Carosel</label>
                            </RadioOptionItem>

                            <RadioOptionItem>
                                <input type="radio" 
                                    id="false" 
                                    name="isCarosel" 
                                    value="false" 
                                    onChange={event => {
                                        pictureBlockSetValue('isCarosel', event.target.value);
                                    }}
                                    ref={pictureBlockRegister({
                                        required: 'Select an option.',
                                    })}>
                                </input>
                                <label>Block of Images</label></RadioOptionItem>
                        </RadioOptionContainer>
                        <Error>{pictureBlockErrors.isCarosel && pictureBlockErrors.isCarosel.message}</Error>
                       
                        <InputText 
                            name='images'
                            keyValue='images'
                            value={lastUploadedImages && lastUploadedImages.images ? JSON.stringify(lastUploadedImages.images) : null}
                            onChange={(keyValue, value) => {
                            }} 
                            type="hidden" 
                            ref={pictureBlockRegister({
                                required: 'Select an image.',
                            })}
                        />
                        <Error>{pictureBlockErrors.images && pictureBlockErrors.images.message}</Error>
                        <Complete>{saveMessage}</Complete>
                        <Error>{isUploading === true ? 'UPLOIADING' : null}</Error>
                        <ImageUploader 
                            name='imageUpload'
                            keyValue='imageUpload'
                            value={lastUploadedImages && lastUploadedImages.images && lastUploadedImages.images.map((image) => image.path)} 
                            onSubmit={submitImageUpload}
                            updateImages={updateImages}
                            label="Upload Images" 
                            baseImagesLocation={`${imagesLocation}styled-pages/design/`}
                            isUploading={isUploading}
                        />
                    </PictureBlockInputControls>
                </form>    
            </PictureBlockInputContaner>

            <TextBlockInputContaner id='textBlockInputContaner'>
                <form width='100%' onSubmit={textBlockHandleSubmit(addTextBlockSubmit)}>
                    <TextBlockInputControls>
                        <Row>
                            Add a new text block
                        </Row>
                        <Buttons>
                            <Button color={'#363636'} disabled={isSaving} onClick={async (e) => {
                                const result = await textBlockValidate(); 
                                document.getElementById("textBlockInputContaner").style.display = result === true ? 'none' : 'block';}}>{isSaving ? 'Saving' : 'Save'}</Button>
                            <Button color={'#363636'} disabled={isSaving} onClick={(e) => {resetForms(); document.getElementById("textBlockInputContaner").style.display = 'none'; e.preventDefault(); return false;}}>{'Cancel'}</Button>
                        </Buttons>

                        <InputText 
                            name='blockType'
                            keyValue='blockType'
                            value={0}
                            onChange={(keyValue, value) => {}} 
                            type="hidden" 
                            ref={textBlockRegister}
                        />
                        
                        <InputText 
                            name='pageId'
                            keyValue='pageId'
                            value={styledPage && styledPage.id}
                            onChange={(keyValue, value) => {
                                textBlockSetValue('pageId', styledPage.id);
                            }} 
                            type="hidden" 
                            ref={textBlockRegister}
                        />
                            
                        <InputText 
                            name='orderPostion'
                            keyValue='orderPostion'
                            value={styledPage && styledPage.itemBlocks !== undefined && styledPage.itemBlocks ? styledPage.itemBlocks.length : 0}
                            onChange={(keyValue, value) => {
                                textBlockSetValue('orderPostion', value);
                            }} 
                            type="hidden" 
                            ref={textBlockRegister}
                        />

                        <InputText 
                            name='title'
                            keyValue='title'
                            onChange={(keyValue, value) => {
                                textBlockSetValue('title', value);
                            }} 
                            label="Title" 
                            ref={textBlockRegister()}
                            error={textBlockErrors.title}
                        />
                        
                        <TextEditor 
                            keyValue='text'
                            name='text'
                            placeholder="Text..."
                            value={lastUploadedImages && lastUploadedImages.text }
                            reset={resetTextEditor}
                            onChange={(keyValue, value) => {
                                textBlockSetValue('text', value);
                                textBlockValidate('text');
                            }}
                            label="Text"
                            error={textBlockErrors.text}
                            required
                        />

                        <RadioOptionContainer>
                            <label>Image in the left or right</label><br />
                            <RadioOptionItem><input type="radio" name="imageOnLeft" 
                                value={'true'}
                                onChange={event => {
                                    textBlockSetValue('imageOnLeft', event.target.value);
                                }}
                                ref={lastUploadedImages && lastUploadedImages.images && lastUploadedImages.images.length > 0 ? textBlockRegister({
                                    required: 'Select an option.',
                                }) : textBlockRegister}
                             >
                                    </input><label>Left</label></RadioOptionItem>
                            <RadioOptionItem><input type="radio" name="imageOnLeft"
                                value={'false'}
                                onChange={event => {
                                    textBlockSetValue('imageOnLeft', event.target.value);
                                }}
                                ref={lastUploadedImages && lastUploadedImages.images && lastUploadedImages.images.length > 0 ? textBlockRegister({
                                    required: 'Select an option.',
                                }) : textBlockRegister}
                            >
                            </input><label>Right</label></RadioOptionItem>
                        </RadioOptionContainer>
                        <Error>{textBlockErrors.imageOnLeft && textBlockErrors.imageOnLeft.message}</Error>
                                                    
                        <InputText 
                            name='images'
                            keyValue='images'
                            value={lastUploadedImages && lastUploadedImages.images ? JSON.stringify(lastUploadedImages.images) : null}
                            onChange={(keyValue, value) => {
                            }} 
                            type="hidden" 
                            ref={textBlockRegister}
                        />

                        <Complete>{saveMessage}</Complete>
                        <Error>{isUploading === true ? 'UPLOIADING' : null}</Error>

                        <ImageUploader 
                            name='imageUpload'
                            keyValue='imageUpload'
                            value={lastUploadedImages && lastUploadedImages.images && lastUploadedImages.images.map((image) => image.path)} 
                            onSubmit={submitImageUpload}
                            updateImages={updateImages}
                            label="Upload Images" 
                            baseImagesLocation={`${imagesLocation}styled-pages/design/`}
                            isUploading={isUploading}
                        />
                    </TextBlockInputControls> 
                </form>
            </TextBlockInputContaner>
        </Column>
    </>);
};

function getStyledPage(styledPage: object) {   
    return (
    <StyledPageContainer onClick={() => {setStyledPageToEdit(styledPage); ScrollToTop();}}>
        <StyledLink>
            <Title>{styledPage.title}</Title>
        </StyledLink>
    </StyledPageContainer>);
}

return(
    <Container>
        <ColumnNoMargin>
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </ColumnNoMargin>
        <Column>
        {
            styledPage === undefined || styledPage === null ? 
            <StyledPages>
                <Column>
                <Row>
                    <form style={{width: '100%'}} onSubmit={newStyledPageHandleSubmit(addStyledPage)}>
                        <Buttons>
                            <Button color={'#696969'} disabled={isSaving} >{'New'}</Button>
                        </Buttons>
                        <InputText 
                                name='title'
                                keyValue='title'
                                onChange={(keyValue, value) => {
                                    newStyledPageSetValue('title', value);
                                }} 
                                label="Page" 
                                ref={newStyledPageRegister({
                                    required: 'Select a title.',
                                })}
                                error={newStyledPageErrors.title} 
                                required
                        />
                    </form> 
                    <Error>{errorMessage}</Error>
                    </Row>
                    <Row>
                        Select a page to edit
                    </Row>
                    <Row>
                        {
                            styledPages && styledPages.length > 0 ? styledPages.map((styledPage) => getStyledPage(styledPage)) : null
                        }
                    </Row>
                </Column>
            </StyledPages> :
            editStyledPage(styledPage)
        }
        </Column>
    </Container>
);
}