export const constants = {
    loadAdminCategories: 'LOAD_ADMIN_CATEGORIES',
    loadAdminCategoriesSuccess: 'LOAD_ADMIN_CATEGORIES_SUCCESS',
    loadAdminCategoriesFailure: 'LOAD_ADMIN_CATEGORIES_FAILURE',

    postAdminUpdateCategories: 'POST_ADMIN_UPDATE_CATEGORIES',
    postAdminUpdateCategoriesSuccess: 'POST_ADMIN_UPDATE_CATEGORIES_SUCCESS',
    postAdminUpdateCategoriesFailure: 'POST_ADMIN_UPDATE_CATEGORIES_FAILURE',

    postCategoryImageUpload: 'POST_UPLOAD_CATEGORY_IMAGE',
    postCategoryImageUploadSuccess: 'POST_UPLOAD_CATEGORY_IMAGE_SUCCESS',
    postCategoryImageUploadFailure: 'POST_UPLOAD_CATEGORY_IMAGE_FAILURE',
};

export const actions = {
    loadAdminCategories: () => ({
        type: constants.loadAdminCategories,
    }),
    loadAdminCategoriesSuccess: payload => ({
        type: constants.loadAdminCategoriesSuccess,
        payload,
    }),
    loadAdminCategoriesFailure: error => ({
        type: constants.loadAdminCategoriesFailure,
        error,
    }), 
    
    postAdminUpdateCategories: payload => ({
        type: constants.postAdminUpdateCategories,
        payload,
    }),
    postAdminUpdateCategoriesSuccess: payload => ({
        type: constants.postAdminUpdateCategoriesSuccess,
        payload,
    }),
    postAdminUpdateCategoriesFailure: error => ({
        type: constants.postAdminUpdateCategoriesFailure,
        error,
    }),
    
    postCategoryImageUpload: payload => ({
        type: constants.postCategoryImageUpload,
        payload,
    }),
    postCategoryImageUploadSuccess: payload => ({
        type: constants.postCategoryImageUploadSuccess,
        payload,
    }),
    postCategoryImageUploadFailure: error => ({
        type: constants.postCategoryImageUploadFailure,
        error,
    }),
};