// @flow
import { type Error } from '../types/error';

const LoginSuccess: 'LOGIN SUCCESS' = 'LOGIN SUCCESS';
type LoginSuccessAction = {| type: typeof LoginSuccess, payload: string |};
const loginSuccess = (loginDetails: Object, redirectTo: string): LoginSuccessAction => ({
  type: LoginSuccess,
  payload: {loginDetails, redirectTo},
});

const LoginFailure: 'LOGIN FAILURE' = 'LOGIN FAILURE';
type LoginFailureAction = {| type: typeof LoginFailure, payload: Object |};
const loginFailure = (error: Error): LoginFailureAction => ({
  type: LoginFailure,
  payload: error,
});

const Login: 'LOGIN' = 'LOGIN';
type LoginAction = {| type: typeof Login |};
const login = (username, password, redirectTo): LoginAction => ({ type: Login, username, password, redirectTo });

const Logout: 'LOGOUT' = 'LOGOUT';
type LogoutAction = {| type: typeof Logout |};
const logout = (): LogoutAction => ({ type: Logout });

const Ready: 'READY' = 'READY';
type ReadyAction = { type: typeof Ready };
const ready = (): ReadyAction => ({ type: Ready });

const postCreateCustomerAccount = ({firstName, lastName, contactNumber, username, password, confirmPassword}) => ({
  type: constants.postCreateCustomerAccount,
  firstName, lastName, contactNumber, username, password, confirmPassword
});
const postCreateCustomerAccountSuccess = (loginDetails: Object, redirectTo: string): LoginSuccessAction => ({
  type: LoginSuccess,
  payload: {loginDetails, redirectTo},
});
const postCreateCustomerAccountFailure = (error: Error): LoginFailureAction => ({
  type: LoginFailure,
  payload: error,
});

export const constants = { 
  LoginSuccess, 
  LoginFailure, 
  Login, 
  Logout, 
  Ready,
  postCreateCustomerAccount: 'POST_CREATECUSTOMER_ACCOUNT',
  postCreateCustomerAccountSuccess: 'POST_CREATECUSTOMER_ACCOUNT_SUCCESS',
  postCreateCustomerAccountFailure: 'POST_CREATECUSTOMER_ACCOUNT_FAILURE',
 };

export const actions = { 
  loginSuccess, 
  loginFailure, 
  login, 
  logout, 
  ready,
  postCreateCustomerAccount,
  postCreateCustomerAccountSuccess,
  postCreateCustomerAccountFailure,
};

export type Action =
  | LoginSuccessAction
  | LoginFailureAction
  | LoginAction
  | LogoutAction
  | ReadyAction;
