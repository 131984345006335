// @flow
import React from 'react';
import styled from 'styled-components';
import { BasketAccessRestrictions } from '../../helpers/SiteRestrictionAccess';
import ROUTES from '../../constants/routes';
import { useBasket } from '../../components/basket/useBasket';
import { useCheckout } from '../../components/basket/useCheckout';
import CheckoutDeliverySelection from '../../components/basket/checkoutDeliverySelection';
import CheckoutUserSelection from '../../components/basket/checkoutUserSelection';
import CheckoutOrderSummary from '../../components/basket/checkoutOrderSummary';
import  { Redirect } from 'react-router-dom';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;  
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    background-color: #fafafa;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

const ColumnRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    background-color: #fafafa;
    width: 100%;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const LoginDisabled = styled.div`
    display: flex;
    flex-direction: row;
    padding:20px ;
    background-color: #fafafa;
    justify-content: center;
    margin: 5px;
`;

export default function() {    
    
    const {
        paymentDetails,
        updatePaymentDetails,
        updatePaymentDetailsFromForm,
        basket,
        resetBasket,
    } = useBasket();

    const { 
        GetTotalPriceAndDelivery,
        RecreateOrderIntent,
        order,
        orderConfirmation,
        ResetOrder,
        stripePromise,
    } = useCheckout(paymentDetails, basket);     
  
    
const { message, isEnabled } = BasketAccessRestrictions();

if(order.intentSecret !== null && order.orderId !== null && order.orderCreated === true && basket.items.length > 0)
{    
    resetBasket();
    ResetOrder();
}

if(orderConfirmation && orderConfirmation.orderId !== null && basket.items.length === 0)
{
    return <Redirect to={{ pathname: ROUTES.ORDER_CONFIRMATION, order: orderConfirmation}} />;
}

return(
    <Container>
        <Header></Header>
        <Content>
            <ColumnRow>
                {
                    isEnabled === false ? 
                        <LoginDisabled dangerouslySetInnerHTML={{__html: message}} /> :
                            basket.items.length > 0 ? 
                            <>
                                {message !== undefined && message !== null && message !== '' ? <LoginDisabled dangerouslySetInnerHTML={{__html: message}} /> : null}

                                <CheckoutUserSelection props={ { paymentDetails, updatePaymentDetails } }/>
                                <CheckoutDeliverySelection props={ { basket, paymentDetails, updatePaymentDetailsFromForm, updatePaymentDetails, RecreateOrderIntent} } />
                                <CheckoutOrderSummary props={ { basket, paymentDetails, order, GetTotalPriceAndDelivery, stripePromise } } />
                            </> : null 
                }
            </ColumnRow>  
        </Content>
      
    </Container>
);
}