// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import { imagesLocation } from '../../settings';
import { ScrollToTop } from '../../helpers/scrollToTop';
import { useEditBlogForm } from './useEditBlogForm';
import TextEditor from '../common/TextEditor';
import InputText from '../common/Input';
import ImageUploader from '../common/ImageUploader';

const Container = styled.div`
    border: 0; 
    height: 100%;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ColumnNoMargin = styled.div`
    padding:10px;
    width: 50%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const Column = styled.div`
    padding:10px;
    margin-bottom:40px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
        width: auto;
    }
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Button = styled.button`
    width: 160px;
    height: 40px;
    color: #ffffff;
    font-size: 0.8em;
    border:none;
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 5px;
    ${props => props.disabled ? 'background-color:#9e9e9e;' : `background-color:${props.color};`}
    ${props => props.disabled ? 'disabled:"disabled";' : null}
    ${props => props.disabled ? 'cursor: default;' : 'cursor: pointer;'}
`;

const Error = styled.div`
    color: #a82700;
`;

const Complete = styled.div`
    color: green;
`;

const BlogContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    position: relative;
    background-color: #fafafa;
    text-decoration: none;
    flex-direction: column;
    border: 1px solid #adadad;
    &:hover {
        border: 1px solid #000000;
    };
    a:visited  {
        color: #000000;
    };
    cursor: pointer;
    color: #000000;
    height: min-content;

    width: 200px;

    @media (min-width: 200px) {
        width: 48%;
    }

    @media (min-width: 550px) {
        width: 48%;
    }

    @media (min-width: 780px) {
        width: 30%;
    }    
    
    @media (min-width: 800px) {
        width: 30%;
    }

    @media (min-width: 1024px) {
        width: 32%;
    }
`;

const StyledLink = styled.div`
    color: #000000;
    padding: 10px;
`;

const BlogImageContainer = styled.div`
`;

const TextContainer = styled.div`
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
`;

const Buttons = styled.div`
display: flex;
flex-wrap:wrap;
flex-direction: row;
width:100%;
`;

export default function(props) {

const isSaving = props.designBlogs.isSaving;
const designBlogs = props.designBlogs.designBlogs;
const updateBlog = props.designBlogs.updateBlog;
const onSubmit = props.designBlogs.onSubmit;
const uploadImages = props.designBlogs.uploadImages;
const newBlogSubmit = props.designBlogs.newBlogSubmit;
const saveMessage = props.designBlogs.saveMessage;
const errorMessage = props.designBlogs.error && props.designBlogs.error.message;
const removeBlog = props.designBlogs.removeBlog;
const publishBlog = props.designBlogs.publishBlog;

const setBlogToEdit = props.designBlogs.setBlogToEdit;
const editBlog = props.designBlogs.editBlog;

const { handleSubmit: blogHandleSubmit, register: blogRegister, errors: blogErrors, setValue: blogSetValue, setError: blogSetError, triggerValidation: blogTriggerValidation } = useForm();
const { handleSubmit: newBlogHandleSubmit, register: newBlogRegister, errors: newBlogErrors, setValue: newBlogSetValue } = useForm();

useEditBlogForm(editBlog, isSaving, blogSetValue, blogSetError, blogRegister);

function submitImageUpload(form, images) {
    updateBlog('images', images, editBlog);

    uploadImages(form); 

    blogSetValue('images', images);
    blogSetValue('body', editBlog.body);
}

function updateImage(images) {
    updateBlog('images', images, editBlog);

    blogSetValue('images', editBlog.images);
    blogSetValue('body', editBlog.body);
}

const submitFrom = (form) => {
    blogSetValue('body', form.body);

    const updatedBlog = {...editBlog,
        body: form.body
    };

    onSubmit(updatedBlog);
};

const submitNewBlogFrom = (form) => {
    newBlogSubmit(form);
};

const resetForm = () => {
    blogSetValue('id', '');
    blogSetValue('dateCreated', '');
    blogSetValue('name', '');
    blogSetValue('body', null);
    blogSetValue('images', []);
    setBlogToEdit(null);
};

const testBlog = (url) => {
    window.open(url,'_blank');
};

const editBlogs = (editBlog) => {

    const textBlogLink = editBlog !== null && editBlog !== undefined && editBlog.name ? `${ROUTES.ADMIN_TEST_BLOG}/${editBlog.id}` : null;
    return(
    <>
        <Column>
            <form onSubmit={blogHandleSubmit(submitFrom)}>
                <Buttons>
                    <Button color={'#696969'} disabled={isSaving}>{isSaving ? 'Saving' : 'Save'}</Button>
                    <Button color={'#b51616'} disabled={isSaving || editBlog == null ? true : false} onClick={(e) => {removeBlog(editBlog); resetForm(); e.preventDefault(); return false;}}>{'Delete'}</Button>
                    <Button color={'#4dbf4d'} disabled={isSaving || editBlog === null || editBlog.published === true ? true : false} onClick={(e) => {publishBlog(editBlog); e.preventDefault(); return false;}}>{'Publish'}</Button>
                    <Button color={'#4dbf4d'} disabled={isSaving || editBlog == null ? true : false} onClick={(e) => {testBlog(textBlogLink); e.preventDefault(); return false;}}>{'View'}</Button>
                    <Button color={'#696969'} disabled={isSaving} onClick={(e) => {setBlogToEdit(null); resetForm(); e.preventDefault(); return false;}}>{'New'}</Button>
                </Buttons>
                <Error>{errorMessage}</Error>
                <Complete>{saveMessage}</Complete>

                <InputText 
                    name='id'
                    keyValue='id'
                    value={editBlog && editBlog.id} 
                    type='hidden'
                    label=""  />

                <InputText 
                    name='dateCreated'
                    keyValue='dateCreated'
                    value={editBlog && editBlog.dateCreated} 
                    type='hidden'
                    label=""  />

                <InputText 
                    name='name'
                    keyValue='name'
                    value={editBlog && editBlog.name} 
                    onChange={(keyValue, value) => {
                        blogSetValue('name', value);}
                    } 
                    label="Name" 
                    ref={blogRegister({
                        required: 'Enter a name.',
                    })}
                    error={blogErrors.name} 
                    disabled
                    required />
                
                <TextEditor 
                    keyValue='body'
                    name='body'
                    placeholder="Text..."
                    value={editBlog && editBlog.body}
                    onChange={(keyValue, value) => {
                            blogSetValue('body', value);
                            blogTriggerValidation('body');
                        }
                    }
                    label="Article"
                    error={blogErrors.body} 
                    required
                />
            </form>
        </Column>
        <Column>
            <ImageUploader 
                name='images'
                keyValue='images'
                value={editBlog && editBlog.images && editBlog.images.map((image) => image.path)} 
                onSubmit={submitImageUpload}
                updateImages={updateImage}
                label="Upload Images" 
                baseImagesLocation={`${imagesLocation}blogs/design/`}
            />
        </Column>
    </>);
};

const newBlog = () => {
    return(
    <>
        <form onSubmit={newBlogHandleSubmit(submitNewBlogFrom)}>
            <Buttons>
                <Button color={'#696969'} disabled={isSaving}>{isSaving ? 'Saving' : 'New'}</Button>
            </Buttons>

            <InputText 
                name='name'
                keyValue='name'
                onChange={(keyValue, value) => {
                    newBlogSetValue('name', value);}
                } 
                label="Name"
                error={newBlogErrors.name} 
                ref={newBlogRegister({
                    required: 'Enter a name.',
                })}
                required />
        </form>
    </>);
};

function getBlog(blog: object) {

    let img = '';
    try {
        img = blog.images && `${imagesLocation}blogs/design/${blog.images.filter(x => x.order === 0)[0].path}`;
    } catch (error) {
        img = ``;
    }
    
    return (
    <BlogContainer onClick={() => {setBlogToEdit(blog); ScrollToTop();}}>
        <StyledLink>
            <BlogImageContainer>
                {blog.images && blog.images[0] ?
                    <img key={img} 
                    src={img} 
                    title={blog.name} 
                    alt={''} 
                    width='100%' /> :
                    <></>
                }
            </BlogImageContainer>
            <TextContainer>
                <Title>{blog.name}</Title>
            </TextContainer>          
        </StyledLink>
    </BlogContainer>);
}

return(
    <Container>
        <ColumnNoMargin>
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </ColumnNoMargin>
        <Column>
            {
                editBlog !== null ? 
                editBlogs(editBlog) : 
                <>
                    <Row>{newBlog()}</Row>
                    <Column>{designBlogs && designBlogs.length > 0 && designBlogs.map((blog) => getBlog(blog))}</Column>
                </>
            }
        </Column>
    </Container>
);
}