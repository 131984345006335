import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/contactUs';
import { post } from '../../helpers/http';
import { contactUsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadContactUsEmail(payload) {

  const {emailAddress, name, subject, message} = payload.message;
  try {
    
    const url = `${contactUsUrl}/contact-us-email`;

    const response = yield call(post, { url, data: { emailAddress, name, subject, message } });

    yield put(actions.loadContactUsEmailSuccess(response.data));
  } catch (error) {
    yield put(actions.loadContactUsEmailFailure(GetErrorResponseMessage(error, "contactUsEmail", REQUEST_METHODS.POST)));
  }
}

export default function* menuSaga() {
  yield takeLatest(constants.loadContactUsEmail, loadContactUsEmail);
}
