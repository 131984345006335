import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/customerDetails';
import { get, httpPut } from '../../helpers/http';
import { accountsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadCustomerDetails() {
  try {
    
    const url = `${accountsUrl}/my-details`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadCustomerDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadCustomerDetailsFailure(GetErrorResponseMessage(error, "customerDetails",REQUEST_METHODS.GET)));
  }
}

function* postUpdateCustomerDetails({payload}) {
  try {
    const url = `${accountsUrl}/my-details`;

    const response = yield call(httpPut, { url,  data: payload });

    yield put(actions.postUpdateCustomerDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.postUpdateCustomerDetailsFailure(GetErrorResponseMessage(error, "customerDetails",REQUEST_METHODS.POST)));
  }
}

export default function* customerDetailsSaga() {
  yield takeLatest(constants.loadCustomerDetails, loadCustomerDetails);
  yield takeLatest(constants.postUpdateCustomerDetails, postUpdateCustomerDetails);
}
