// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as viewOrdersActions } from '../../state/actions/customerOrders';

export function useViewOrders(props) {
  const dispatch = useDispatch();

  const ordersStore = useSelector(state => state.customerOrders);
  const [orders, setOrders ] = useState(ordersStore);
  const [viewOrderPaneVisible, setViewOrderPaneVisible] = useState(false);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    dispatch(viewOrdersActions.loadCustomerOrdersSearch());
  },[]);

  useEffect(() => {
    setOrders(ordersStore);
  },[ordersStore]);

  useEffect(() => {

  },[order]);

  const GetOrderToView = orderId => {
      setOrder(orders.orders.filter(x => x.orderId === orderId)[0]);
      setViewOrderPaneVisible(true);
  };

  const UpdateViewOrderPaneVisible = () => {
    setViewOrderPaneVisible(!viewOrderPaneVisible);
  };

  return {
    error: ordersStore.error,
    orders: ordersStore && ordersStore.orders,
    isLoading: ordersStore.isLoading,
    GetOrderToView,
    viewOrderPaneVisible,
    UpdateViewOrderPaneVisible,
    order
  };
}
