// @flow
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import ArrowLeft from '../common/ArrowLeft';
import ROUTES from '../../constants/routes';
import styled from 'styled-components';
import Input from '../common/Input';
import DropdownList from '../common/DropdownList';
import { useForm } from "react-hook-form";
import { useAdminAccounts } from './useAdminAccounts';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const AccountActionLink = styled(Link)`
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const Button = styled.button`
    padding: 5px;
    min-width: 70px;
    margin: 10px 10px 10px 10px;
    cursor: pointer;

    @media (min-width: 800px) {
        min-width: 100px;
    }
`;

const Error = styled.div`
    color: #a82700;
    font-weight: bold;
    font-size: 0.9em;
`;

export default function(props) {

    const { handleSubmit, register, 
        errors, watch } = useForm();  

        const { newAccountSubmit, newAccountErrorMessage, newAccountCreated } = useAdminAccounts();

    const password = useRef({});
    password.current = watch("password", "");

return(
    <Content>           
        <Column> 
            <AccountActionLink to={ROUTES.ADMIN} ><ArrowLeft /> Back To Site Admin</AccountActionLink>
        </Column>     
        <Column> 
            <form style={{width:'100%'}} onSubmit={handleSubmit(newAccountSubmit)}>
                <p>Create an account for another user. Please set a temporary password and ask the user to use the forgotten password link to change the password.</p>

                <DropdownList 
                    name="accountType" 
                    keyValue='accountType' 
                    label="Account Type" 
                    value={null}
                    options={[
                        'Customer',
                        'Administrator'
                    ]}
                    onChange={(keyValue, value) => {}}
                    error={errors.accountType} 
                    ref={register({
                        required: 'Select an account type.',
                    })}
                    showDefaultValue={false}
                    required />

                <Input
                    label='First Name' 
                    type='text' 
                    placeholder='' 
                    name="firstName"
                    error={errors.firstName}
                    ref={register({
                    required: 'Please enter your first name.',
                    minLength: { value: 3, message: "First name must be longer than 3 characters" },
                    })}
                    onChange={(value) => {}}
                    required
                    />

                <Input
                    label='Last Name' 
                    type='text' 
                    placeholder='' 
                    name="lastName"
                    error={errors.lastName}
                    ref={register({
                    required: 'Please enter your last name.',
                    minLength: { value: 3, message: "Last name must be longer than 3 characters" },
                    })}
                    onChange={(value) => {}}
                    required
                    />

                <Input
                    label='Email Address' 
                    type='text' 
                    placeholder='' 
                    name="username"
                    error={errors.username}
                    ref={register({
                    required: 'Please enter your email address.',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address"
                    }})}
                    onChange={(value) => {}}
                    required
                    />

                <Input
                    label='Contact number' 
                    type='text' 
                    placeholder='' 
                    name="contactNumber"
                    error={errors.contactNumber}
                    ref={register({
                        required: 'Please enter your contact number.',
                        minLength: { value: 11, message: "Contact number must be longer than 11 characters" },
                        pattern: {
                            value: /^[0-9]+$/,
                            message: "Invalid contact number"
                        }
                    })}
                    onChange={(value) => {}}
                    required
                    />

                <Input 
                    type='password' 
                    label='Password'
                    placeholder='' 
                    name="password"
                    error={errors.password}
                    onChange={(value) => {}}
                    ref={register({
                        required: 'Please enter a password.',
                    })}
                    required />

                <Input 
                    type='password' 
                    label='Confirm Password'
                    placeholder='' 
                    name="confirmPassword"
                    error={errors.confirmPassword}
                    onChange={(value) => {}}
                    ref={register({
                        required: 'Please re-enter your password.',
                        validate: value => value === password.current || "The passwords do not match"
                    })}
                    required />

                <Button type='submit' >Create Account</Button>
                {newAccountCreated ? <p>New account created.</p> : null}
                <Error dangerouslySetInnerHTML={{__html: newAccountErrorMessage}} />
            </form>
        </Column>
    </Content>
);
}