// @flow
import React from 'react';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const FormInput = styled.input`
  padding:5px;
  font-size: 1.0em;
  ${props => props.error ? `border-color ${ERROR_COLOUR};` : `border-color ${INPUT_BORDER_COLOR};`}
  ${props => props.disabled ? `background-color: ${INPUT_DISABLED_COLOUR};` : `background-color: #fff;`}
  ${props => props.disabled ? `cursor: not-allowed;` : `cursor: inherit;`}
  color: ${INPUT_TEXT_COLOUR};

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
`;

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`;

const Search = styled.div`
  display: flex;
    position: absolute;
    right: 0px;
    bottom: 10px;
    width: 27px;
    height: 40%;
    border: solid 2px #5e5e5e;
    cursor: pointer;
    color:#5e5e5e;
    align-items: center;
    justify-content: center;
    background-color:#efefef;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  onKeyPress: (keyValue: string, value: string) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  readonly: Boolean,
};

const Input = React.forwardRef(({
  label,
  required,
  type,
  keyValue,
  value,
  onChange,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  isSearch = false,
  name,
  readOnly = false,
}: Props, ref) => {
  return (
    <InputContainer>
        <LabelContainer><Label>{label}{required ? <Required error={error && error.message}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
        <FormInput 
          type={type}
          placeholder={placeholder} 
          name={name}
          error={error && error.message}
          ref={ref}
          value={value}
          onChange={(onChange !== null && onChange !== undefined) && isSearch === false ? e => onChange(keyValue, e.target.value) : null}
          onKeyDown={(onChange !== null && onChange !== undefined) ? e => e.key === "Enter" ? onChange(keyValue, e.target.value) : null : null}
          readOnly={readOnly}
          disabled={disabled}
        />
        {isSearch === true ?
        <Search onClick={onChange !== null && onChange !== undefined ? e => onChange(keyValue, ref.current.value) : ``} >
          <FontAwesomeIcon icon={faSearch} size="1x" />
        </Search> : null }
    </InputContainer >
  )
});

export default Input