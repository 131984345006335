// @flow
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from '../../state/actions/resetPassword';

type ResetPasswordHook = {
    error: string,
    message: string,
    passwordChangeRequestSubmit: Function,
};

export function useResetPasswordRequest(): ResetPasswordHook {
    const dispatch = useDispatch();

    const passwordResetStore = useSelector(state => state.changePassword);

    const passwordChangeRequestSubmit = (formValues) => {
        dispatch(authActions.loadResetPasswordRequest(formValues.emailAddress));
    };

    return {
        error: passwordResetStore.error,
        message: passwordResetStore.message,
        passwordChangeRequestSubmit,
    };
}
