// @flow
import { constants } from '../actions/products';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  searchResults: object[],
  productDetails: object,
  filters: object,
};

const initialState: State = { 
  searchResults: null,
  filters: null,
  productDetails: null,
  isLoading: true,
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadProductSearch:
      return { ...state, isLoading: true };

    case constants.loadProductSearchSuccess: {
      return {
        ...state,
        searchResults: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadProductSearchFailure:
      return { ...state,  searchResults: null, error: action.error, isLoading: false};

    case constants.loadProductDetails:
      return { ...state, productDetails: null , isLoading: true };
    case constants.loadProductDetailsSuccess: {
      return {
        ...state,
        productDetails: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case constants.loadProductDetailsFailure:
      return { ...state, error: action.error, productDetails: null, isLoading: false };
    default:
      return state;
    }
}
