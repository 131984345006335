import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/resetPassword';
import { post } from '../../helpers/http';
import { accountsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadResetPasswordRequest({ payload }) {
  try {
    
    const url = `${accountsUrl}/request-password-reset`;

    const response = yield call(post, { url, data: { emailAddress: payload, } });

    yield put(actions.loadResetPasswordRequestSuccess(response.data.message));
  } catch (error) {
    yield put(actions.loadResetPasswordRequestFailure(GetErrorResponseMessage(error, "resetPasswordRequest", REQUEST_METHODS.GET)));
  }
}

function* loadResetPassword({ payload }) {
  try {
    
    const url = `${accountsUrl}/password-reset`;

    const response = yield call(post, { url, data: { token: payload.token, newPassword: payload.newPassword, confirmNewPassword: payload.confirmNewPassword } });

    yield put(actions.loadResetPasswordSuccess(response.data.message));
  } catch (error) {
    yield put(actions.loadResetPasswordFailure(GetErrorResponseMessage(error, "resetPasswordRequest", REQUEST_METHODS.POST)));
  }
}

export default function* productSaga() {
  yield takeLatest(constants.loadResetPasswordRequest, loadResetPasswordRequest);
  yield takeLatest(constants.loadResetPassword, loadResetPassword);
}
