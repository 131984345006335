// @flow
import { constants } from '../actions/adminProducts';
import { Error } from '../types/error';

export type ProductState = {
  isLoading: boolean,
  isSaving: boolean,
  isUploading: boolean,
  saveMessage: string,
  error: ?Error,
  productDetails: null,
  categories: object,
  deliveries: object
};

export type State = {
  adminProducts: ProductState,
};

const initialState: State = {
  isLoading: true,
  isSaving: false,
  isUploading: false,
  saveMessage: null,
  error: null,
  productDetails: null,
  categories: null,
  deliveries: null
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadAdminProductDetails:
      return { ...state, isLoading: true, productDetails: null };
    case constants.loadAdminProductDetailsSuccess: {

      let product = action.payload.product;

      return {
        ...state,
          productDetails: product,
          categories: action.payload.categories,
          deliveries: action.payload.deliveries,
          error: null,
          isLoading: false,
          isUploading: false,
          saveMessage: null,
      };
    }
    case constants.loadAdminProductDetailsFailure:
      return { ...state, error: action.error, productDetails: null, isLoading: false };

    case constants.saveProductDetails:
      return { ...state, isSaving: true, saveMessage: null };
    case constants.saveProductDetailsSuccess: {
      return {
        ...state,
        error: null,
        isSaving: false,
        productDetails: action.payload.product,
        saveMessage: action.payload.saveMessage
      };
    }
    case constants.saveProductDetailsFailure:
      return { ...state, error: action.error, isSaving: false, saveMessage: null };

    case constants.imageUpload:
      return { ...state, isUploading: true };
    case constants.imageUploadSuccess: {
      return {
        ...state,
        error: null,
        isUploading: false,
        saveMessage: action.payload.saveMessage
      };
    }
    case constants.imageUploadFailure:
      return { ...state, error: action.error, isUploading: false };
    default:
      return state;
    }
}
