export const constants = {
    loadHomePage: 'LOAD_HOME_PAGE',
    loadHomePageSuccess: 'LOAD_HOME_PAGE_SUCCESS',
    loadHomePageFailure: 'LOAD_HOME_PAGE_FAILURE',
};

export const actions = {
    loadHomePage: payload => ({
        type: constants.loadHomePage,
        payload
    }),
    loadHomePageSuccess: payload => ({
        type: constants.loadHomePageSuccess,
        payload,
    }),
    loadHomePageFailure: error => ({
        type: constants.loadHomePageFailure,
        error,
    }), 
};