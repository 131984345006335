import REQUEST_METHODS from '../constants/requestMethods';
import { history } from '../state/effects/router';
import ROUTES from '../constants/routes';

export function GetErrorResponseMessage(responceData, effectType, method)
{
    if(!responceData.response || !responceData.response.status)
    {
        return { message: "There was a problem processing your request. Please try again later." };
    }

    if(responceData.response.status === 500)
    {
        return { message: "There was a problem processing your request. Please try again later." };
    }

    if(responceData.response.status === 401)
    {
        history.push(ROUTES.LOGIN);
    }

    if(effectType === "productSearch")
    {
        if(responceData.response.status === 503)
        {
            return { message: "There was a problem getting the product details, Please try refreshing the page." };
        }
    }

    if(effectType === "adminOrders")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem retrieving the orders." };
        }

        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            return { message: "There was a problem processing the order." };
        }
    }

    if(effectType === "AdminProductSearch")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem retrieving search results." };
        }
    }

    if(effectType === "AdminProductSearch-Details")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem retrieving the product details." };
        }

        if(responceData.response.status === 503 || responceData.response.status === 400 && method === REQUEST_METHODS.POST)
        {
            return { message: responceData.response.data && responceData.response.data.message };
        }

        if(responceData.response.status === 503 && method === REQUEST_METHODS.UPLOAD)
        {
            //image file upload
            return { message: responceData.response && responceData.response.data && responceData.response.data.message }
        }
    }

    if(effectType === "deliveryAndBillingDetails")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem retrieving the billing and delivery details." };
        }

        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            return { message: "There was a problem processing the order." };
        }
    }
    if(effectType === "deliveryAndBillingDetails-Order")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            return { message: "There was a problem processing the order." };
        }
    }
    if(effectType === "deliveryAndBillingDetails-OrderReset")
    {
        return { message: "There was a problem clearing your basket." };
    }
    
    if(effectType === "contactUsEmail")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            return { message: "There was a problem processing the contact us form, please try calling us directly using out phone number or email address." };
        }
    }
    
    if(effectType === "customerDetails")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem loading the customer details." };
        }

        if(responceData.response.status === 503 || responceData.response.status === 400 && method === REQUEST_METHODS.POST)
        {
            return { message: responceData.response.data && responceData.response.data.message };
        }
    }

    if(effectType === "customerOrdersSearch")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem loading the your orders." };
        }
    }

    if(effectType === "siteMenu")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem loading the menu." };
        }
    }

    if(effectType === "resetPasswordRequest")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.GET)
        {
            return { message: "There was a problem requesting your password to be reset, please try again later." };
        }

        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            let errorMessage = responceData.message;
            if(responceData?.response?.data && responceData?.response?.data?.message)
            {
                errorMessage = responceData?.response?.data?.message;

                if(errorMessage[0].includes('Invalid token') )
                {
                    errorMessage[0] = "The link to change your password has expored, please request a new link.";
                }
                else
                {
                    if(errorMessage && errorMessage.length > 0)
                    {
                        errorMessage = errorMessage?.message.join('<br />');
                    }
                    else
                    {
                        errorMessage = errorMessage?.message
                    }
                }
            }

            return { message: errorMessage };
        }
    }

    if(effectType === "adminAccountAdmin")
    {
        if(responceData.response.status === 503 && method === REQUEST_METHODS.POST)
        {
            return { message: responceData.response.data && responceData.response.data.message };
        }
    }

    if(effectType === "styledPages-add-styled-page")
    {
        if(responceData.response.status === 400 && method === REQUEST_METHODS.POST)
        {
            return { message: responceData.response.data && responceData.response.data.message };
        }
    }

    if(effectType === "loadHomePage")
    {
        if(responceData.response.status === 400 && method === REQUEST_METHODS.GET)
        {
            return { message: responceData.response.data && responceData.response.data.message };
        }
    }

    if(effectType === "postImageUpload")
    {
        if(responceData.response.status === 400 && method === REQUEST_METHODS.POST)
        {
            return { message: "There was a problem uploading the image, please try again later." };
        }
    }
}