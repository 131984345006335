// @flow
import React, { useRef } from 'react';
import styled from 'styled-components';
import  { Link } from 'react-router-dom';
import Input from '../common/Input';
import ROUTES from '../../constants/routes';
import { useForm } from "react-hook-form";
import { useCheckoutUserSelection } from '../basket/useCheckoutUserSelection';

const Button = styled.button`
    padding: 5px;
    min-width: 100px;
    margin: 10px 10px 10px 0;
`;

const CheckoutStageTitle = styled.div`
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 10px;
    background-color: #fafafa;
    margin: 5px;
    width: 100%;

    @media (max-width: 1200px) {
        flex-direction: Row;
        width: auto;

        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 10px;
    }
`;

const Error = styled.div`
    color: #a82700;
    font-weight: bold;
    font-size: 0.9em;
`;

const LoginForm = styled.div`
    min-width:250px;
    width: 100%;
`;

const ForgottenPasswordLinkItem = styled(Link)`
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
    font-size: 0.8em;
    white-space: nowrap;
`;

export default function CheckoutUserSelection(props) {
    
    const {paymentDetails, updatePaymentDetails} = props.props;
    
    const { handleSubmit: handleNewAccountSubmit, register: newAccountRegister, 
        errors: newAccountErrors, getValues: newAccountGetValues, watch } = useForm();    

    const { handleSubmit: handleExistingAccountSubmit, register: existingAccountRegister, 
        errors: existingAccountErrors, getValues: existingAccountGetValues } = useForm();  

    const { newAccountSubmit, existingAccountSubmit, loginErrors, newAccountErrorMessage } = useCheckoutUserSelection(paymentDetails, updatePaymentDetails, existingAccountGetValues, newAccountGetValues);
    
    const password = useRef({});
    password.current = watch("password", "");

return paymentDetails.userDetailsSet === true ? null : (
    <>
        <Row>
            <LoginForm>
                <CheckoutStageTitle>
                    <strong>Create Account</strong>
                </CheckoutStageTitle>
                <form style={{width:'100%'}} onSubmit={handleNewAccountSubmit(newAccountSubmit)}>
                    <Input
                        label='First Name' 
                        type='text' 
                        placeholder='' 
                        name="firstName"
                        error={newAccountErrors.firstName}
                        ref={newAccountRegister({
                        required: 'Please enter your first name.',
                        minLength: { value: 3, message: "First name must be longer than 3 characters" },
                        })}
                        onChange={(value) => {}}
                        required
                        />

                    <Input
                        label='Last Name' 
                        type='text' 
                        placeholder='' 
                        name="lastName"
                        error={newAccountErrors.lastName}
                        ref={newAccountRegister({
                        required: 'Please enter your last name.',
                        minLength: { value: 3, message: "Last name must be longer than 3 characters" },
                        })}
                        onChange={(value) => {}}
                        required
                        />

                    <Input
                        label='Email Address' 
                        type='text' 
                        placeholder='' 
                        name="username"
                        error={newAccountErrors.username}
                        ref={newAccountRegister({
                        required: 'Please enter your email address.',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address"
                        }})}
                        onChange={(value) => {}}
                        required
                        />

                    <Input
                        label='Contact number' 
                        type='text' 
                        placeholder='' 
                        name="contactNumber"
                        error={newAccountErrors.contactNumber}
                        ref={newAccountRegister({
                            required: 'Please enter your contact number.',
                            minLength: { value: 11, message: "Contact number must be longer than 11 characters" },
                            pattern: {
                                value: /^[0-9]+$/,
                                message: "Use numbers with no spaces."
                            }
                        })}
                        onChange={(value) => {}}
                        required
                        />

                    <Input 
                        type='password' 
                        label='Password'
                        placeholder='' 
                        name="password"
                        error={newAccountErrors.password}
                        onChange={(value) => {}}
                        ref={newAccountRegister({
                            required: 'Please enter a password.',
                        })}
                        required />

                    <Input 
                        type='password' 
                        label='Confirm Password'
                        placeholder='' 
                        name="confirmPassword"
                        error={newAccountErrors.confirmPassword}
                        onChange={(value) => {}}
                        ref={newAccountRegister({
                            required: 'Please re-enter your password.',
                            validate: value => value === password.current || "The passwords do not match"
                        })}
                        required />

                    <Button type='submit' >Continue with new account</Button> 
                    
                    <Error>{newAccountErrorMessage}</Error>
                </form>
            </LoginForm>
        </Row>
        <Row>
            <LoginForm>
            <CheckoutStageTitle>
                    <strong>Log in</strong>
                </CheckoutStageTitle>
                <form style={{width:'100%'}} onSubmit={handleExistingAccountSubmit(existingAccountSubmit)}>

                    <Input type='hidden' name='redirectTo' onChange={(value) => {}} ref={existingAccountRegister()} value={''} />

                    <Input
                        label='Email Address' 
                        type='text' 
                        placeholder='' 
                        name="username"
                        error={existingAccountErrors.username}
                        ref={existingAccountRegister({
                        required: 'Please enter your email address.',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address"
                        }})}
                        onChange={(value) => {}}
                        required
                        />

                    <Input 
                        type='password' 
                        label='Password'
                        placeholder='' 
                        name="password"
                        error={existingAccountErrors.password}
                        onChange={(value) => {}}
                        ref={existingAccountRegister({
                            required: 'Please enter your password.',
                        })}
                        required />

                    <Button type='submit' >Login with existing account</Button> 
                    <ForgottenPasswordLinkItem to={ROUTES.REQUEST_PASSWORD_RESET}>Forgotten your password?</ForgottenPasswordLinkItem>
                    
                    <Error dangerouslySetInnerHTML={{__html: loginErrors}} />
                </form>
            </LoginForm>
        </Row>
    </>
    );
}