// @flow
import React from 'react';
import styled from 'styled-components';
import StyledPages from '../../components/admin/EditStyledPages'
import LoadingAnimation from '../../components/common/LoadingAnimation';

import { useEditStyledPages } from '../../components/admin/useEditStyledPages';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Error = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 40px 0;
    font-weight: bold;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
`;

export default function(props) {

 const {
    error,
    styledPage,
    isLoading,
    isUploading,
    publishStyledPage,
    uploadImages,
    updateImages,
    isSaving,
    saveMessage,
    moveDragableItem,
    addItemBlock,
    addStyledPage,
    removeStyledPage,
    lastUploadedImages,
    resetItemBlockForm,
    removeItemBlockSubmit,
    setStyledPageToEdit,
 } = useEditStyledPages();

return (
    <Container>
        <Header></Header>
        
        <Content>
            { isLoading === true ? <Loading><LoadingAnimation /></Loading> : 
                styledPage ?         
                <StyledPages styledPage={{
                        error,
                        styledPage,
                        isLoading,
                        isUploading,
                        publishStyledPage,
                        uploadImages,
                        updateImages,
                        isSaving,
                        saveMessage,
                        moveDragableItem,
                        addItemBlock,
                        addStyledPage,
                        removeStyledPage,
                        lastUploadedImages,
                        resetItemBlockForm,
                        removeItemBlockSubmit,
                        setStyledPageToEdit,
                    }} /> : 
                    <Error>{error && error.message}</Error> }
        </Content>

    </Container>
);
}