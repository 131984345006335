// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const FormInput = styled.select`
  padding:5px;
  font-size: 1.0em;
  ${props => props.error ? `border-color ${ERROR_COLOUR};` : `border-color ${INPUT_BORDER_COLOR};`}
  ${props => props.disabled ? `background-color: ${INPUT_DISABLED_COLOUR};` : `background-color: #fff;`}
  ${props => props.disabled ? `cursor: not-allowed;` : `cursor: inherit;`}
  color: ${INPUT_TEXT_COLOUR};

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
`;

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const Placeholder = styled.option`
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`;

const InputContainer = styled.div`
    margin-bottom: 10px;
`;

const labelFromOption = opt => opt.label;
const valueFromOption = opt => opt.value;

type Props<Option> = {
  keyValue: string,
  value: Option,
  onChange: (input: { keyValue: string, value: any }) => void,
  disabled: boolean,
  options: Option[],
  label: string,
  required: boolean,
  children: Node,
  error?: string,
  placeholder?: string,
  name: string,
};

const MultiSelect = React.forwardRef(({
  label,
  required,
  keyValue,
  onChange,
  disabled,
  options,
  error,
  value,
  placeholder,
  name
}: Props<Option>, ref) => {
  return (
      <InputContainer>
        <LabelContainer><Label>{label}{required ? <Required error={error}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
        <FormInput
            multiple
            name={name}
            error={error}
            ref={ref}
            onChange={({ target: { value: val, options: op, selectedIndex } }) => {
            onChange(keyValue, { options: op });
            }}
            disabled={disabled}
        >
            {[
              !value && placeholder && (
                  <Placeholder key={`${placeholder}`} value={placeholder}>
                  {placeholder}
                  </Placeholder>
              ),
              ...options.map(o => {
                  const optionLabel = labelFromOption(o);
                  const optionValue = valueFromOption(o);
                  return (
                      <option key={`${optionValue}${optionLabel}`} value={optionValue} selected={value && value.find(obj => obj === optionValue)}>
                      {optionLabel}
                      </option>
                  );
              }),
            ].filter(Boolean)}
        </FormInput>
    </InputContainer>
  )
});

export default MultiSelect