// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as basketActions } from '../../state/actions/basket';
import {loadStripe} from '@stripe/stripe-js/pure';
import { paymentKey } from '../../settings';

export function useCheckout(paymentDetails: Object, basket: Object) {
    const dispatch = useDispatch();

    const orderStore = useSelector(state => state.newOrder);
    const [order, setOrder] = useState(orderStore);
    const [stripePromise, setStripePromise] = useState(null);
    const [orderConfirmation, setOrderConfirmation] = useState(order);

    if(order && order.orderId && order.orderId !== orderConfirmation.orderId)
    {
        setOrderConfirmation(order);
    }

    useEffect(() => {
        try{
            loadStripe.setLoadParameters({advancedFraudSignals: false});
        }
        catch(e)
        {
        }

        setStripePromise(loadStripe(paymentKey));
    }, []);

    useEffect(() => {
        setStripePromise(loadStripe(paymentKey));
    }, [order]);

    useEffect(() => {
    }, [orderConfirmation]);

    const RecreateOrderIntent = () => {
        if(order.intentSecret !== null)
        {
            setOrder({...orderStore, intentSecret: null, orderId: null, orderCreated: false });
        }

        dispatch(basketActions.loadOrderClientSecret({
            DeliveryAddress: paymentDetails.deliveryAddress,
            BillingAddress: paymentDetails.billingAddress,
            Items: basket.items.map((item) => { return { ProductId: item.id, Quantity: item.quantity } }),
            Delivery: paymentDetails.deliveryMethod,
            sameBillingAddress: paymentDetails.sameBillingAddress
        }));
    };

    const ResetOrder = () => {
        if(order.intentSecret !== null)
        {
            setOrder({...orderStore, intentSecret: null, orderId: null, orderCreated: false });
        }
    };

    const ResetConfirmOrder = () => {
        if(orderConfirmation.orderId !== null)
        {
            dispatch(basketActions.resetOrderNewDetails());
            setOrder(orderStore);
            setOrderConfirmation(orderStore);
        }
    };

    const TotalItemPrice = () => 
    {
        let total = 0;

        basket.items.forEach(x => { 
            total += x.quantity * x.price;
        })

        return total;
    };

    const GetTotalPriceAndDelivery = () => {

        const totalPrice = TotalItemPrice();
    
        const deliveryOptions = [];

        if(basket.items.length === 1)
        {
            const deliveryMethod = basket.items[0].deliveryOptions.filter(x => x.delivery.name === paymentDetails.deliveryMethod);

            if(deliveryMethod.length === 0)
            {
                return { 
                    totalPrice, 
                    delivery: { description: 'International Delivery', price: null }
                };
            }
            
            if(totalPrice >= deliveryMethod[0].delivery.freeIfOverAmount || deliveryMethod.price === 0)
            {
                return { 
                    totalPrice, 
                    delivery: { description: deliveryMethod[0].delivery.description, price: 0 }
                };
            }

            return { 
                totalPrice: totalPrice + deliveryMethod[0].delivery.price, 
                delivery: { description: deliveryMethod[0].delivery.description, price: deliveryMethod[0].delivery.price }
            };  
        }

        for(let a = 0; a < basket.items.length; a++)
        {
            for(let b = 0; b < basket.items.length; b++)
            {
                for(let i = 0; i < basket.items[a].deliveryOptions.length; i++)
                {
                    for(let k = 0; k < basket.items[b].deliveryOptions.length; k++)
                    {
                        if(basket.items[a].id !== basket.items[b].id && basket.items[a].deliveryOptions[i].delivery.name === basket.items[b].deliveryOptions[k].delivery.name)
                        {
                            if(deliveryOptions && deliveryOptions.filter(x => basket.items[a].deliveryOptions[i].delivery.name === x.name).length === 0)
                            {
                                deliveryOptions.push(basket.items[a].deliveryOptions[i].delivery);
                            }
                        }
                    }
                }
            }
        }

        const deliveryMethod = deliveryOptions.filter(x => x.name === paymentDetails.deliveryMethod);

        if(deliveryMethod.length === 0)
        {
            return { 
                totalPrice, 
                delivery: { description: 'International Delivery', price: null }
            };
        }
            
        if(totalPrice >= deliveryMethod[0].freeIfOverAmount || deliveryMethod.price === 0)
        {
            return { 
                totalPrice, 
                delivery: { description: deliveryMethod[0].description, price: 0 }
            };
        }

        return {
            totalPrice: totalPrice + deliveryMethod[0].price, 
            delivery: { description: deliveryMethod[0].description, price: deliveryMethod[0].price }
        };      
    };

    useEffect(() => {
        setOrder(orderStore); 
    }, [orderStore]);
        
    return { 
        GetTotalPriceAndDelivery,
        ResetOrder,
        RecreateOrderIntent,
        order,
        orderConfirmation,
        ResetConfirmOrder,
        stripePromise
    };
}
