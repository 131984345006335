// @flow
import { constants } from '../actions/customerDetails';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  saveComplete: boolean,
  saveCompleteMessage: String,
  error: ?Error,
  customerDetails: object,
};

const initialState: State = { 
  customerDetails: null,
  isLoading: true,
  isSaveComplete: true,
  saveCompleteMessage: '',
  error: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadCustomerDetails:
      return { ...state, customerDetails: null, isLoading: true };

    case constants.loadCustomerDetailsSuccess: {
      return {
        ...state,
          customerDetails: action.payload,
          error: null,
          isLoading: false,
      };
    }
    case constants.loadCustomerDetailsFailure:
      return { ...state, error: action.error, customerDetails: null, isLoading: false };
    case constants.postUpdateCustomerDetails:
      return { ...state, isSaveComplete: false, saveCompleteMessage: '' };
    case constants.postUpdateCustomerDetailsSuccess: {
      return {
        ...state,
          error: null,
          saveCompleteMessage: action.payload.message,
          isSaveComplete: true,
      };
    }
    case constants.postUpdateCustomerDetailsFailure:
      return { ...state, error: action.error, isLoading: false, isSaveComplete: true, saveCompleteMessage: '' };
    default:
      return state;
    }
}
