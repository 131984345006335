// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from '../../state/actions/auth';
import { getExpiryTime } from '../../helpers/jwt';
import { authOn } from '../../settings';
import ROUTES from '../../constants/routes';
import history from '../../state/effects/router';

type authDetails = {
  username: string,
  password: string,
};

type AuthHook = {
  loginSubmit: Function,
  updateAuthDetails: Function,
  authDetails: authDetails
};

const isTokenStillValid = (token): boolean =>
  token ? getExpiryTime(token) > Date.now() : !authOn;

export function useAuth(): AuthHook {
    const dispatch = useDispatch();

    let authData = localStorage.getItem('persist:root');
    if(authData !== null)
    {
      authData = JSON.parse(authData);
      authData = authData.authDetails === undefined ? null : JSON.parse(authData.authDetails);
    }

    const authStore = useSelector(state => state.authDetails);

    const loginSubmit = (formValues) => {
        dispatch(authActions.login(formValues.username, formValues.password, formValues.redirectTo));
    };

    const logout = () => dispatch(authActions.logout());

    const recheckLogin = () => {
      if(authStore && authStore.loginDetails && authStore.loginDetails.token){
        if(!isTokenStillValid(authStore.loginDetails.token)) {     
          history.push(ROUTES.LOGIN);
        }
      }
    };

    useEffect(() => {
      let authData = localStorage.getItem('persist:root');
      if(authData !== null && authData !== undefined)
      {
        authData = JSON.parse(authData);
        authData = authData.loginDetails === undefined ? null : JSON.parse(authData.loginDetails);
      }
    }, [dispatch, authStore.loginDetails]);

    if(authStore && authStore.loginDetails)
    {
      return {
          error: authStore.error,
          redirectTo : authStore.redirectTo,
          isLoggedIn: isTokenStillValid(authStore.loginDetails.token) && authStore.loginDetails.loginResult.succeeded,
          loginDetails: authStore.loginDetails, 
          loginSubmit,
          logout,
          recheckLogin
      };
    }

    return {
        error: authStore.error,
        redirectTo : authData && authData.redirectTo,
        isLoggedIn: authData && authData.loginDetails && isTokenStillValid(authData.loginDetails.token) && authData.loginDetails.loginResult.succeeded,
        loginDetails: authData && authData.loginDetails, 
        loginSubmit,
        logout,
        recheckLogin
    };
}
