import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/adminCategories';
import { get, post, postFiles, httpPut } from '../../helpers/http';
import { adminCategoriesUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadAdminCategories() {
  try {
    
    const url = `${adminCategoriesUrl}/admin-categories`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadAdminCategoriesSuccess(response.data));
  } catch (error) {
    yield put(actions.loadAdminCategoriesFailure(GetErrorResponseMessage(error ,"loadAdminCategories", REQUEST_METHODS.GET)));
  }
}

function* postAdminUpdateCategories({payload}) {
  try {

    let url = `${adminCategoriesUrl}/admin-categories`;
    let method = post;

    if(payload.categoryId !== undefined) {
        url = `${adminCategoriesUrl}/admin-categories/${payload.categoryId}`;
        method = httpPut;
    }

    const response = yield call(method, { url,  data: payload });

    yield put(actions.postAdminUpdateCategoriesSuccess(response.data));
  } catch (error) {
    yield put(actions.postAdminUpdateCategoriesFailure(GetErrorResponseMessage(error ,"postAdminUpdateCategories", REQUEST_METHODS.POST)));
  }
}

function* postCategoryImageUpload({payload}) {
  try {
    const url = `${adminCategoriesUrl}/images`;

    const response = yield call(postFiles, { url, data: payload.images });

    yield put(actions.postCategoryImageUploadSuccess(response.data));
  } catch (error) {
    yield put(actions.postCategoryImageUploadFailure(GetErrorResponseMessage(error, "postImageUpload",REQUEST_METHODS.POST)));
  }
}

export default function* adminOrdersSaga() {
  yield takeLatest(constants.loadAdminCategories, loadAdminCategories);
  yield takeLatest(constants.postAdminUpdateCategories, postAdminUpdateCategories);
  yield takeLatest(constants.postCategoryImageUpload, postCategoryImageUpload);
}
