import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import reducer from './reducer';
import sagas from './effects';

const persistedReducer = persistReducer({
    key: 'root',
    storage,
    whitelist: ['authDetails', 'authDetails.authDetails'],
    },
    reducer,
);

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagas.forEach(saga => sagaMiddleware.run(saga));

export const persistor = persistStore(store);
export default store;