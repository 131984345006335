import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/products';
import { get } from '../../helpers/http';
import { productsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadProductSearch({ payload }) {
  try {
    
    const url = `${productsUrl}/search`;

    const config = {
      params: {
        page: payload.page,
        minPrice: payload.minPrice,
        maxPrice: payload.maxPrice,
        searchText: payload.searchText,
        brandName: payload.brand,
        categoryName: payload.category,
        subCategoryName: payload.subCategory,
        subSubCategoryName: payload.subSubCategory,
        pageSize: payload.pageSize,
        location: payload.location,
        sortBy: payload.sortBy,
        sortByOptions: payload.sortByOptions,
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadProductSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.loadProductSearchFailure(GetErrorResponseMessage(error, "productSearch", REQUEST_METHODS.GET )));
  }
}

function* loadProductDetails({ payload }) {
  try {
    
    const url = `${productsUrl}`;

    const config = {
      params: {
        name: payload,
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadProductDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadProductDetailsFailure(GetErrorResponseMessage(error, "productSearch", REQUEST_METHODS.GET)));
  }
}

export default function* productSaga() {
  yield takeLatest(constants.loadProductSearch, loadProductSearch);
  yield takeLatest(constants.loadProductDetails, loadProductDetails);
}
