// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as contactUsActions } from '../../state/actions/basket';
import { CardElement } from '@stripe/react-stripe-js';

const defaultPaymentResult = {
    error: null
};

export function useCheckoutPayment(paymentDetails: Object, basketItems: Object, clientSecret: Object, stripe: Object, elements: Object) {
    const dispatch = useDispatch();

    const [paymentResult, setPaymentResult] = useState(defaultPaymentResult);

    const handleSubmitPayment = async (event) => {

        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
                name: `${paymentDetails.userDetails.firstName} ${paymentDetails.userDetails.lastName}`,
            },
            }
        });
    
        if (result.error) 
        {
            setPaymentResult({...paymentResult, error: result.error.message});           
        } 
        else 
        {
            if (result.paymentIntent.status === 'succeeded') 
            {
                setPaymentResult({...paymentResult, error: null });
                dispatch(contactUsActions.postOrderNewDetails({
                    DeliveryAddress: paymentDetails.deliveryAddress,
                    BillingAddress: paymentDetails.billingAddress,
                    Items: basketItems.map((item) => { return { ProductId: item.id, Quantity: item.quantity } }),
                    Delivery: paymentDetails.deliveryMethod,
                    sameBillingAddress: paymentDetails.sameBillingAddress,
                    PaymentReferenced: result.paymentIntent.id
                }));   
            }
        }
    };

    useEffect(() => {

    }, [paymentResult]);

    return {
        handleSubmitPayment,
        paymentErrors: paymentResult.error
    };
}
