// @flow
import React from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { default as NumberFormat } from 'react-number-format';
import logo from '../../logo.png';
import { imagesLocation } from '../../settings';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const PaddedColumn = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 20px 10px 0;
`;

const Table = styled.table`
    width: 100%;
  &&& {
    table {
        border-bottom: solid 1px #a29062;
    },
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      border-bottom: solid 1px #a29062;
      border-collapse: collapse;
      font-size: 0.8em;
    }
    th {
      text-align: left;
    }
  }
`;

const Container = styled.div`
    border: 0; 
    height: 95%;
    width: 100%;
    font-size: 0.7em;
    padding-top: 50px;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
`;

const HeaderTitle = styled.div` 
    display: flex;
    flex-direction: column; 
    width: 90%;
    text-align: center;
    justify-content: space-between;
`;

const SiteTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #a29062;
    font-family: 'seagull';
    text-align: center;
    justify-content: center;
    align-items:center;
`;

const Justified = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Center = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align:center;
    width: 100%;
`;

const LogoImage = styled.img`
    border: none;
    outline: 0;
    padding-top:0;
    src: url(${props => props.src});
    height: 60px;
`;

const Title = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 5em;
    height: 40px;
    white-space: nowrap;
`;

const SubTitle = styled.div`
    font-size: 4em;
    white-space: nowrap;
    width: 100%;
`;

const HorizontalRule = styled.div`
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0 10px 0;
`;

const StockCode = styled.div`
    font-size: 0.8em;
    white-space: nowrap;
    font-style: italic;
`;

const Img = styled.img`
    src: url(${props => props.src});
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 10px;
`;

class PrintShopOrderNote extends React.Component {
    render() 
    {
    const order = this.props.props;

    function getImages(images) {
        try {
            return(`${imagesLocation}products/${images[0].path}`);
        } catch (error) {
            return('');
        }
    }

    const OrderItems = (item, id) => 
    {
        return(
            <tr key={id}>
                <td>
                {item.product && item.product.images ? <Img src={getImages(item.product.images)} /> : null}
                </td>
                <td>
                    {item.producNameAtTimeOfSale}<br />
                    {item.producStockCodeAtTimeOfSale !== '' ? <StockCode>({item.producStockCodeAtTimeOfSale})</StockCode> : null}
                </td>
                <td>{item.quantity}</td>
                <td><NumberFormat value={ item.quantity * item.unitPrice } displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' /></td>
            </tr>
        );
    };

return(
    <Container>
        <Header>
            <HeaderTitle>
                <SiteTitle>
                    <LogoImage src={logo} />
                    <Column>
                        <Title>GALERIE MIRAGES<br /></Title>
                        <SubTitle>Est 1987&nbsp;&nbsp;&nbsp;</SubTitle>
                    </Column>
                </SiteTitle>
                <HorizontalRule />
            </HeaderTitle>                                    
        </Header>

        <ContentContainer>
            <Content> 
                <Column>
                    <Row>
                        <Center>
                            <p><strong>Order Note - Shop Copy</strong></p>
                        </Center>
                    </Row>
                    <Row>
                        <Justified>
                        <p><strong>Order Id</strong><br />{order.orderId}</p>
                        <p><strong>Date Ordered</strong><br />{Moment(order.dateAdded).format('DD-MM-YYYY')}</p>
                        </Justified>
                    </Row>
                    <Row>
                        <p><strong>Delivery To<br /></strong>{order.user && order.user.firstName} {order.user && order.user.lastName}</p>
                    </Row>
                    <Row>
                        <Justified>
                            <PaddedColumn>
                                <strong>Delivery Address</strong><br />
                                {order.deliveryAddress && order.deliveryAddress.address}<br />
                                {order.deliveryAddress && order.deliveryAddress.address1}<br />
                                {order.deliveryAddress && order.deliveryAddress.address2}<br />  
                                {order.deliveryAddress && order.deliveryAddress.city} <br />                                                        
                                {order.deliveryAddress && order.deliveryAddress.county}<br />
                                {order.deliveryAddress && order.deliveryAddress.postCode}<br />
                                {order.deliveryAddress && order.deliveryAddress.country}<br />
                            </PaddedColumn>

                            <PaddedColumn>
                                <strong>Billing Address</strong><br />
                                {order.billingAddress && order.billingAddress.address}<br />
                                {order.billingAddress && order.billingAddress.address1}<br />
                                {order.billingAddress && order.billingAddress.address2}<br />
                                {order.billingAddress && order.billingAddress.city}<br />  
                                {order.billingAddress && order.billingAddress.county}<br />            
                                {order.billingAddress && order.billingAddress.postCode}<br />
                                {order.billingAddress && order.billingAddress.country}<br />                           
                            </PaddedColumn>
                        </Justified>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                order.items.map( (item, xid) => OrderItems(item, xid)) }
                                
                                <tr key={'total'}>
                                    <td></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>
                                        <NumberFormat value={order.totalOrderPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale='2' fixedDecimalScale='true' />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>  

                    <Row>
                        <Center>
                            <p>
                                46a Raeburn Place, Edinburgh, EH4 1HL<br />
                                T:0131 315 2603 E:info@galeriemirages.com
                            </p>
                        </Center>
                    </Row>    
                </Column>
            </Content>
        </ContentContainer>
    </Container>
);
}
}

export default PrintShopOrderNote;