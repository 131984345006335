// @flow
import { REHYDRATE } from 'redux-persist/lib/constants';
import { constants } from '../actions/auth';
import type { Action } from '../actions/auth';
import { getExpiryTime } from '../../helpers/jwt';
import { authOn } from '../../settings';
import { type Error } from '../types/error';
import ROUTES from '../../constants/routes';
import ROLES from '../../constants/roles';

type Token = string;

export type State = {
  isLoginPending: boolean,
  isLoggedIn: boolean,
  token: ?Token,
  error: ?Error,
  redirectTo: ?string,
  loginDetails: Object, 
};

const initialState: State = {
  isLoginPending: false,
  isLoggedIn: !authOn,
  error: null,
  redirectTo: null,
  loginDetails: { token : null}, 
};

const isTokenStillValid = (token): boolean =>
  token ? getExpiryTime(token) > Date.now() : !authOn;

export default function reducer(
  state: State = initialState,
  action: Action | { type: typeof REHYDRATE, payload: Object },
): State {   
  switch (action.type) {
    case constants.Login:
      return { ...state, error: null, isLoginPending: true };
    case constants.LoginSuccess:

      let redirectTo = action.payload.redirectTo;
      if(action.payload.loginDetails.roles && action.payload.loginDetails.roles.indexOf(ROLES.ADMINISTRATOR) !== -1)
      {
          redirectTo = ROUTES.ADMIN
      }

      return { ...state, 
        error: null, 
        isLoggedIn: true, 
        isLoginPending: false, 
        redirectTo: redirectTo,
        loginDetails: action.payload.loginDetails, 
      };
      
    case constants.LoginFailure:
      let errorMessage = action.payload;
      
      if(action.payload && action.payload && action.payload.response && action.payload.response.data) {
        errorMessage = action.payload.response.data.message;
      }

      return { ...state, ...initialState, error: errorMessage };
    case constants.Logout:
      localStorage.removeItem('persist:root');
      return { ...state, ...initialState };
    case REHYDRATE: {
      const authDetails = action.payload && action.payload.authDetails;
      const token = authDetails && authDetails.loginDetails && authDetails.loginDetails.token;
            
      return {
        ...state,
        isLoggedIn: isTokenStillValid(token),
        isLoginPending: false, 
        redirectTo: action.payload && action.payload.redirectTo,
        loginDetails: authDetails && authDetails.loginDetails, 
      };
    }
    case constants.Ready:
      return { ...state, isLoggedIn: isTokenStillValid(state.token)  };
    default:
      return state;
  }
}
