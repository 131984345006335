import { lensPath, view } from 'ramda';
import { useEffect } from 'react';

export function useEditProductForm(product, isSaving, setValue, setError, register, trigger) {

  const productLens = (...propPath) => view(lensPath(propPath), product);

  useEffect(() => {
    register('brand', {
      required: 'Select a brand.',
    });

    register('category', {
      required: 'Select a category.',
    });

    register('subCategory', {
      required: 'Select a sub category.',
    });

    register({name: 'description'}, {
      required: 'Enter a description.',
    });
}, []);

useEffect(() => {
  if(product.brand && product.brand.name)
  {
    setValue('brand', product.brand.name);
  }

  if(product.category && product.category.name)
  {
    setValue('category', product.category.name);
  }

  if(product.subCategory && product.subCategory.name)
  {
    setValue('subCategory', product.subCategory.name);
  }

  if(product && product.description)
  {
    setValue('description', product.description);
  }
}, [product]);

  useEffect(() => {

      if(isSaving === false)
      {
        let description = productLens('description');

        if(description === '<p><br></p>' || description === '' || description === undefined)
        {
          description = '';

          setError("description", {
            message: "Enter a description"
          });
        }
        else{
          setValue('description', description);
        }
      }
  }, [product, isSaving]);
}
