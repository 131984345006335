// @flow
import { constants } from '../actions/contactUs';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  message: string,
};

const initialState: State = {
  isLoading: true,
  error: null,
  message: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadContactUsEmail:
      return { ...state, isLoading: true, message: null, error: null };

    case constants.loadContactUsEmailSuccess: {
      return {
        ...state,
        message: action.payload.message,
        isLoading: false,
        error: null
      };
    }
    case constants.loadContactUsEmailFailure:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
}
