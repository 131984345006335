// @flow
import React from 'react';
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { useContactUs } from '../../components/contactUs/useContactUs';
import Input from '../../components/common/Input';
import TextArea from '../../components/common/TextArea';
import shop from '../../assets/contact-us/galeriemirage-interior-1024x682.jpg';
import { useOpeningHours } from '../../components/menu/useOpeningHours';

const Container = styled.div`
    border: 0; 
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin: auto;

    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
    width: 100%;

    @media (max-width: 799px) {
        flex-direction: column;
    }
`;

const RowContent = styled.div`
    padding: 10px 10px 10px 10px;
    color:#5e5e5e;
    background-color: #fafafa;

    @media (min-width: 800px) {
        width: 100%;
    }
`;

const Img = styled.img`
    src: url(${props => props.src});
    overflow: hidden;
    padding: 5px;
    width: 100%;
`;

const Button = styled.button`
    padding:5px;
    width: 100px;
`;

const HorizontalRule = styled.div`
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0 10px 0;
`;

const MapContainer = styled.div`
    height: 100%;
    width: 100%;

    @media (min-width: 1000px) {
        width: 70%;
    }
`;

export default function() {

    const { handleSubmit, register, errors } = useForm();
    const {
        error,
        message,
        isLoading,
        submitContactUsEmail } = useContactUs();

    const {
        openingHours,
    } = useOpeningHours();

return (
    <Container>
        <Header></Header>
        <Content>      
            <Row>
                <RowContent>
                <b>Contact</b><br/>&nbsp;<br/>

                46a Raeburn Place<br/>
                Edinburgh<br/>
                EH4 1HL<br/>
                view map<br/>&nbsp;<br/>

                0131 315 2603<br/>
                info@galeriemirages.com<br/>&nbsp;<br/>

                <strong>Opening Hours</strong><br/>
                {
                    openingHours !== null && openingHours !== undefined ? 
                    <>
                        {openingHours.opening1 === '' ? null : <>{openingHours.opening1} <br /></>}
                        {openingHours.opening2 === '' ? null : <>{openingHours.opening2} <br /></>}
                        {openingHours.opening3 === '' ? null : <>{openingHours.opening3} <br /></>}
                        {openingHours.opening4 === '' ? null : <>{openingHours.opening4} <br /></>}
                        {openingHours.opening5 === '' ? null : <>{openingHours.opening5} <br /></>}
                        {openingHours.opening6 === '' ? null : <>{openingHours.opening6} <br /></>}
                        {openingHours.opening7 === '' ? null : <>{openingHours.opening7} <br /></>}
                        {openingHours.opening8 === '' ? null : <>{openingHours.opening8} <br /></>}
                        {openingHours.opening9 === '' ? null : <>{openingHours.opening9} <br /></>}
                        {openingHours.opening10 === '' ? null : <>{openingHours.opening10} <br /></>}
                    </> : null
                }

                <br/>
                Parking in Stockbridge is free at weekends, <br/>paid parking is available through the week.
                </RowContent>

                <MapContainer>
                    <iframe title="google map" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2233.427237690973!2d-3.2142658838156417!3d55.95929858060909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887c7bf13cc9961%3A0x11ab3f4d3978c14a!2sGalerie%20Mirages!5e0!3m2!1sen!2suk!4v1592314559516!5m2!1sen!2suk" 
                        width="100%" height="530" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0">            
                    </iframe>
                </MapContainer>

            </Row>
            <Row>
                <RowContent>
                    <form width='100%' key={'sendEmail'} onSubmit={handleSubmit(submitContactUsEmail)}>
                        <b>Contact us</b><br/>
                        <Input 
                            label='Email Address' 
                            name="emailAddress"
                            type='text' 
                            placeholder='example@hotmail.com'
                            error={errors.emailAddress}
                            ref={register({
                            required: 'Please enter your email address.',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address"
                            }})}
                            onChange={(value) => {}}
                            required />

                        <Input 
                            type='text' 
                            placeholder='Jane Smith'
                            label='Name' 
                            name="name"
                            error={errors.name}
                            ref={register({
                            required: 'Please enter your name.'
                            })}
                            onChange={(value) => {}}
                            required />

                        <Input 
                            type='text' 
                            placeholder='Subject'
                            label='Subject' 
                            name="subject"
                            error={errors.subject}
                            ref={register({
                            required: 'Please enter a subject.'
                            })}
                            onChange={(value) => {}}
                            required />

                        <TextArea 
                            placeholder='Your message.' 
                            label='Message' 
                            name="message"
                            error={errors.message}
                            ref={register({
                            required: 'Please enter a Message.'
                            })}
                            onChange={(value) => {}}
                            required 
                        />

                        <Button type='submit'>{isLoading ? 'Sending' : 'Send'}</Button>
                        {message}
                        {error?.message}
                    </form>

                    <br/>&nbsp;<br/>
                    <HorizontalRule />
                </RowContent>
            </Row>
            <Row>
                <Img src={shop} />
            </Row>
        </Content>
    </Container>
);
}