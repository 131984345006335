// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as contactUsActions } from '../../state/actions/contactUs';

export function useContactUs() {
  const dispatch = useDispatch();

  const contactUsStore = useSelector(state => state.contactUs);

  const submitContactUsEmail = (form) => {
    dispatch(contactUsActions.loadContactUsEmail(form));
  };

  useEffect(() => {
    
  }, [contactUsStore]);

  return {
    error: contactUsStore.error,
    message: contactUsStore.message,
    isLoading: contactUsActions.isLoading,
    submitContactUsEmail,
  };
}
