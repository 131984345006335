import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/customerOrders';
import { get } from '../../helpers/http';
import { ordersUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* loadCustomerOrdersSearch() {
  try {
    
    const url = `${ordersUrl}/my-orders`;

    const config = {
      params: {
      },
    };

    const response = yield call(get, { url, config });

    yield put(actions.loadCustomerOrdersSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.loadCustomerOrdersSearchFailure(GetErrorResponseMessage(error, "customerOrdersSearch", REQUEST_METHODS.GET)));
  }
}

export default function* adminOrdersSaga() {
  yield takeLatest(constants.loadCustomerOrdersSearch, loadCustomerOrdersSearch);
}
