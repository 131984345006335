// @flow
import React from 'react';
import styled from 'styled-components';
import ProductSearchResults from '../../components/products/ProductSearchResults';

const Container = styled.div`
    border: 0; 
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start | flex-end | center | space-between | space-around
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default function(props) {
    return(
        <Container>
            <Header></Header>
            <Content>
                <Column>
                    <Row>
                    <ProductSearchResults props={props} location={'Brands'}/>
                    </Row>
                </Column>
            </Content>
        </Container>
    );
}