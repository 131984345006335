export const constants = {
    loadMenuDetails: 'LOAD_MENU_DETAILS',
    loadMenuDetailsSuccess: 'LOAD_MENU_DETAILS_SUCCESS',
    loadMenuDetailsFailure: 'LOAD_MENU_DETAILS_FAILURE',
};

export const actions = {
    loadMenuDetails: menuDetails => ({
        type: constants.loadMenuDetails,
        payload: menuDetails
    }),
    loadMenuDetailsSuccess: payload => ({
        type: constants.loadMenuDetailsSuccess,
        payload,
    }),
    loadMenuDetailsFailure: error => ({
        type: constants.loadMenuDetailsFailure,
        error,
    })
};