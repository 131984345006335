// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as styledPageActions } from '../../state/actions/styledPages';

export function useTestStyledPage(pageId) {
  const dispatch = useDispatch();

  const styledPageStore = useSelector(state => state.styledPages);

  useEffect(() => {
    dispatch(styledPageActions.loadStyledPage( pageId === undefined ? null : pageId));
  }, []);

  useEffect(() => {
  },[dispatch, styledPageStore.isLoading]);

  return {
    styledPage: styledPageStore.styledPage,
    isLoading: styledPageStore.isLoading,
    error: styledPageStore.error
  };
}
