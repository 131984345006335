import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '../actions/adminAccounts';
import { post } from '../../helpers/http';
import { adminAccountsUrl } from '../../settings';
import { GetErrorResponseMessage } from '../../helpers/responseHelper';
import REQUEST_METHODS from '../../constants/requestMethods';

function* postAdminCreateAccount({payload}) {
  try {

    const {firstName, lastName, contactNumber, username, password, confirmPassword, accountType} = payload;
    
    const url = `${adminAccountsUrl}`;

    const response = yield call(post, { url,  data: {firstName: firstName, 
      lastName: lastName, 
      phoneNumber:contactNumber, 
      email:username, 
      password: password, 
      confirmPassword: confirmPassword, 
      accountType: accountType, } });

    yield put(actions.postAdminCreateAccountSuccess(response.data));
  } catch (error) {
    yield put(actions.postAdminCreateAccountFailure(GetErrorResponseMessage(error, "adminAccountAdmin", REQUEST_METHODS.POST)));
  }
}

export default function* adminAccountsSaga() {
  yield takeLatest(constants.postAdminCreateAccount, postAdminCreateAccount);
}
