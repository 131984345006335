// @flow
import React from 'react';
import styled from 'styled-components';
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const FormInput = styled.textarea`
  padding:5px;
  font-size: 1.0em;
  ${props => props.error ? `border-color ${ERROR_COLOUR};` : `border-color ${INPUT_BORDER_COLOR};`}
  ${props => props.disabled ? `background-color: ${INPUT_DISABLED_COLOUR};` : `background-color: #fff;`}
  ${props => props.disabled ? `cursor: not-allowed;` : `cursor: inherit;`}
  color: ${INPUT_TEXT_COLOUR};

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  width: 100%;
`;

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const InputContainer = styled.div`
    margin-bottom: 10px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  readonly: Boolean,
  setValue: (keyValue: string, value: string) => void,
};

const TextArea = React.forwardRef(({
  label,
  required,
  keyValue,
  value,
  onChange,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  reference,
  name,
  setValue
}: Props, ref) => {
  return (
    <InputContainer>
        <LabelContainer><Label>{label}{required ? <Required error={error}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
        <FormInput 
          placeholder={placeholder} 
          name={name}
          error={error}
          ref={ref}
          value={value}
          onChange={onChange != null ? e => onChange(keyValue, e.target.value) : ''}
        />
    </InputContainer>
  )
});

export default TextArea