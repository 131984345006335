export const constants = {
    postAdminCreateAccount: 'POST_ADMIN_CREATE_ACCOUNT',
    postAdminCreateAccountSuccess: 'POST_ADMIN_CREATE_ACCOUNT_SUCCESS',
    postAdminCreateAccountFailure: 'POST_ADMIN_CREATE_ACCOUNT_FAILURE',
};

export const actions = {    
    postAdminCreateAccount: payload => ({
        type: constants.postAdminCreateAccount,
        payload,
    }),
    postAdminCreateAccountSuccess: payload => ({
        type: constants.postAdminCreateAccountSuccess,
        payload,
    }),
    postAdminCreateAccountFailure: error => ({
        type: constants.postAdminCreateAccountFailure,
        error,
    }),
};