/* eslint-env browser */
// @flow
import React from 'react';
import { useAuth } from '../../components/auth/useAuth';
import  { Redirect } from 'react-router-dom';
import ROUTES from '../../constants/routes';

export default function(props) {
  
  const { children, parent, role } = props;
  const { loginDetails, isLoggedIn } = useAuth();

  const currentPath = window.location.pathname;
  const parentUrl = parent;

  return (
    loginDetails && isLoggedIn ?
          loginDetails && role && loginDetails.roles && loginDetails.roles.indexOf(role) !== -1 ?
            <>{children}</>
          :
          <Redirect to={{ pathname: ROUTES.UNAUTHORISED, redirectTo: currentPath}} />
        :
        <Redirect to={{ pathname: ROUTES.LOGIN, redirectTo: currentPath}} />
  );
}