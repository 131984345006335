// @flow
import { constants } from '../actions/basket';
import { Error } from '../types/error';

export type State = {
  isLoading: boolean,
  error: ?Error,
  orderCreated: boolean,
  orderId: string
};

const initialState: State = {
  error: null,
  isCreatingOrder: false,
  orderCreated: false,
  orderId: null,
  intentSecret: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadOrderClientSecret:
      return { ...state, isCreatingOrder: true, orderCreated: false, error: null };

    case constants.loadOrderClientSecretSuccess: {
      return {
        ...state,
        isCreatingOrder: false,
        orderCreated: false,
        error: null,
        intentSecret: action.payload.intentSecret
      };
    }
    case constants.loadOrderClientSecretFailure:
      return { ...state, error: action.error, orderCreated: false, isCreatingOrder: false };

    case constants.postOrderNewDetails:
      return { ...state, isCreatingOrder: true, orderCreated: false, error: null };

    case constants.postOrderNewDetailsSuccess: {
      return {
        ...state,
        isCreatingOrder: false,
        orderCreated: true,
        error: null,
        orderId: action.payload.orderId,
      };
    }

    case constants.postOrderNewDetailsFailure:
      return { ...state, error: action.error, orderCreated: false, isCreatingOrder: false };

    case constants.resetOrderNewDetailsSuccess:
      return { ...state, isCreatingOrder: true, orderCreated: false, orderId: null };

    default:
      return state;
  }
}
