// @flow
import React from 'react';
import styled from 'styled-components';
import  { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import  { Redirect } from 'react-router-dom';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ThankYou = styled.div`
    font-weight: bold;
    font-size: 1.3em;
`;

const LinkItem = styled(Link)`
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

export default function OrderConfirmation(props) { 

    const { order } = props.props.location;

    if(order === undefined)
    {
        return (<Redirect to={ROUTES.INDEX} />);
    }

    return order.orderId !== null ? (
        <>
            <Column>
                <Row>
                    <ThankYou>Thank You!</ThankYou>
                </Row>
                <Row>
                    <Column>                   
                        <p>We have received you order and will send you a confirmation email shortly.</p>

                        Your order number is: {order.orderId}

                        <LinkItem to={ROUTES.INDEX}>Continue shopping</LinkItem>
                    </Column>
                </Row>
            </Column> 
        </>
    ) : <></>; 
}