export const constants = {
    loadCustomerOrdersSearch: 'LOAD_CUSTOMER_ORDERS_SEARCH',
    loadCustomerOrdersSearchSuccess: 'LOAD_CUSTOMER_ORDERS_SEARCH_SUCCESS',
    loadCustomerOrdersSearchFailure: 'LOAD_CUSTOMER_ORDERS_SEARCH_FAILURE',
};

export const actions = {
    loadCustomerOrdersSearch: () => ({
        type: constants.loadCustomerOrdersSearch,
    }),
    loadCustomerOrdersSearchSuccess: payload => ({
        type: constants.loadCustomerOrdersSearchSuccess,
        payload,
    }),
    loadCustomerOrdersSearchFailure: error => ({
        type: constants.loadCustomerOrdersSearchFailure,
        error,
    })
};