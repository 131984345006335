// @flow
import React from 'react';
import styled from 'styled-components';
import StyledPage from '../../components/styledPages/styledPage'
import {useHomePage} from '../../components/styledPages/useStyledPages'
import LoadingAnimation from '../../components/common/LoadingAnimation';
import ProductSearchResults from '../../components/products/ProductSearchResults';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;
    
    font-weight: bold;
`;

const Loading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;   
    width: 100%;
    height: 100%;
    padding: 20px 0px 30px 0px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
    @media (max-width: 799px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;


const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default function(props) {

    const pageId = props.location.pathname.toLowerCase().replace('/', '').replace('-', ' ');
    const showProducts = props?.location?.showProducts ? props?.location?.showProducts : true;
    const styledPage = useHomePage(pageId);

    return (
        <Container>
            <Header></Header>
            {
                styledPage && styledPage.isLoading 
                ? <Loading><LoadingAnimation /></Loading>
                : styledPage && styledPage.page && styledPage.page.itemBlocks.length > 0 
                    ? <StyledPage styledPage={styledPage.page} /> 
                    : styledPage && styledPage.error && styledPage.error.message 
                        ? <Loading>{styledPage.error.message}</Loading> 
                        : <Loading>We are making some changes right now, this section will be back up and running soon.</Loading>  
            }
            {
            showProducts === true  
                ? <Content>
                    <Column>
                        <Row>
                            <ProductSearchResults props={{category:pageId}} location={pageId}/>
                        </Row>
                    </Column>
                 </Content>
                : null
            }
        </Container>
    );
}