// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { ERROR_COLOUR, INPUT_BORDER_COLOR, INPUT_TEXT_COLOUR, INPUT_DISABLED_COLOUR } from '../../styles/SiteStyles';

const Label = styled.label`
  color: ${INPUT_TEXT_COLOUR};
  padding:6px 0 6px; 6px;
  font-size: 1.0em;
`;

const Error = styled.div`
  color: ${ERROR_COLOUR};
  ${props => props.error ? `border-color:  ${ERROR_COLOUR};` : `border-color: ${INPUT_BORDER_COLOR};`};
  font-weight: bold;
  font-size: 0.9em;
  padding:6px 0 6px; 6px;
`;

const Required = styled.div`
  ${props => props.error ? `color:${ERROR_COLOUR};` : `color: ${INPUT_TEXT_COLOUR};`};
  ${props => props.error ? `font-weight: bold;` : `font-weight: default;`};
  display: inline;
`;

const InputContainer = styled.div`
    margin-bottom: 10px;
`;

const EditorContainer = styled.div`
    ${props => props.error ? 'color: #a82700;' : 'color: default;'}
    ${props => props.error ? 'border: solid 1px #a82700;' : 'border: none;'}
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`;

type Props = {
  label: string,
  required: boolean,
  keyValue: string,
  value: string,
  onChange: (value: string) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  readonly: Boolean,
};

const TextEditor = React.forwardRef(({
  label,
  required,
  keyValue,
  value,
  onChange,
  error,
  disabled,
  placeholder,
  name,
  editorRef,
}: Props, ref) => {

    let defaultValue = value !== undefined && value !== null ? value : '';
    const [text, setText] = useState('');

    useEffect(() => {
        setText(defaultValue);
    });

    useEffect(() => {
    }, [text]);

    const updateText = newValue => {
        onChange(keyValue, newValue);
        setText(newValue);
    };

    return (
        <InputContainer key='editorBase'>
            <LabelContainer><Label>{label}{required ? <Required error={error}>*</Required>: <></>}</Label><Error>{error && error.message}</Error></LabelContainer>
            
            <input 
                type='hidden' 
                keyValue={keyValue}
                ref={ref}  
                key={name} 
                name={name}
                value={text}
                error={error}
                referance={ref}
            />
            <EditorContainer error={error}>
            <SunEditor 
                ref={editorRef}
                defaultValue={text}
                value={text}
                defaultValue ={defaultValue }
                error={error}
                lang="en" 
                width="100%" 
                height="400px"
                placeholder={placeholder}
                enableToolbar={true} 
                showToolbar={true} 
                onChange={value => { 
                    updateText(value)
                }}
                setOptions={{
                    "mode": "classic",
                    "katex": "window.katex",
                    "stickyToolbar": "",
                    "minHeight" : "300px",
                    "width": "100%",
                    "font" : [
                        'Arial',
                        'tohoma',
                        'Courier New,Courier',
                        'seagull',
                        'Helvetica', 
                        'sans-serif',
                        'Impact',
                        'Lucida Sans Unicode',
                        'Trebuchet MS',
                        'Verdana',
                        'lucida handwriting', 
                        'BlowBrush',
                    ],
                    "buttonList": [
                        [
                            "undo",
                            "redo",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "paragraphStyle",
                            "blockquote",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "textStyle",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                            "image",
                            "video",
                            "math",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                            "print",
                            "save",
                            "template"
                        ]
                    ]
                }}
            />
            </EditorContainer>
    </InputContainer>);
});

export default TextEditor;