// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useMyAccount } from './useMyAccount';
import ROUTES from '../../constants/routes';
import ROLES from '../../constants/roles';
import { faUserCog, faDollyFlatbed, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    color:#5e5e5e;
    background-color: #fafafa;
`;

const AccountActionLink = styled(Link)`
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const ColumnCentreAlign = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items:center;
    text-align:center;
    justify-content: space-between;
`;

const OptionContainer = styled.div`
    width: 90px;
    height : 90px;
    padding: 10px;
    margin: 5px;
    color: #ffffff;
    border: solid 1px #000000;
    background-color: rgba(168, 39, 0, 0.9);
`;

const Title = styled.div`
    color:#5e5e5e;
`;

export default function(props) {

const account = useMyAccount();

function GetAdminLinks()
{
    if(account.loginDetails.roles.includes(ROLES.ADMINISTRATOR)) {
        return (<AccountActionLink to={ROUTES.ADMIN} ><OptionContainer><ColumnCentreAlign><div>Site Admin</div><FontAwesomeIcon icon={faUserCog} size="3x" /></ColumnCentreAlign></OptionContainer></AccountActionLink>);
    }

    return (<></>);   
}

return account.error ? (<>{account.error.message}</>) : 
    (
        <>
            <Column>
                <Title>Hi {account.loginDetails.firstName},<br />this section is still under construction. More will be added soon.</Title>
                <Row>
                    <AccountActionLink to={ROUTES.VIEW_CUSTOMER_DETAILS} ><OptionContainer><ColumnCentreAlign><div>My Details</div><FontAwesomeIcon icon={faUserEdit} size="3x" /></ColumnCentreAlign></OptionContainer></AccountActionLink>
                    <AccountActionLink to={ROUTES.VIEW_ORDERS} ><OptionContainer><ColumnCentreAlign><div>Orders</div><FontAwesomeIcon icon={faDollyFlatbed} size="3x" /></ColumnCentreAlign></OptionContainer></AccountActionLink>
                
                    {GetAdminLinks()}
                </Row>
            </Column>
        </>
    );
}