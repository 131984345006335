// @flow
import React from 'react';
import styled from 'styled-components';

import { useBasket } from '../../components/basket/useBasket';
import { useCheckout } from '../../components/basket/useCheckout';
import OrderConfirmation from '../../components/basket/orderConfirmation';

const Container = styled.div`
    border: 0; 
    height: 100%;
`;

const Header = styled.div`  
    display: flex;
    justify-content: center;  
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 70%;
    margin:auto;
    background-color: #fafafa;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

const ColumnRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    background-color: #fafafa;
    width: 100%;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export default function(props) {    
    
    const {
        paymentDetails,
        basket,
        resetBasket
    } = useBasket();

    const { 
        ResetOrder,
        order,
        ResetConfirmOrder
    } = useCheckout(paymentDetails, basket);
    
    if(order.intentSecret !== null && order.orderId !== null && order.orderCreated === true && basket.items.length > 0)
    {
        resetBasket();
        ResetOrder();
    }
    
    ResetConfirmOrder();
   
return(
    <Container>
        <Header></Header>
        <Content>
            <ColumnRow>
                <OrderConfirmation props={ props } />
            </ColumnRow>  
        </Content>   
    </Container>
);
}