// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lensPath, set } from 'ramda';
import { actions as adminOpeningTimesActions } from '../../state/actions/adminOpeningTimes';

export function useAdminOpeningTimes() {
  const dispatch = useDispatch();

  const openingTimesStore = useSelector(state => state.adminOpeningTimes);

  const [editOpeningTimes, setEditOpeningTimes] = useState(null);

  useEffect(() => {
    setEditOpeningTimes(null);
    dispatch(adminOpeningTimesActions.loadAdminOpeningTimes());
  }, []);

  useEffect(() => {
    setEditOpeningTimes(openingTimesStore.openingTimes);
  },[dispatch, openingTimesStore.isLoading]);

  const updateOpeningTimes = (keyValue, newValue) => {

    const lensSet = (key, newValue, updateOpeningTimes) => {

        let openingTimes = set(lensPath(key.split('.')), newValue, updateOpeningTimes);
        setEditOpeningTimes(openingTimes);
    };

    lensSet(keyValue, newValue, editOpeningTimes ?? editOpeningTimes);
  };

  const onSubmit = form => {  
    dispatch(adminOpeningTimesActions.postAdminUpdateOpeningTimes(editOpeningTimes));   
  };

  return {
    error: openingTimesStore.error,
    isLoading: openingTimesStore.isLoading,
    isSaving: openingTimesStore.isSaving,
    saveMessage: openingTimesStore.saveMessage,
    updateOpeningTimes,
    onSubmit,
    editOpeningTimes
  };
}
