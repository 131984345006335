// @flow
import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import  { Redirect, Link } from 'react-router-dom';
import { useAuth } from './useAuth';
import Input from '../common/Input';
import ROUTES from '../../constants/routes';
import { LoginAccessRestrictions } from '../../helpers/SiteRestrictionAccess';

const Button = styled.button`
    padding:5px;
    min-width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    width: 100%;
    cursor: pointer;
`;

const Column = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 10px;
    color:#5e5e5e;
    background-color: #fafafa;
    width: 100%;
    margin: 5px;

    @media (max-width: 799px) {
        flex-direction: column; 
    }
`;

const Error = styled.div`
    color: #a82700;
    font-weight: bold;
    font-size: 0.9em;
`;

const SignUpForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5px 10px 5px 10px;

    @media (max-width: 799px) {
        width: 98%;
    }
`;

const LoginForm = styled.div`
    min-width:250px;
    width: 50%;
    padding: 5px 10px 5px 10px;

    @media (max-width: 799px) {
        width: 98%;
    }
`;

const LinkItem = styled(Link)`
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
`;

const ForgottenPasswordLinkItem = styled(Link)`
    text-decoration: none;
    color: #5e5e5e;
    border: none;
    outline: 0;
    font-size: 0.9em;
    white-space: nowrap;
`;

const LoginDisabled = styled.div`
    display: flex;
    flex-direction: row;
    padding:20px ;
    background-color: #fafafa;
    justify-content: center;
    width: 100%;
    margin: 5px;
`;

export default function(props) {
    const {
        error,
        redirectTo,
        isLoggedIn,
        loginSubmit,
    } = useAuth();

const { handleSubmit, register, errors } = useForm();

const { isEnabled, message } = LoginAccessRestrictions();

return isEnabled === false ? <LoginDisabled dangerouslySetInnerHTML={{__html: message}}/> : 
            redirectTo && isLoggedIn ? (<Redirect to={redirectTo}  />) : 
                redirectTo === '' && isLoggedIn ? (<Redirect to={'./'}  />) :
                    (
                    <Column>
                        <LoginForm>
                        <form onSubmit={handleSubmit(loginSubmit)}>
                            <Input type='hidden' name='redirectTo' onChange={(value) => {}} ref={register()} value={props.redirectTo} />

                            <Input
                                label='Email Address' 
                                type='text' 
                                placeholder='' 
                                name="username"
                                error={errors.username}
                                ref={register({
                                required: 'Please enter your email address.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address"
                                }})}
                                onChange={(value) => {}}
                                required
                                />

                            <Input 
                                type='password' 
                                label='Password'
                                placeholder='' 
                                name="password"
                                error={errors.password}
                                onChange={(value) => {}}
                                ref={register({
                                    required: 'Please enter your password.',
                                })}
                                required />

                            <Button type='submit' >Login</Button> 
                            <ForgottenPasswordLinkItem to={ROUTES.REQUEST_PASSWORD_RESET}>Forgotten your password?</ForgottenPasswordLinkItem>
                            
                            <Error>{error}</Error>
                        </form>
                        </LoginForm>
                        <SignUpForm>
                            <p><LinkItem to={ROUTES.CREATE_USER_ACCOUNT}>Create an account</LinkItem> with us and recieve our newsletter, manage your orders and contact preferances.</p>
                            <LinkItem to={ROUTES.CREATE_USER_ACCOUNT}><Button>Create an account</Button></LinkItem>
                        </SignUpForm>
                    </Column>
                );
}