import decode from 'jwt-decode';

/* eslint-disable */

export const getExpiryTime = encodedToken => {
  const token = decode(encodedToken);

  if (!token.exp) {
    return null;
  }

  return new Date(0).setUTCSeconds(token.exp);
};

/* eslint-enable */
