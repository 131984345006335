// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as adminBlogsActions } from '../../state/actions/blogs';

export function useTestBlog(blogId) {
  const dispatch = useDispatch();

  const blogStore = useSelector(state => state.blogs);

  useEffect(() => {
      dispatch(adminBlogsActions.loadBlogDesign(blogId));
  }, []);

  useEffect(() => {

  },[dispatch, blogStore.isLoading]);

  return {
    error: blogStore.error,
    blog: blogStore && blogStore.designBlog ? blogStore.designBlog : null,
    isLoading: blogStore.isLoading,
  };
}
