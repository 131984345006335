import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from 'styled-components';

const ImageItem = styled.div`
    position: relative;
    padding:10px 10px 5px 10px;
    border: solid 1px #000000;
    margin:5px;
    ${props => props.image ? "background: url('" + props.image + "') no-repeat;" : "" }
    background-size: 100%;
    ${props => props.main ? "border: solid 3px green;" : "border:solid 1px #000000;" }
    &:active {
      cursor: grabbing;
    };
`;

const DeleteImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:-8px;
    right: -8px;
    width: 20px;
    height: 20px;
    z-index: 1;
    background-color: #ff4545;
    cursor: pointer;
    border-radius: 25%;
`;

const Close1 = styled.div` 
  height: 15px;
  width: 3px;
  background-color: #ffffff;
  transform: rotate(45deg);
`;

const Close2 = styled.div`
  height: 15px;
  width: 3px;
  background-color: #ffffff;
  transform: rotate(90deg);
`;

const ImageContainer = styled.div`
  ${props => props.isUploading ? "cursor: default;" : "&:active { cursor: grabbing; };" }
`;

const type = "Image"; 

const Image = ({ image, index, moveImage, removeImage, KeyId, isUploading }) => {
  const ref = useRef(null);
  
  const [, drop] = useDrop({
    accept: type,
    // This method is called when we hover over an element while dragging
    hover(item) { // item is the dragged element
      if(isUploading === false)
      {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        // current element where the dragged element is hovered on
        const hoverIndex = index;
        // If the dragged element is hovered in the same place, then do nothing
        if (dragIndex === hoverIndex) { 
          return;
        }

        moveImage(dragIndex, hoverIndex);

        item.index = hoverIndex;
      }
    }
});

  
  const [{ isDragging }, drag] = useDrag({
    
    item: { type, order: image.order, index },
    
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  
  drag(drop(ref));

  return (
    <ImageContainer
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1, cursor:'grab' }}
      id={KeyId} 
      className={KeyId}
      disabled={isUploading}
    >
      <ImageItem main={index === 0} image={image.path} id={KeyId} className={KeyId}>
        <img src={image.path} style={{visibility:'hidden', 'width':'150px'}} />
        <DeleteImage onClick={removeImage} id={KeyId} className={KeyId}>
            <Close1 onClick={removeImage} id={KeyId} className={KeyId}>
                <Close2 onClick={removeImage} id={KeyId} className={KeyId}/>
            </Close1>
        </DeleteImage>
      </ImageItem>
    </ImageContainer>
  );
};

const ImageList = ({ images, moveImage, baseImagesLocation, removeImage, isUploading }) => {
      return(
        <>
          {images && images.map((image, idx) => {

            let img = '';
            try {
                if(image.path.includes('data:image'))
                {
                    img = image.path;
                }
                else {
                  img = `${baseImagesLocation}${image.path}`;
                }
            } catch (error) {
                img = `${baseImagesLocation}${image.path}`;
            }

            const KeyId = image.path + '-' + image.order;

            return image && image.path ?(            
                <Image 
                    image={{ order: image.order, path: img }}
                    key={img + '-' + image.order}
                    KeyId={KeyId}
                    index={idx}
                    moveImage={moveImage}
                    removeImage={removeImage}
                    id={KeyId} 
                    className={KeyId}
                    isUploading={isUploading} />
            ) : <></>;
        })}
    </>);
  };
  
  export default ImageList;